/**
 * LIST_FIRMWARE
 */
export const LIST_FIRMWARE = 'FIRMWARE.LIST_FIRMWARE';
export const LIST_FIRMWARE_ERROR = 'FIRMWARE.LIST_FIRMWARE_ERROR';
export const LIST_FIRMWARE_SUCCESS = 'FIRMWARE.LIST_FIRMWARE_SUCCESS';
export const LIST_FIRMWARE_CLEAR = 'FIRMWARE.LIST_FIRMWARE_CLEAR';

/**
	* CREATE_FIRMWARE
	*/
export const CREATE_FIRMWARE = 'FIRMWARE.CREATE_FIRMWARE';
export const CREATE_FIRMWARE_ERROR = 'FIRMWARE.CREATE_FIRMWARE_ERROR';
export const CREATE_FIRMWARE_SUCCESS = 'FIRMWARE.CREATE_FIRMWARE_SUCCESS';

/**
	* UPDATE_FIRMWARE
	*/
export const UPDATE_FIRMWARE = 'FIRMWARE.UPDATE_FIRMWARE';
export const UPDATE_FIRMWARE_ERROR = 'FIRMWARE.UPDATE_FIRMWARE_ERROR';
export const UPDATE_FIRMWARE_SUCCESS = 'FIRMWARE.UPDATE_FIRMWARE_SUCCESS';

/**
	* DELETE_FIRMWARE
	*/
export const DELETE_FIRMWARE = 'FIRMWARE.DELETE_FIRMWARE';
export const DELETE_FIRMWARE_ERROR = 'FIRMWARE.DELETE_FIRMWARE_ERROR';
export const DELETE_FIRMWARE_SUCCESS = 'FIRMWARE.DELETE_FIRMWARE_SUCCESS';

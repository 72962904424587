import { useDispatch, useSelector } from 'react-redux';
import { reportSelectors } from '../../../store/selectors';
import React, { useEffect } from 'react';
import styles from './ReportInfo.component.module.scss';
import { reportActions } from '../../../store/actions';
import { Details } from '@indigo-cloud/common-react';

type ReportInfoProperties = {
	id: string;
};

export const reportInfoDataSelectors = {
	container: 'ReportInfo-container'
};

export const ReportInfo: React.FC<ReportInfoProperties> = ({ id }) => {
	const getReportInfoOperation = useSelector(reportSelectors.getReportInfoOperation(id));

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(reportActions.loadReportInfo(id));
	}, []);

	return (<div data-cy={reportInfoDataSelectors.container} className={styles.container} >

		<Details title={'Report Information'} object={getReportInfoOperation?.result || {}} isLoading={ getReportInfoOperation?.isLoading} />
	</div>);
};

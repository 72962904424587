import React from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import { JobsTable } from '../../../../shared/components/JobsTable';
import { DeviceGroupsTable } from '../../../../shared/components/DeviceGroupsTable';
import { NotificationsTable } from '../../../../shared/components/NotificationsTable';
import { devicesSelectors } from '../../../../store/selectors';
import styles from './DeviceInfoTabs.component.module.scss';

interface DeviceInfoTabsProperties {
	agentEndpointId: string;

}

interface TabPanelProperties {
	children?: React.ReactNode;
	dir?: string;
	index: number;
	value: number;
}

export function TabPanel(properties: TabPanelProperties) {
	const { children, value, index, ...other } = properties;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			<Box width="100%" p={2} display={value === index ? 'initial' : 'none'}>
				<Typography>{children}</Typography>
			</Box>
		</div>
	);
}

export const getTabAttributes = (index: number) => {
	return {
		'aria-controls': `widget-tabpanel-${index}`,
		id: `widget-tabpanel-${index}`
		
	};
}

export const DeviceInfoTabs: React.FC<DeviceInfoTabsProperties> = ({ agentEndpointId }) => {

	const [value, setValue] = React.useState(0);

	const deviceInfo = useSelector(devicesSelectors.getDeviceInfoOperation(agentEndpointId));

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setValue(newValue);
	};


	return (<Box display="flex" flexDirection="column" className={styles.container}>
		<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
			<Tabs value={value} onChange={handleChange} aria-label="Tab for jobs, events and thing groups">
				<Tab label="Jobs" {...getTabAttributes(0)} />
				<Tab label="Events" {...getTabAttributes(1)} />
				<Tab label="Device Groups" {...getTabAttributes(2)} />
			</Tabs>
			<TabPanel value={value} index={0}>
				<JobsTable isLoading={deviceInfo?.isLoading} name={agentEndpointId} table="device" />
			</TabPanel>
			<TabPanel value={value} index={1}>
				<NotificationsTable isLoading={deviceInfo?.isLoading} name={agentEndpointId} />
			</TabPanel>
			<TabPanel value={value} index={2}>
				<DeviceGroupsTable isDeviceGroupLoading={deviceInfo?.isLoading} name={agentEndpointId} />
			</TabPanel>
		</Box>
	</Box>);
};

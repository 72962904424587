import React, { useEffect, useRef, useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as PropTypes from 'prop-types';

import { ErrorMessage, Field, FieldArray, Form, Formik, FormikErrors, FormikHelpers, FormikState, useFormikContext } from 'formik';
import { CheckboxWithLabel, Select, TextField, TextFieldProps } from 'formik-material-ui';
import { Button, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import { DatePicker,  TimePicker } from 'formik-mui-lab';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCardIcon from '@mui/icons-material/AddCard';
import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import { DeviceGroupConfigurationInputFormValues } from '../../DeviceGroupConfigurationInputForm';
import Box from '@mui/system/Box/Box';


export interface SchedulingConfigFormProperties extends Pick<FormikHelpers<DeviceGroupConfigurationInputFormValues> & FormikState<DeviceGroupConfigurationInputFormValues>, 'setFieldValue' | 'errors' | 'values'> {
	isSubmitting: boolean;
}

export const SchedulingConfigForm: React.FC<SchedulingConfigFormProperties> = (({ values, isSubmitting, errors, setFieldValue }) => {

	const startMinDateTime = new Date();
	const [endMinDateTime, setEndMinDateTime] = useState(Array.from({length: 1}).fill(startMinDateTime));


	return (
		<Box width={'100%'} position="relative">
			<Typography variant="h6" color="primary" pb={2}>
				Scheduling Config
			</Typography>

			<Box position="absolute" top={4} right={24}>
				<Button color="primary" onClick={() => {
					setFieldValue('schedulingConfig.startTime', '')
					setFieldValue('schedulingConfig.endTime', '')
					setFieldValue('schedulingConfig.endBehavior', '')
					setFieldValue('schedulingConfig.maintenanceWindows', undefined)
					
					setTimeout(() => {
						

						setFieldValue('schedulingConfig', undefined)
					}, 0)
					
				}}>Reset</Button>
			</Box>

			<Box width={300} pb={2}>
				<Field 
					name={'schedulingConfig.startTime'}
					type="text"
					
					component={TextField}>
					{({ field, form }: { field: any, form: any }) => (
						<DateTimePicker
							{...field}
							disabled={isSubmitting}
							format="dd/MM/yyyy HH:mm"
							label="Start Date and Time"
							minDateTime={startMinDateTime}
							onChange={(newValue) => {
								setFieldValue(field.name, newValue);
							}}
							value={values.schedulingConfig?.startTime}
						/>
					)}
				</Field>
				
				{/* @ts-ignore */}
				{errors?.schedulingConfig?.startTime ? (
					<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error">
						{/* @ts-ignore */}
						{errors.schedulingConfig.startTime}
					</Typography> ) : <p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-5jypxi-MuiFormHelperText-root">The start time for the schedule</p>}
			
			</Box>
			<Box width={300} pb={2}>
				<Field 
					name={'schedulingConfig.endTime'}
					type="text"
					component={TextField}>
					{({ field, form }: { field: any, form: any }) => (
						<DateTimePicker
							{...field}
							disabled={isSubmitting}
							format="dd/MM/yyyy HH:mm"
							label="End Date and Time"
							minDateTime={endMinDateTime}
							onChange={(newValue) => {
								setFieldValue(field.name, newValue);
							}}
							value={values.schedulingConfig?.endTime}
						/>
					)}
				</Field>
				{/* @ts-ignore */}
				{errors?.schedulingConfig?.endTime ? (
					<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error">
						{/* @ts-ignore */}
						{errors.schedulingConfig.endTime}
					</Typography> ) : <p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-5jypxi-MuiFormHelperText-root">The end time for the schedule</p>}
			</Box>
			{/* <Box width={300} pb={2}>
				<Field
					disabled={isSubmitting}
					name={'schedulingConfig.endBehavior'}
					component={Select}
					label="End Behaviour"
					
					formHelperText={{ children: 'What should happen to a job execution after it completes.' }}
					type="select"
					value={values.schedulingConfig?.endBehavior ? values?.schedulingConfig?.endBehavior : ''}
					onChange={(event_: any) => setFieldValue('schedulingConfig.endBehavior', event_.target.value)}
				>
					<MenuItem value={'CANCEL'}>Cancel</MenuItem>
					<MenuItem value={'REJECT'}>Reject</MenuItem>
					<MenuItem value={'ROLLBACK'}>Rollback</MenuItem>
					<MenuItem value={'NONE'}>None</MenuItem>
				</Field>
			
			</Box> */}

			{/* <Box pb={2} mt={2}>
				<Typography variant="h6" color="primary">
					Maintenance Windows
				</Typography>
				<p className="MuiFormHelperText-root MuiFormHelperText-sizeMedium MuiFormHelperText-contained css-5jypxi-MuiFormHelperText-root" style={{ marginLeft: -0 }}>Specifies if optional maintenance windows should be use for the scheduled job to rollout.</p>

				<FieldArray name="schedulingConfig.maintenanceWindows">
					{({ insert, remove, push }) => (
						<div>
							{values.schedulingConfig?.maintenanceWindows?.map((row, index: number) => (
								<Box key={index} mt={2} width={600} p={4} boxShadow={'2px 0px 4px 2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)'} borderRadius={8}>
										
									<Box pb={2}>
										<Field 
											name={`schedulingConfig.maintenanceWindows[${index}].startTime`}
											type="text"
											component={TextField}>
											{({ field, form }: { field: any, form: any }) => (
												<DateTimePicker
													{...field}
													disabled={isSubmitting}
													format="dd/MM/yyyy HH:mm"
													label="Start Date and Time"
													minDateTime={startMinDateTime}
													onChange={(newValue) => {
														form.setFieldValue(field.name, newValue);
													}}
													value={values.schedulingConfig?.maintenanceWindows?.[index].startTime || ''}
												/>
											)}
										</Field>
									</Box>
									<Box pb={2}>
										<Field disabled={isSubmitting} label="Duration (mins)" placeholder="Duration (in minutes)" helperText={'The duration of the maintenance window.'}  component={TextField} name={`schedulingConfig.maintenanceWindows[${index}].durationInMinutes`} type="number" style={{ width: 240 }} />
									</Box>
									
									{values.schedulingConfig?.maintenanceWindows?.length && <Tooltip title="Remove field">
										<IconButton onClick={() => {
											remove(index);
										}} size={'small'}>
											<Remove style={{ color: '#e57373'}} />
										</IconButton>
									</Tooltip>}
									
									<div>
										{errors.schedulingConfig?.maintenanceWindows && Array.isArray(errors.schedulingConfig?.maintenanceWindows) && errors.schedulingConfig?.maintenanceWindows[index] && Object.entries(errors.schedulingConfig?.maintenanceWindows[index]).map(([key, value]) => (
											<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error" key={key}>
												{(errors.schedulingConfig?.maintenanceWindows?.[index] as any)[key]}
											</Typography>
										))}
									</div>
								</Box>
							))}
							<Tooltip title="Add new maintenance window">
								<IconButton onClick={() => push({})} size={'small'}>
									<Add color={'primary'} />
								</IconButton>
							</Tooltip>	
						</div>
						
					)}
					
				</FieldArray>
				
			</Box> */}
			

			
		</Box>
	);
});

SchedulingConfigForm.displayName = 'Scheduling Config Form';

SchedulingConfigForm.propTypes = {
	errors: PropTypes.any,
	isSubmitting: PropTypes.any,
	setFieldValue: PropTypes.func.isRequired,
	values: PropTypes.any
	
};
import MaterialTable from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import { devicesSelectors } from '../../../store/selectors';
import React, { useEffect, useRef, useState } from 'react';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import styles from './DevicesTable.component.module.scss';
import { devicesActions } from '../../../store/actions';
import { Device } from '../../types';
import Tooltip from '@mui/material/Tooltip';
import { AppRouteNames, appRoutes } from '../../../App.router';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CSVDownload, CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import { DeviceInfo } from '../DeviceInfo';

import { theme } from '../../../App.theme';
import { InputAdornment, TextField } from '@mui/material';
import { getDeviceIdentifierUrl, navigateToDeviceIdentifier } from '../../../shared/utils';
import moment from 'moment';
import { StatusChip } from '@indigo-cloud/common-react';

const columnStyle: React.CSSProperties = { fontFamily: theme.typography.fontFamily };

export const devicesTableDataSelectors = {
	container: 'DevicesTable-container'
};

export const DevicesTable: React.FC = ({  }) => {
	const operationGetDevices = useSelector(devicesSelectors.getDevicesOperation);




	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(devicesActions.loadDevices());
	}, []);

	const history = useHistory();

	const data = operationGetDevices?.result?.data || [];

	const reference = useRef<any>(null);

	const [filteredData, setFilteredData] = useState([]);

	return (
		<div data-cy={devicesTableDataSelectors.container} id={styles.container} >


			<CSVLink
				// Can't use 'ref?.current?.state?.data' directly, seems to cause empty data
				data={filteredData}
				asyncOnClick={true}
				style={{ position: 'absolute', right: 6, top: 6 }}
				onClick={(event, done) => {
					setFilteredData(reference?.current?.state?.data?.map?.(({
						tableData,
						...rest
					}: any) => {
						return rest;
					}) || []);

					done();
				}}
				filename={`device_list_${process.env.REACT_APP_API_NAME}.csv`}
			>

				<IconButton color="primary" aria-label="Export as CSV" component="label">
					<Tooltip title="Export as CSV">
						<DownloadIcon />
					</Tooltip>
				</IconButton>
			</CSVLink>



			<MaterialTable<Device>
				title="Devices"
				tableRef={reference}
				columns={[
					{
						cellStyle: columnStyle,
						field: 'status',
						headerStyle: columnStyle,
						render: ({ status }) => (
							<StatusChip status={status} />
						),
						title: 'Status',
						width: 40
					},
					{ align: 'center', cellStyle: columnStyle, field: 'agentEndpointId', headerStyle: columnStyle, title: 'Agent Endpoint ID' },
					{ align: 'center', cellStyle: columnStyle, field: 'serialNumber', headerStyle: columnStyle, title: 'Serial Number' },
					{ align: 'center', cellStyle: columnStyle, field: 'softwareVersion', headerStyle: columnStyle, title: 'Software Version' },
					{ align: 'center', cellStyle: columnStyle, field: 'macAddress', headerStyle: columnStyle, title: 'MAC Address' },
					{ align: 'center', cellStyle: columnStyle, field: 'oui', headerStyle: columnStyle, title: 'OUI' },
					{ align: 'center', cellStyle: columnStyle, field: 'productClass', headerStyle: columnStyle, title: 'Product Class' },
					{ align: 'center', cellStyle: columnStyle, field: 'lastStatusChange', headerStyle: columnStyle, title: 'Last Status Update'},
					{
						align: 'center',
						cellStyle: columnStyle,
						headerStyle: columnStyle,

						render: (device) => {
							return (
								<div className={styles.containerRowActions}>
									<Tooltip title={'Open in a new tab'}>

										<Link to={getDeviceIdentifierUrl(device)} target="_blank" onClick={(error) => { error.stopPropagation() }}>
											<IconButton size="large">
												<OpenInBrowserIcon />
											</IconButton >
										</Link>
									</Tooltip>
								</div>
							);

						},
						title: 'Actions',
						width: 150
					}
				]}
				components={{
					// 2022-02-08 [DEF]:  https://github.com/material-table-core/website/blob/dc91f769de59d14adc4a921c9a15248e677cb38c/demos/filter/custom-filter-row.mdx#live-demo
					FilterRow: (rowProperties) => {

						const { actionsColumnIndex, columns, onFilterChanged, selection, hasDetailPanel } = rowProperties;

						return (
							<tr style={{ 'borderBottom': '1px solid rgba(224, 224, 224, 1)' }} key={actionsColumnIndex}>
								{selection && <td></td>}
								{hasDetailPanel && <td></td>}
								{columns.map((col: any) => {


									if (col.field) {
										return (
											<th style={{ 'padding': '24px 24px 24px 8px' }} key={col.field}>
												<TextField
													variant="standard"
													InputProps={{
														startAdornment: (
															<InputAdornment position="start">
																<FilterListIcon />
															</InputAdornment>
														)
													}}
													id={col.field}
													onChange={event => onFilterChanged(col.tableData.id, event.target.value)}
												/>
											</th>
										);
									}

								})}
							</tr>
						);
					}
				}}
				options={{
					draggable: false,
					emptyRowsWhenPaging: false,
					filterCellStyle: { textAlign: 'center' },
					filtering: true,
					pageSize: 20,
					pageSizeOptions: [20, 50, 100, 500, 1000],
					searchFieldVariant: 'standard'
				}}
				data={data}
				onRowClick={(_, device) => {
					navigateToDeviceIdentifier(device!, history);
				}}
				detailPanel={({rowData}) => {
					const { agentEndpointId } = rowData;
					return (
						<DeviceInfo agentEndpointId={agentEndpointId} />
					)
			 }}
			/>
		</div>);
};

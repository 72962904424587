import { DrawerNavigationList as DrawerNavigationListCommon, DrawerNavigationListLink as DrawerNavigationListLinkCommon, isAuthorised, useAuthorisation } from '@indigo-cloud/common-react';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import * as PropTypes from 'prop-types';
import React from 'react';
import { appRoutes, navigationItems } from '../../App.router';

import { Theme, useTheme } from '@mui/material/styles';

interface DrawerNavigationListProperties {
	isOpen: boolean;
	onCloseDrawer: () => void;
}

export const drawerNavigationListDataSelectors = {
	drawerNavigationListContainer: 'DrawerNavigationList-drawer'
};


export const DrawerNavigationList: React.FC<DrawerNavigationListProperties> = ({ isOpen, onCloseDrawer }) => {

	// 'useLocation' required here to cause re-rneder on route change. Otherwise the highlighted route doesn't update on route change.
	// const location = useLocation();

	const applicationName = 'ADMIN';

	const theme = useTheme<Theme>();

	const userAuthorisationGroups = useAuthorisation();

	return (
		<DrawerNavigationListCommon >

			<DrawerNavigationListLinkCommon drawerNavigationOptions={{ applicationName, isOpen, isRootItem: true }} linkProps={{ onClick: onCloseDrawer }} routeKey={'Dashboard'} appRoutes={appRoutes} icon={ManageAccountsIcon} themeColour={theme.palette.primary.main} />
			{navigationItems
				.filter(({shouldHideLink, roles}) => !shouldHideLink && isAuthorised(roles, userAuthorisationGroups))
				.map(({icon, name}) => <DrawerNavigationListLinkCommon key={name} drawerNavigationOptions={{applicationName, isOpen, isRootItem: false  }}  linkProps={{ onClick: onCloseDrawer }} appRoutes={appRoutes} routeKey={name} icon={icon!} themeColour={theme.palette.primary.main} />)
			}

		</DrawerNavigationListCommon>
	);
};

DrawerNavigationList.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseDrawer: PropTypes.func.isRequired
};

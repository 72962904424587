import {useSelector} from 'react-redux';

import React, {useRef, useState} from 'react';

import {Modal, ModalComponentProperties, OptionalOperation} from '@indigo-cloud/common-react';
import Button from '@mui/material/Button';

import styles from './InputModalPage.component.module.scss';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {RouterState} from 'connected-react-router';
import {Form, FormikProps} from 'formik';

export type InputFormReference<FormValues> = {
	formik: FormikProps<FormValues> | null;
	formReset?: () => void;
};

export interface InputModalPageProperties<OperationResult, FormValues> extends Pick<ModalComponentProperties, 'isOpen' | 'onClickClose'> {
	dataCySelectors: {
		form: string;
		modal: string;
	};
	operation: OptionalOperation<OperationResult, Error>;
	parentRouteName: string;
	parentRouteNameWithParams?: string;
	previousRoute?: RouterState<unknown>;
	title: string;
	refForm?: React.RefObject<InputFormReference<FormValues>>;
	formControlAdditionalProps?: any;
	FormComponent: React.ComponentType<{
		ref: React.RefObject<InputFormReference<FormValues>>;

		onFormIsTouchedChanged?: (isTouched: boolean) => void;
		onFormIsSubmittingChanged?: (isFormSubmitting: boolean) => void;
		onFormIsValidChanged?: (isValid: boolean) => void;
	}>;
	confirmUpdate?: string;
	addClearButton?: boolean;
}

export const setParameterValuesInputModalDataSelectors = {
	setParameterValuesInputModalForm: 'setParameterValuesInputModal-form',
	setParameterValuesInputModalModal: 'setParameterValuesInputModal-modal'
};

export const InputModalPage = <FormValues, OperationResult>({
	FormComponent,
	dataCySelectors,
	operation,
	refForm,
	parentRouteName,
	formControlAdditionalProps,
	parentRouteNameWithParams: parentRouteNameWithParameters = parentRouteName,
	previousRoute,
	title,
	confirmUpdate = '',
	addClearButton = true
}: React.PropsWithChildren<InputModalPageProperties<OperationResult, FormValues>>) => {

	let referenceForm = useRef<InputFormReference<FormValues>>(null);

	if (refForm) {
		// TODO: Improve this logic
		referenceForm = refForm;
	}

	const [isFormValid, setIsFormValid] = useState(false);
	const [isFormTouched, setIsFormTouched] = useState(false);
	const [isFormSubmitting, setIsFormSubmitting] = useState(false);
	const [displayForm, setDisplayForm] = useState(true);
	const [displayConfirmation, setdisplayConfirmation] = useState(false);

	const history = useHistory();

	const didNavigateFromDeviceInfoPage = previousRoute?.location?.pathname === parentRouteName;

	const routeMatch = useRouteMatch<{agentEndpointId: string, parameterPaths?: string}>();

	const routeParameterAgentEndpointIdDecoded = decodeURIComponent(routeMatch.params?.agentEndpointId);

	const onModalBack = () => {
		if (didNavigateFromDeviceInfoPage) {
			history.goBack();
		} else {
			// New tab loaded the child/next route directly and has no history, or the user directly changed the address bar to the nested route from another page. Both cases require push instead of goBack
			history.push(parentRouteNameWithParameters);
		}
	}

	

	const onModalOk = () => {
		if(confirmUpdate !== ''){
			if(displayForm){
				setDisplayForm(false);
				setdisplayConfirmation(true);
			}
			if(displayConfirmation){
				setDisplayForm(true);
				setdisplayConfirmation(false);
				referenceForm.current?.formik?.submitForm();
			}
		} else {
			referenceForm.current?.formik?.submitForm();
		}
	}

	const resetForm = () => {
		referenceForm.current?.formik?.resetForm?.();
		setDisplayForm(true);
		setdisplayConfirmation(false);
	}

	const onModalClear = () => {
		resetForm();
	}

	const onModalClose = () => {
		resetForm();
		onModalBack?.();
	}

	return (<Modal
		isOpen={true}
		isLoading={operation?.isLoading}
		title={title}
		onClickOk={onModalOk}
		onClickClose={onModalClose}
		data-cy={dataCySelectors.modal}
		additionalActionsLeft={addClearButton ? <Button onClick={onModalClear} color="primary" disabled={!isFormTouched || isFormSubmitting}>
			Clear
		</Button> : ''}
		hide={{scroll: true}}
		buttonOkProps={{
			disabled: (isFormSubmitting || isFormValid === false )
		}}
	>
		<FormComponent isFormSubmitting={isFormSubmitting} ref={referenceForm} onFormIsTouchedChanged={setIsFormTouched} onFormIsSubmittingChanged={setIsFormSubmitting} onFormIsValidChanged={setIsFormValid} formDisplay={displayForm} data-cy={dataCySelectors.form} {...formControlAdditionalProps} />

		<div className={displayConfirmation ? styles.confirmDisplay : styles.confirmHide}>
			<div className={styles.confimHeading}>Are you sure?</div>
			<p>{confirmUpdate}</p>
		</div>

		{/* <SetParameterValuesInputModalForm agentEndpointId={routeParameterAgentEndpointIdDecoded}  /> */}
	</Modal>);
};


// this is an auto generated file. This will be overwritten

export const exchangeTokens = /* GraphQL */ `
  query ExchangeTokens($input: TokensInput) {
    exchangeTokens(input: $input) {
      AccessKeyId
      SecretAccessKey
      SessionToken
    }
  }
`;
export const syncSpeedTests = /* GraphQL */ `
  query SyncSpeedTests(
    $filter: ModelSpeedTestFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSpeedTests(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        line
        deviceId
        type
        serialNumber
        status
        result
        error
        createdAt
        updatedAt
        completedAt
        executionDuration
        trigger
        platform
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSpeedTest = /* GraphQL */ `
  query GetSpeedTest($id: ID!) {
    getSpeedTest(id: $id) {
      id
      line
      deviceId
      type
      serialNumber
      status
      TCPTestResult {
        resultVersion
      }
      UDPTestResult {
        resultVersion
      }
      hubError {
        code
        type
        message
      }
      result
      error
      createdAt
      updatedAt
      completedAt
      executionDuration
      trigger
      platform
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Snackbar } from '@mui/material';
import { devicesActions } from '../../../store/actions';
import { devicesSelectors } from '../../../store/selectors';
import styles from './DeviceSearchBox.component.module.scss';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info'; import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import CSVReader from 'react-csv-reader';
import HourglassBottomIcon from '@mui/icons-material/HourglassBottom';
import { SearchSnackbars } from './components/SearchSnackbars/SearchSnackbars.component';

export const DeviceSearchDataSelectors = {
	container: 'DeviceSearchBox-container'
};

interface DeviceSearchBoxProperties {
	onDevicesImport?: (devices:string[]) => void;
}

export const DeviceSearchBox: React.FC<DeviceSearchBoxProperties> = ({ onDevicesImport }) => {
	const operationGetDevice = useSelector(devicesSelectors.findDeviceOperation);
	const [searchTerm, setSearchTerm] = useState('');
	const [showHelpForFirstTime, setShowHelpForFirstTime] = useState<boolean>(true);
	const [showHelp, setShowHelp] = useState<boolean>(false);
	const [importDevicesCsvError, setImportDevicesCsvError] = useState<string | undefined>();

	const handleChange = (event: any) => {
		setSearchTerm(event.target.value);
		if ((event.target.value === 'o' || event.target.value === 'O') && searchTerm.length === 0) { // autopopulate the constant part of agent endpoint Id
			setSearchTerm('os::012345-:');
		}
		if (showHelpForFirstTime) {
			setShowHelp(true);
			setShowHelpForFirstTime(false);
		}
	};

	const dispatch = useDispatch();

	const handleHelpPopup = useCallback(
		(open: boolean) => () => {
			setShowHelp(open);
		},
		[]
	);

	const onCsvImport = (data: any[]) => {
		const things = data.filter((entry) => entry?.thingName?.length)?.map(({thingName}) => thingName);

		if (!things?.length) {
			setImportDevicesCsvError('CSV should not be empty and should match the same format as an exported device list.')
		} else {
			onDevicesImport?.(things)
		}
		
	  };

	const handleFind = useCallback(() => {
		if (searchTerm.trim() !== '') {
			dispatch(devicesActions.findDevice(searchTerm.trim()));
		}
	},
	[searchTerm]
	);

	return (

		<Box className={styles.searchBoxWrapper} maxWidth="md" sx={{ margin: 'unset', position: 'relative' }}>
			<Input inputProps={{ className: styles.searchBox }} placeholder="Search by Agent Endpoint Id, Serial No or External Id" disableUnderline onChange={(event) => handleChange(event)} value={searchTerm} />
			<Button variant="contained" endIcon={operationGetDevice?.isLoading ? <HourglassBottomIcon /> : <SearchIcon />} className={styles.findBtn} disabled={operationGetDevice?.isLoading} onClick={handleFind}>
				Find
			</Button>
			<Box flexDirection="row" position="absolute" left="912px" zIndex={99} top="4px" display="flex" gap="12px">
				<IconButton aria-label="info" className={styles.helpBtn} sx={{
					...(showHelp && {
						display: 'none'
					})
				}} onClick={handleHelpPopup(true)}>
					<InfoIcon />
				</IconButton>
				<IconButton aria-label="close" className={styles.helpBtn} sx={{
					...(!showHelp && {
						display: 'none'
					})
				}} onClick={handleHelpPopup(false)}>
					<CloseIcon />
				</IconButton>
			</Box>
				
			<List sx={{ bgcolor: 'background.paper', display: showHelp ? 'block' : 'none', width: '100%' }}>
				<ListItem alignItems="flex-start">
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									sx={{ display: 'inline' }}
									component="span"
									variant="body2"
									color="text.primary"
								>
									+XXXXXX+XXXXXXXXXX
								</Typography>
								<Box component="div" sx={{ display: 'inline', ml: 2 }}>Serial Number</Box>
							</React.Fragment>
						}
					/>
				</ListItem>
				<Divider component="li" />
				<ListItem alignItems="flex-start">
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									sx={{ display: 'inline' }}
									component="span"
									variant="body2"
									color="text.primary"
								>
									os::012345-:<i>first half of SN</i>:<i>second half of SN</i>
								</Typography>
								<Box component="div" sx={{ display: 'inline', ml: 2 }}>Agent Endpoint Id</Box>
							</React.Fragment>
						}
					/>
				</ListItem>
				<Divider component="li" />
				<ListItem alignItems="flex-start">
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography
									sx={{ display: 'inline' }}
									component="span"
									variant="body2"
									color="text.primary"
								>
									RBBSI_XXXX/<span className={styles.highlight}>XXXXXXX</span>@HG7.BTCLICK.COM
								</Typography>
								<Box component="div" sx={{ display: 'inline', ml: 2 }}>External Id</Box>
							</React.Fragment>
						}
					/>
				</ListItem>
			</List>
			<SearchSnackbars search={searchTerm} />
			<Snackbar open={!!importDevicesCsvError} autoHideDuration={10_000} onClose={() => setImportDevicesCsvError(undefined)}>
				<Alert variant="filled" severity="error" onClose={() => setImportDevicesCsvError(undefined)} >
					{importDevicesCsvError}
				</Alert>
			</Snackbar>
		</Box>
	);
};

import React from 'react';
import Chip from '@mui/material/Chip';
import {green, red} from '@mui/material/colors';

interface StatusChipProperties {
	status: string;
}

export const StatusChip: React.FC<StatusChipProperties> = ({status}) => {
	const chipStyle = {
		backgroundColor:
			status.toLowerCase() === 'online' || status.toLowerCase() === 'enabled' ? green['400'] : red['200']
	};

	return <Chip size='small' label={status} style={chipStyle} />;
};

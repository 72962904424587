import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {
	useLocation
} from 'react-router-dom';


export const NavigationBreadcrumbs = ({
	routes
}: {routes: {[k: string]: string}}) => {
	const location = useLocation();
	const pathnames = location.pathname.split('/').filter((x, index) => x && index !== 1 && !x.includes('hubFw:'));

	const applicationNameRoot = location.pathname.split('/')[1];

	return (
	  <Box paddingX={2} paddingY={1}>
		   <Breadcrumbs aria-label="breadcrumb" >
		  <Link underline="hover" color="inherit" href={routes.Dashboard} fontWeight="bold">
			  {applicationNameRoot.toUpperCase()}
		  </Link>
				{pathnames.map((value, index) => {
					const last = index === pathnames.length - 1;
					const to = `/${applicationNameRoot}/${pathnames.slice(0, index + 1).join('/')}`;

					return last ? (
						<Typography textTransform="capitalize" color="inherit" key={to} fontWeight="bold">
							{decodeURIComponent(value)}
						</Typography>
					) : (
						<Link underline="hover" textTransform="capitalize" fontWeight="bold" color="inherit" href={to} key={to}>
							{decodeURIComponent(value)}
						</Link>
					);
				})}
			</Breadcrumbs>
	  </Box>

	);
};

import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { AddParameterValuesProperties } from '../../../AddParameterValues';
import { devicesSelectors } from '../../../../../store/selectors';


interface AddParameterValuesOperationSnackbarProperties extends Pick<AddParameterValuesProperties, 'agentEndpointId'> {

}

export const addParameterValuesOperationSnackbarDataSelectors = {
	addParameterValuesOperationSnackbarAlertError: 'AddParameterValuesOperationSnackbar-snackbarAlertError',
	addParameterValuesOperationSnackbarAlertSuccess: 'AddParameterValuesOperationSnackbar-snackbarAlertSuccess',
	addParameterValuesOperationSnackbarError: 'AddParameterValuesOperationSnackbar-snackbarError',
	addParameterValuesOperationSnackbarForm: 'AddParameterValuesOperationSnackbar-form',
	addParameterValuesOperationSnackbarModal: 'AddParameterValuesOperationSnackbar-modal',
	addParameterValuesOperationSnackbarSuccess: 'AddParameterValuesOperationSnackbar-snackbarSuccess'
};

export const AddParameterValuesOperationSnackbar: React.FC<AddParameterValuesOperationSnackbarProperties> = ({ agentEndpointId }) => {

	const addDeviceParameterValuesOperation = useSelector(devicesSelectors.addDeviceParameterValuesOperation(agentEndpointId)) as any;
	const addDeviceParameterValuesOperationResults = useSelector(devicesSelectors.addDeviceParameterValuesOperationResults(agentEndpointId)) as any;
	const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean | 'success' | 'error'>(false);

	const operationError = typeof addDeviceParameterValuesOperation?.error === 'string' ? addDeviceParameterValuesOperation.error : addDeviceParameterValuesOperation?.error?.message;
	const operationResponse = addDeviceParameterValuesOperationResults;

	const didOperationSucceed = addDeviceParameterValuesOperation?.didSucceed === true;
	const didOperationError = addDeviceParameterValuesOperation?.hasError === true;

	const operationRequestParameterPaths = addDeviceParameterValuesOperation?.request?.paramPaths;

	const shouldOpenSnackbar = didOperationSucceed || didOperationError;

	useEffect(() => {
		if (shouldOpenSnackbar) {
			setShouldShowSnackbar(didOperationSucceed ? 'success' : 'error');
		}
	}, [
		shouldOpenSnackbar
	]);

	return (<>
		<Snackbar open={shouldShowSnackbar === 'error'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={addParameterValuesOperationSnackbarDataSelectors.addParameterValuesOperationSnackbarError}>
			<Alert variant="filled" severity="error" onClose={() => setShouldShowSnackbar(false)} data-cy={addParameterValuesOperationSnackbarDataSelectors.addParameterValuesOperationSnackbarAlertError}>
				An error occurred while adding {operationRequestParameterPaths?.length} parameter value{operationRequestParameterPaths?.length === 1 ? '' : 's'}: {operationError}
			</Alert>
		</Snackbar>
		<Snackbar open={shouldShowSnackbar === 'success'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={addParameterValuesOperationSnackbarDataSelectors.addParameterValuesOperationSnackbarSuccess}>
			<Alert variant="filled" severity="success" onClose={() => setShouldShowSnackbar(false)} data-cy={addParameterValuesOperationSnackbarDataSelectors.addParameterValuesOperationSnackbarAlertSuccess}>
				{operationResponse?.length} parameter value{operationResponse?.length === 1 ? '' : 's'} added successfully.
			</Alert>
		</Snackbar>
	</>);
};

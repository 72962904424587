import { RouterRootState, connectRouter } from 'connected-react-router';
import { History, LocationState } from 'history';
import { combineReducers } from 'redux';
// import { PersistConfig, persistReducer } from 'redux-persist';
// import reduxPersistLocalStorage from 'redux-persist/lib/storage';
import { StateType } from 'typesafe-actions';
import { authReducers, initialAuthState } from './Auth';
import { initialLocationState, locationReducers } from './Location';
import { initialMenuState, menuReducers } from './Menu';

// SWITCHING BACK TO REDUX-PERSIST 4.10.6 TO WORK WITH AWS-APPSYNC 4.0.0 (4.10 ONWARDS USES 5)
// const createPersistConfig = <State>() =>
// 	({
// 		// key: 'root',
// 		// storage: reduxPersistLocalStorage
// 	} as PersistConfig<State>);

export const createRootReducer = <HistoryState extends LocationState>(history: History<HistoryState>) =>
	combineReducers({
		// auth: persistReducer(createPersistConfig<AuthState>(), authReducers),
		auth: authReducers,
		location: locationReducers,
		menu: menuReducers,
		router: connectRouter(history)
	});


export type RootReducers = ReturnType<ReturnType<typeof createRootReducer>>;

export type AppState = StateType<ReturnType<typeof createRootReducer>>;
export type RootState = AppState & RouterRootState;

export const rootInitialState = {
	auth: initialAuthState,
	location: initialLocationState,
	menu: initialMenuState
	// router: {}
} as RootState;

import {createSelector} from 'reselect';
import { Firmware } from '../../shared/types';

import {RootState} from '../reducers';
import { PaginatedResults, createOperationResultSelector, createPagedResultDataSelector, operationSelector } from '@indigo-cloud/common-react';

const firmwareSelector = (state: RootState) => {
	return state?.firmware?.listFirmwareOperation;
};

/**
 * CREATE FIRMWARE
 */
const createFirmwareSelector = (state: RootState) => {
	return state?.firmware?.createFirmwareOperation;
};


const createFirmwareOperationSelector = createSelector(createFirmwareSelector, (state) => operationSelector(state));
const createFirmwareOperationResultSelector = createOperationResultSelector(createFirmwareOperationSelector);

/**
 * DELETE FIRMWARE
 */
const deleteFirmwareSelector = (state: RootState) => {
	return state?.firmware.deleteFirmwareOperation;
};

const deleteFirmwareOperationSelector = createSelector(deleteFirmwareSelector, (state) => operationSelector(state));
const deleteFirmwareOperationResultSelector = createOperationResultSelector(deleteFirmwareOperationSelector);

/**
 * LIST FIRMWARE
 */
const listFirmwareOperationSelector = createSelector(firmwareSelector, (state) => operationSelector<{[pageNumberAndFilters: string]: PaginatedResults<Firmware>}, Error>(state));
const listFirmwareOperationResultsSelector = createOperationResultSelector(listFirmwareOperationSelector);
const listFirmwareOperationResultsPageSelector = (pageNo: number, filters?: Record<string, unknown>) => createSelector(listFirmwareOperationResultsSelector, (state) => state?.[`${pageNo}_${JSON.stringify(filters)}`]);

const getFirmwareByIdentifiersSelector = (name?: string, modelName?: string) => createSelector(listFirmwareOperationResultsSelector, (state) => {
	const data = Object.values(state? state: {}).map(
		(page) => {
			return page.data?.filter((item) => name === (item as Firmware).firmwareName && modelName === (item as Firmware).modelName)?.[0];
		}
	).find(firmware => firmware !== undefined);
	return data;
});

/**
 * UPDATE FIRMWARE
 */
const updateFirmwareSelector = (state: RootState) => {
	return state?.firmware?.updateFirmwareOperation;
};

const updateFirmwareOperationSelector = createSelector(updateFirmwareSelector, (state) => operationSelector(state));
const updateFirmwareOperationResultSelector = createOperationResultSelector(updateFirmwareOperationSelector);

export const firmwareSelectors = {
	createFirmwareOperation: createFirmwareOperationSelector,
	createFirmwareOperationResult: createFirmwareOperationResultSelector,
	deleteFirmwareOperation: deleteFirmwareOperationSelector,
	deleteFirmwareOperationResult: deleteFirmwareOperationResultSelector,
	getFirmwareByIdentifiers: getFirmwareByIdentifiersSelector,
	getFirmwareOperationResults: listFirmwareOperationResultsSelector,
	listFirmwareOperation: listFirmwareOperationSelector,
	listFirmwareOperationResultsPage: listFirmwareOperationResultsPageSelector,
	updateFirmwareOperation: updateFirmwareOperationSelector,
	updateFirmwareOperationResult: updateFirmwareOperationResultSelector
};

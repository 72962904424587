import MaterialTable from '@material-table/core';
import { useDispatch, useSelector } from 'react-redux';
import { deviceGroupsSelectors, devicesSelectors } from '../../../store/selectors';	
import React, { useEffect, useRef, useState } from 'react';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './DeviceGroupsTable.component.module.scss';
import { deviceGroupsActions, devicesActions } from '../../../store/actions';
import { DeviceGroup } from '../../types';
import Tooltip from '@mui/material/Tooltip';
import { Link, useHistory } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { CSVDownload, CSVLink } from 'react-csv';
import DownloadIcon from '@mui/icons-material/Download';

import { DeviceGroupInfo } from '../DeviceGroupInfo';

import { theme } from '../../../App.theme';
import { Button, InputAdornment, TextField } from '@mui/material';
import { getDeviceGroupIdentifierUrl, navigateToDeviceGroupIdentifier } from '../../utils';

interface DeviceGroupTableProperties {
	name?: string;
	isDeviceGroupLoading?: boolean;
}

const columnStyle: React.CSSProperties = { fontFamily: theme.typography.fontFamily };

export const deviceGroupsTableDataSelectors = {
	container: 'DevicesTable-container'
};

export const DeviceGroupsTable: React.FC<DeviceGroupTableProperties> = ({ name, isDeviceGroupLoading }) => {
	const deviceGroups = name ? devicesSelectors.getDeviceGroupsForDevice(name) : deviceGroupsSelectors.listDeviceGroupsOperation;
	const operationGetDevices = useSelector(deviceGroups);



	const dispatch = useDispatch();
	useEffect(() => {
		if (!name) {
			dispatch(deviceGroupsActions.loadDeviceGroups());
		} else {
			dispatch(devicesActions.loadDeviceGroupsForDevice({name: name}));
		}
	}, []);

	const history = useHistory();

	const data = operationGetDevices?.result?.data || [];

	const reference = useRef<any>(null);
	
	return (
		<>
			{(isDeviceGroupLoading || operationGetDevices?.isLoading)? <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div> : 
				<div data-cy={deviceGroupsTableDataSelectors.container} id={styles.container} >
					{(data.length > 0) && <CSVLink
						// Can't use 'ref?.current?.state?.data' directly, seems to cause empty data
						data={data}
						asyncOnClick={true}
						className={name ? styles.downlodIconDevice : styles.downlodIconDeviceGroup}
						filename="Device Groups list"
					>
						{name ? <Button
							variant="contained"
							startIcon={<FileDownloadOutlined />}
							aria-label="Export as CSV"
							title="Export as CSV"
						>
							Download Device Group(s)
						</Button> : 
							<IconButton color="primary" aria-label="Export as CSV" component="label">
								<Tooltip title="Export as CSV">
									<DownloadIcon />
								</Tooltip>
							</IconButton>}
					</CSVLink>}
		
					<MaterialTable<DeviceGroup>
						title="Device Groups"
						tableRef={reference}
						columns={[
							{ align: 'left', cellStyle: columnStyle, field: 'id', headerStyle: columnStyle, title: 'ID' },
							{ align: 'left', cellStyle: columnStyle, field: 'name', headerStyle: columnStyle, title: 'Name' },
							{
								align: 'center',
								cellStyle: columnStyle,
								headerStyle: columnStyle,
		
								render: (deviceGroup) => {
									return (
										<>
											<div className={styles.containerRowActions}>
												<Tooltip title={'Open in a new tab'}>
													<Link to={getDeviceGroupIdentifierUrl(deviceGroup)} target="_blank" onClick={(error) => { error.stopPropagation() }}>
														<IconButton size="large">
															<OpenInBrowserIcon />
														</IconButton >
													</Link>
												</Tooltip>
											</div>
										</>	
									);
								},
								title: 'Actions'
							}
						]}
						components={{
							// 2022-02-08 [DEF]:  https://github.com/material-table-core/website/blob/dc91f769de59d14adc4a921c9a15248e677cb38c/demos/filter/custom-filter-row.mdx#live-demo
							FilterRow: (rowProperties) => {
		
								const { actionsColumnIndex, columns, onFilterChanged, selection, hasDetailPanel } = rowProperties;
		
								return (
									<tr style={{ 'borderBottom': '1px solid rgba(224, 224, 224, 1)' }} key={actionsColumnIndex}>
										{selection && <td></td>}
										{hasDetailPanel && <td></td>}
										{columns.map((col: any) => {
		
		
											if (col.field) {
												return (
													<th style={{ 'padding': '24px 24px 24px 8px', 'textAlign': 'left'}} key={col.field}>
														<TextField
															variant="standard"
															InputProps={{
																startAdornment: (
																	<InputAdornment position="start">
																		<FilterListIcon />
																	</InputAdornment>
																)
															}}
															id={col.field}
															onChange={event => onFilterChanged(col.tableData.id, event.target.value)}
														/>
													</th>
												);
											}
		
										})}
									</tr>
								);
							}
						}}
						options={{
							draggable: false,
							emptyRowsWhenPaging: false,
							filterCellStyle: { textAlign: 'center' },
							filtering: true,
							pageSize: 20,
							pageSizeOptions: [20, 50, 100, 500, 1000],
							searchFieldVariant: 'standard'
						}}
						data={data}
						onRowClick={(_, deviceGroup) => {
							navigateToDeviceGroupIdentifier(deviceGroup!, history);
						}}
						detailPanel={({rowData}) => {
							const { id, name } = rowData;
							return (
								<DeviceGroupInfo name={name} />
							)
						}}
					/>
				</div>
			}
		</>
	);

};

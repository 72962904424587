import {CognitoUser} from 'amazon-cognito-identity-js';
import {Action, ActionType, PayloadAction} from 'typesafe-actions';

import {
	LOAD_AUTH_USER,
	LOAD_AUTH_USER_CLEAR,
	LOAD_AUTH_USER_ERROR,
	LOAD_AUTH_USER_SUCCESS,
	LOGIN,
	LOGIN_ERROR,
	LOGIN_SUCCESS,
	LOGOUT
} from '../../constants';
import {createAction} from '@indigo-cloud/common-react';

export const authActions = {


	/**
	 * LOAD_AUTH_USER
	 */
	loadAuthUser: (): Action<typeof LOAD_AUTH_USER> => createAction(LOAD_AUTH_USER),


	loadAuthUserClear: (): Action<typeof LOAD_AUTH_USER_CLEAR> => createAction(LOAD_AUTH_USER_CLEAR),


	loadAuthUserError: <Error_ extends Error>(error: Error_): PayloadAction<typeof LOAD_AUTH_USER_ERROR, {error: Error_}> =>
		createAction(LOAD_AUTH_USER_ERROR, {error}),




	loadAuthUserSuccess: (
		response: CognitoUser
	): PayloadAction<typeof LOAD_AUTH_USER_SUCCESS, {response: CognitoUser}> =>
		createAction(LOAD_AUTH_USER_SUCCESS, {response}),



	/**
	 * LOGIN
	 */
	login: (
		email: string,
		password: string,
		formSubmit?: {res: () => void; rej: () => void}
	): PayloadAction<typeof LOGIN, {email: typeof email; password: typeof password; formSubmit: typeof formSubmit}> =>
		createAction(LOGIN, {email, formSubmit, password}),

	loginError: <Error_ extends Error>(
		email: string,
		error: Error_
	): PayloadAction<typeof LOGIN_ERROR, {email: string; error: Error_}> => createAction(LOGIN_ERROR, {email, error}),

	loginSuccess: (user: CognitoUser): PayloadAction<typeof LOGIN_SUCCESS, {user: typeof user}> =>
		createAction(LOGIN_SUCCESS, {user}),
	/**
	 * LOGOUT
	 */
	logout: () => createAction(LOGOUT)

};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type authActions = ActionType<typeof authActions>;

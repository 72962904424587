import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';
import DoneIcon from '@mui/icons-material/Done';
import { Button, Paper, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVDownload } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { theme } from '../../../App.theme';
import { jobsSelectors } from '../../../store/selectors';
import { Job } from '../../types';
import styles from './JobsTable.component.module.scss';
import { PaginatedTable } from '@indigo-cloud/common-react';
import { Column } from 'react-table';
import { deviceGroupsActions, devicesActions } from '../../../store/actions'
import { debounce } from 'lodash';
import { TJobFor, navigateToJobIdentifier } from '../../utils';



interface JobsTableProperties {
	name: string
	isLoading?: boolean;
	inherittedFilters?: Record<string, unknown>;
	customStyle?: Record<string, unknown>;
	table?: TJobFor
}


export const jobsTableDataSelectors = {
	container: 'JobsTable-container'
};

// Reuse this component in Device Page to list jobs

export const JobsTable: React.FC<JobsTableProperties> = ({ inherittedFilters, name, isLoading, table, customStyle = {} }) => {

	const Filter = (property: any) => {

		return (
			<TextField
				placeholder={'Filter...'}
					 value={property.column.filterValue ? property.column.filterValue : ''}
					 onChange={event => property.setFilter(property.column.id, event.target.value)}
			/>
		 )
	}

	const columns = useMemo<Column<Job>[]>(
		() => [
			{
				Filter,
				Header: 'ID',
				accessor: 'jobId' as any,
				disableFilters: true
			},
			{
				Cell: ({ value }: { value: string }) => {
					switch(value) {
					case 'SUCCEEDED':
					case 'COMPLETED':
						return (
							<div className={styles.statusStyleSuccess}>
								<DoneIcon/>
								{value}
							</div>);
					case 'FAILED':
					case 'CANCELED':
						return (
							<div className={styles.statusStyleFailed}>
								<ClearIcon/>
								{value}
							</div>
						);
					default:
						return <div>{value}</div>;
					}
				},
				Filter,
				Header: 'Status',
				accessor: 'status' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Execution Number',
				accessor: 'executionNumber' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Started at',
				accessor: 'startedAt' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Updated at',
				accessor: 'updatedAt' as any,
				disableFilters: true
			}
		], []
	);
	const [pageSize, setPageSize] = React.useState(10);
	const [pageIndex, setPageIndex] = React.useState(0);
	const [filters, setFilters] = React.useState({
		...inherittedFilters
	});

	const getJobsOperationCurrentPage = useSelector(jobsSelectors.getJobsOperationResultsPage(pageIndex, name, filters));
	const getJobsOperation = useSelector(jobsSelectors.getJobsOperation(name));
	const jobs = useSelector(jobsSelectors.getJobsOperationResults(name));
	const [getJobsOperationAll, getJobsOperationLastPage] = useSelector(jobsSelectors.getJobsOperationAll(name, filters));

	const [initiateDownload, setInitiateDownload] = useState(false);
	const [collectingtAllData, setCollectingtAllData] = useState(false);

	const reference = useRef<any>(null);
	const [colorReset, setColorReset] = useState(false); //[REMOVE LATER]
	
	const dispatch = useDispatch();

	const history = useHistory();

	useEffect(() => {
		if(table === 'deviceGroup'){
			dispatch(deviceGroupsActions.loadDeviceGroupJobs({cursor: '', filters, limit: pageSize, name: name}));
		} else if(table === 'device') {
			dispatch(devicesActions.loadDeviceJobs({agentEndpointId: name, cursor: '', filters, limit: pageSize}));
		}
		return () => {
			if(table === 'deviceGroup'){
				dispatch(deviceGroupsActions.loadDeviceGroupJobsClear());
			} else if(table === 'device') {
				dispatch(devicesActions.loadDeviceJobsClear());
			}
		}
	}, []);

	// Define a default UI for filtering
	function DefaultColumnFilter({
		column: { filterValue, preFilteredRows, setFilter }
	}: any) {
		const count = preFilteredRows.length

		return (
			<div style={{
				'borderBottom': '2px solid rgba(224, 224, 224, 1)',
				display: 'flex',
				justifyContent: 'center',
				padding: '10px'
			}}>
				<FilterListIcon />
				<input
					value={filterValue || ''}
					onChange={event => {
						setFilter(event.target.value || undefined) // Set undefined to remove the filter entirely
					}}
					placeholder={`Search ${count} records...`}
				/>
			</div>
		)
	}

	const defaultColumn: any = React.useMemo(
		() => ({
		  Filter: DefaultColumnFilter
		}),
		[]
	);

	const onFiltersChanged = useCallback(debounce((filtersNext: any) => {
		const filtersObject = filtersNext.reduce((result: Record<string, unknown>, current: Record<string, unknown>) => {
			return Object.assign({
				[current.id as string]: current.value
			}, result);
		}, {});
		const filtersNextParsed = {
			...inherittedFilters,
			...filtersObject

		}
		if (JSON.stringify(filtersNextParsed) === JSON.stringify(filters || '{}')) {
			return;
		}
		setFilters(filtersNextParsed);
		setPageIndex(0);

		const hasRequestedPageInState = !!getJobsOperation?.result?.[`${pageIndex.toString()}_${JSON.stringify(filtersNextParsed)}`];

		if (!hasRequestedPageInState) {
			if(table === 'deviceGroup'){
				dispatch(deviceGroupsActions.loadDeviceGroupJobs({filters: filtersNextParsed, limit: pageSize, name: name, pageIndex: 0}));
			} else if(table === 'device') {
				dispatch(devicesActions.loadDeviceJobs({agentEndpointId: name, filters: filtersNextParsed, limit: pageSize, pageIndex: 0}));
			}
		}
	}, 800), [pageSize, pageIndex, getJobsOperationCurrentPage, inherittedFilters]);

	const onPageChanged = useCallback((page) => {
		setPageIndex(page);

		const hasRequestedPageInState = !!getJobsOperation?.result?.[`${page.toString()}_${JSON.stringify(filters)}`];

		if (!hasRequestedPageInState) {
			if(table === 'deviceGroup'){
				dispatch(deviceGroupsActions.loadDeviceGroupJobs({cursor: getJobsOperationCurrentPage?.pagination.next, filters, limit: pageSize, name: name, pageIndex: page}));
			} else if(table === 'device') {
				dispatch(devicesActions.loadDeviceJobs({agentEndpointId: name, cursor: getJobsOperationCurrentPage?.pagination.next, filters, limit: pageSize, pageIndex: page}));
			}
		}

	}, [jobs, pageSize, getJobsOperationCurrentPage?.pagination?.next]);

	const onRowClick = useCallback((row: Job) => {
		if (table == 'device') {
			navigateToJobIdentifier(row, name, history, 'device');
		} else {
			navigateToJobIdentifier(row, name, history, 'deviceGroup');
		}

	}, [name, history]);

	const hasCursor = useMemo(() => {
		return getJobsOperationCurrentPage?.pagination?.cursor !== undefined;
	}, [getJobsOperationCurrentPage?.pagination]);

	useEffect(() => {
		// Enables user to download data more than once
		if (initiateDownload) {
		  setInitiateDownload(false)
		}
	}, [initiateDownload]);

	useEffect(() => {
		if(!getJobsOperation?.isLoading && collectingtAllData){
			setInitiateDownload(true);
			setCollectingtAllData(false);
		}
	}, [getJobsOperation?.isLoading]);

	const jobsCSVDownload = () => {
		if(getJobsOperationLastPage.pagination.cursor){
			setCollectingtAllData(true);
			if(table === 'deviceGroup'){
				dispatch(deviceGroupsActions.loadDeviceGroupJobs({
					name, cursor:getJobsOperationLastPage.pagination.next,
					filters,
					pageIndex: getJobsOperationLastPage.pageIndex + 1, 
					collectAllData: true
				}));
			} else {
				dispatch(devicesActions.loadDeviceJobs({
					agentEndpointId: name, cursor:getJobsOperationLastPage.pagination.next,
					filters,
					pageIndex: getJobsOperationLastPage.pageIndex + 1, 
					collectAllData: true
				}));
			}
			
		} else {
			setInitiateDownload(true);
		}
	}

	const generateView = () => (
		<div data-cy={jobsTableDataSelectors.container} id={styles.container} >
			{initiateDownload && (
				<CSVDownload
					data={getJobsOperationAll}
					filename={`Devices in ${name}`}
					target="_self"
				/>
			)}
			{(getJobsOperationAll.length > 0 ) && <div className={styles.downloadIcon}>
				{!collectingtAllData ?
					<Button
						variant="contained"
						startIcon={<FileDownloadOutlined />}
						onClick={jobsCSVDownload}
						aria-label="Export as CSV"
						title="Export as CSV"
					>
						Download Job(s)
					</Button> : 
					<CircularProgress data-cy='' color='primary' size={'2rem'} />}
			</div>}

			<Paper elevation={2} style={{ margin: '20px', overflow: 'auto', padding: '20px'}}>
				<PaginatedTable
					colorReset={colorReset}
					setColorReset={setColorReset}
					ref={reference}
					columns={columns}
					data={getJobsOperationCurrentPage?.data || []}
					defaultColumn={defaultColumn}
					hasCursor={hasCursor}
					navigateToRowDetails={onRowClick}
					onFiltersChanged={onFiltersChanged}
					onPageChanged={onPageChanged}
					// Seems to be bug with pageIndex in react-table where it doesn't always update on nextPage(). So use redux pagine index instead.
					pageIndex={pageIndex}
					loading={!!getJobsOperation?.isLoading}
					theme={theme}
					customStyle={{bodyAlign: 'center', headerAlign: 'center', ...customStyle }}
				/>
			</Paper>
		</div>);
	return (
		<>
			{(isLoading || getJobsOperation?.isLoading)? <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div> : generateView()}
		</>
	);
};

import React, { useEffect } from 'react';

import { ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { AppRouter } from '../../App.router';
import { theme } from '../../App.theme';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { store } from '../../store';
import './App.component.scss';

import { History } from 'history';
import { awsExports, setAwsExports } from '../../shared';
import { LocalizationProvider } from '@mui/x-date-pickers';

interface AppProperties {
	awsExports: any;
	history: History<any>;
}

export const AwsExportsContext = React.createContext(awsExports);
export const App: React.FC<AppProperties> = ({ awsExports: awsExportsProperties, history }: AppProperties) => {


	useEffect(() => {
		setAwsExports(awsExportsProperties as any);
	}, []);

	return (
		<AwsExportsContext.Provider value={awsExportsProperties}>
			<Provider store={store}>
				<ConnectedRouter history={history as any}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<ThemeProvider theme={theme}>
							<AppRouter />
						</ThemeProvider>
					</LocalizationProvider>

				</ConnectedRouter>
			</Provider>
		</AwsExportsContext.Provider>
	);
};

import {combineReducers} from 'redux';
import {StateType} from 'typesafe-actions';
import {DeepReadonlyObject, OperationState, getStateError, getStateInProgress, getStateInitial, getStateSuccess} from '@indigo-cloud/common-react';
import { Report, ReportsResponse } from '../../../shared';
import { APP_REPORT, APP_REPORT_ERROR, APP_REPORT_SUCCESS, CREATE_REPORT, CREATE_REPORT_ERROR, CREATE_REPORT_SUCCESS, DELETE_REPORT, DELETE_REPORT_ERROR, DELETE_REPORT_SUCCESS, DOWNLOAD_REPORT, DOWNLOAD_REPORT_ERROR, DOWNLOAD_REPORT_SUCCESS, FIRMWARE_REPORT, FIRMWARE_REPORT_ERROR, FIRMWARE_REPORT_SUCCESS, LIST_REPORTS, LIST_REPORTS_ERROR, LIST_REPORTS_SUCCESS, LOAD_REPORT_INFO, LOAD_REPORT_INFO_ERROR, LOAD_REPORT_INFO_SUCCESS, RBSID_REPORT, RBSID_REPORT_ERROR, RBSID_REPORT_SUCCESS, UPDATE_REPORT, UPDATE_REPORT_ERROR, UPDATE_REPORT_SUCCESS  } from '../../constants';
import { FirmwareReport } from '../../../shared/types/FirmwareReport';

export type ReportState = DeepReadonlyObject<{
	createReportOperation: OperationState<any, Error>;
	deleteReportOperation: OperationState<any, Error>;
	listReportsOperation: OperationState<ReportsResponse>;
	loadReportInfoOperation: {
		[id: string]: OperationState<Report>
	};
	firmwareReportOperation: OperationState<FirmwareReport>;
	updateReportOperation: OperationState<any, Error>;
	downloadReportOperation: OperationState<any, Error>;
	rbsidReportOperation: OperationState<any, Error>;
	appReportOperation: OperationState<any, Error>;
}>;
export const initialReportState: ReportState = {

	createReportOperation: getStateInitial<any, Error>(),
	deleteReportOperation: getStateInitial<any, Error>(),
	listReportsOperation: getStateInitial<any, Error>(),
	loadReportInfoOperation: {},
	updateReportOperation: getStateInitial<any, Error>(),
	firmwareReportOperation: getStateInitial<any, Error>(),
	downloadReportOperation: getStateInitial<any, Error>(),
	rbsidReportOperation: getStateInitial<any, Error>(),
	appReportOperation: getStateInitial<any, Error>()
};

export const reportReducers = combineReducers<ReportState, any>({
	firmwareReportOperation: (state = initialReportState.firmwareReportOperation, action) => {
		switch (action.type) {
		case FIRMWARE_REPORT: {
			return getStateInProgress(action.payload);
		}
		case FIRMWARE_REPORT_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case FIRMWARE_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	rbsidReportOperation: (state = initialReportState.rbsidReportOperation, action) => {
		switch (action.type) {
		case RBSID_REPORT: {
			return getStateInProgress(action.payload);
		}
		case RBSID_REPORT_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case RBSID_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	appReportOperation: (state = initialReportState.appReportOperation, action) => {
		switch (action.type) {
		case APP_REPORT: {
			return getStateInProgress(action.payload);
		}
		case APP_REPORT_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case APP_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	createReportOperation: (state = initialReportState.createReportOperation, action) => {
		switch (action.type) {
		case CREATE_REPORT: {
			return getStateInProgress(action.payload);
		}
		case CREATE_REPORT_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case CREATE_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},

	deleteReportOperation: (state = initialReportState.createReportOperation, action) => {
		switch (action.type) {
		case DELETE_REPORT: {
			return getStateInProgress(action.payload);
		}
		case DELETE_REPORT_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case DELETE_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	listReportsOperation: (state = initialReportState.listReportsOperation, action) => {
		switch (action.type) {
		case LIST_REPORTS: {
			return getStateInProgress(action.payload);
		}
		case LIST_REPORTS_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case LIST_REPORTS_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	loadReportInfoOperation: (state = initialReportState.loadReportInfoOperation, action) => {
		switch (action.type) {
		case LOAD_REPORT_INFO: {
			const { id } = action.payload;
			return {
				[id]: getStateInProgress(action.payload)
			};
		}
		case LOAD_REPORT_INFO_SUCCESS: {
			const { id } = action.payload;
			return {
				[id]: getStateSuccess(action.payload)
			};
		}
		case LOAD_REPORT_INFO_ERROR: {

			const { id, error } = action.payload;
			return {
				[id]: getStateError(error, action.payload)
			};

		}
		default:
			return state;
		}
	},
	updateReportOperation: (state = initialReportState.createReportOperation, action) => {
		switch (action.type) {
		case UPDATE_REPORT: {
			return getStateInProgress(action.payload);
		}
		case UPDATE_REPORT_SUCCESS: {
			const response = action.payload;

			return getStateSuccess(response);
		}
		case UPDATE_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	downloadReportOperation: (state = initialReportState.downloadReportOperation, action) => {
		switch (action.type) {
		case DOWNLOAD_REPORT: {
			return getStateInProgress(action.payload);
		}
		case DOWNLOAD_REPORT_SUCCESS: {
			const { response } = action.payload;

			return getStateSuccess(response, state);
		}
		case DOWNLOAD_REPORT_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	}

});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type reportReducers = StateType<typeof reportReducers>;

/**
 * LOAD_RULES_TABLE
 */
export const LIST_RULES = 'RULES.LOAD_RULES_TABLE';
export const LIST_RULES_ERROR = 'RULES.LOAD_RULES_TABLE_ERROR';
export const LIST_RULES_SUCCESS = 'RULES.LOAD_RULES_TABLE_SUCCESS';

/**
 * CREATE_RULE
 */
export const CREATE_RULE = 'RULES.CREATE_RULE';
export const CREATE_RULE_ERROR = 'RULES.CREATE_RULE_ERROR';
export const CREATE_RULE_SUCCESS = 'RULES.CREATE_RULE_SUCCESS';

/**
 * UPDATE_RULE
 */
export const UPDATE_RULE = 'RULES.UPDATE_RULE';
export const UPDATE_RULE_ERROR = 'RULES.UPDATE_RULE_ERROR';
export const UPDATE_RULE_SUCCESS = 'RULES.UPDATE_RULE_SUCCESS';

/**
 * DELETE_RULE
 */
export const DELETE_RULE = 'RULES.DELETE_RULE';
export const DELETE_RULE_ERROR = 'RULES.DELETE_RULE_ERROR';
export const DELETE_RULE_SUCCESS = 'RULES.DELETE_RULE_SUCCESS';

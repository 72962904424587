import React, {ReactNode, WeakValidationMap} from 'react';
import {Box, Button, ExtendButtonBase, ExtendButtonBaseTypeMap, LinearProgress, ModalProps, Typography} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

import {TransitionProps} from '@mui/material/transitions';
import * as PropTypes from 'prop-types';
import {Scrollbars} from 'react-custom-scrollbars';
import styles from './Modal.component.module.scss';
import './Modal.component.scss';

export interface ModalComponentProperties {
  children: ReactNode | ReactNode[];
	isOpen: ModalProps['open'];
	isLoading?: boolean;
	isFullscreen?: boolean;
	onClickClose?: () => void;
	onClickOk: () => void;
	onClickBack?: () => void;
	isDisabledBack?: boolean;
	title: string;
	classes?: {root?: string; content?: string};
	hide?: {
		cancel?: boolean;
		back?: boolean;
		scroll?: boolean;
	};

  disableBackdropClick?: boolean;
  buttonOkProps?: ReturnType<ExtendButtonBase<any>>['props'];

  additionalActionsLeft?: React.ReactNode;
  additionalActionsRight?: React.ReactNode;
}

export const modalDataSelectors = {
	dialog: 'Modal-dialog',

	dialogActions: 'Modal-dialog-actions',
	dialogActionsButtonBack: 'Modal-dialog-actions-button-back',

	dialogActionsButtonCancel: 'Modal-dialog-actions-button-cancel',

	dialogActionsButtonClose: 'Modal-dialog-actions=button-close',
	dialogActionsButtonOk: 'Modal-dialog-actions-button-ok',
	dialogContent: 'Modal-dialog-content',
	dialogTitle: 'Modal-dialog-title',
	dialogTitleText: 'Modal-dialog-title-text'
};

const Transition = React.forwardRef((
	properties: TransitionProps & {
	  children: React.ReactElement<any, any>;
	},
	reference: React.Ref<unknown>
) => {
	return <Slide direction="up" ref={reference} {...properties} />;
});

Transition.displayName = 'Transition';

export const Modal: React.FC<ModalComponentProperties> = ({
	isOpen,
	isLoading,
	isFullscreen,
	hide,
	isDisabledBack,
	onClickClose,
	onClickOk,
	onClickBack,
	additionalActionsLeft,
	additionalActionsRight,
	title,
	children,
	classes,
	disableBackdropClick,
	buttonOkProps
}) => {
	return (
		<Dialog
			open={isOpen}
			TransitionComponent={Transition}
			keepMounted

			fullScreen={isFullscreen}
			onClose={onClickClose}
			className={classes?.root}
			data-cy={modalDataSelectors.dialog}>
			{isLoading && <LinearProgress />}
			<DialogTitle id="alert-dialog-slide-title" data-cy={modalDataSelectors.dialogTitle}>
				<Box paddingTop={2} paddingX={2} display={'flex'} flexDirection={'column'}>
					<Typography variant="h4" className="heading" color="primary" data-cy={modalDataSelectors.dialogTitleText}>
						{title}
					</Typography>
				</Box>
			</DialogTitle>
			<DialogContent className={`${styles.content} ${classes?.content}`} data-cy={modalDataSelectors.dialogContent}>
				{/* @ts-ignore */}
				<Box paddingX={2} className={`${styles.boxRootContent}`} height="100%">
					{hide?.scroll !== true ? (
						<Scrollbars className={styles.scrollbarsRootContent}>{children}</Scrollbars>
					) : (
						children
					)}
				</Box>
			</DialogContent>
			<DialogActions data-cy={modalDataSelectors.dialogActions}>
				{hide?.back !== true && onClickBack && (
					<Button onClick={onClickBack}  disabled={isDisabledBack} data-cy={modalDataSelectors.dialogActionsButtonBack}>
						Back
					</Button>
				)}
				{ additionalActionsLeft}
				<div style={{flex: '1 0 0'}} />
				{ additionalActionsRight}
				{hide?.cancel !== true && onClickClose && (
					<Button onClick={onClickClose}  data-cy={modalDataSelectors.dialogActionsButtonCancel}>
						Cancel
					</Button>
				)}

				<Button onClick={onClickOk} {...buttonOkProps} data-cy={modalDataSelectors.dialogActionsButtonOk}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};

Modal.displayName = 'Modal';

Modal.propTypes = {
	additionalActionsLeft: PropTypes.node,
	additionalActionsRight: PropTypes.node,
	buttonOkProps: PropTypes.object,
	children: PropTypes.any.isRequired,
	classes: PropTypes.object,
	disableBackdropClick: PropTypes.bool,

	hide: PropTypes.object,
	isDisabledBack: PropTypes.bool,
	isLoading: PropTypes.bool,
	isOpen: PropTypes.bool.isRequired,
	onClickBack: PropTypes.func,
	onClickClose: PropTypes.func,
	onClickOk: PropTypes.func.isRequired,
	title: PropTypes.string.isRequired
};

import { useDispatch, useSelector } from 'react-redux';
import { deviceGroupsSelectors } from '../../../store/selectors';
import React, { useEffect } from 'react';
import styles from './DeviceGroupInfo.component.module.scss';
import { deviceGroupsActions } from '../../../store/actions';
import { Details } from '@indigo-cloud/common-react';
import { DeviceGroupInfoActions } from '../../../pages/DeviceGroupInfo/components/DeviceGroupInfoActions';

type DeviceGroupInfoProperties = {
	name: string;
};

export const deviceGroupInfoDataSelectors = {
	container: 'DeviceGroupInfo-container'
};

export const DeviceGroupInfo: React.FC<DeviceGroupInfoProperties> = ({ name }) => {
	const getDeviceGroupInfoOperation = useSelector(deviceGroupsSelectors.getDeviceGroupInfoOperation(name)) as any;

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(deviceGroupsActions.loadDeviceGroupInfo(name));
	}, []);

	return (<div data-cy={deviceGroupInfoDataSelectors.container} className={styles.container} >
		<DeviceGroupInfoActions deviceGroup={name} groupType={getDeviceGroupInfoOperation?.result?.data?.type || ''} />
		<Details title={'Device Group Information'} object={getDeviceGroupInfoOperation?.result?.data || {}} isLoading={ getDeviceGroupInfoOperation?.isLoading} />
	</div>);
};

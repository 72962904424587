import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { DeleteParameterValuesProperties } from '../../../DeleteParameterValues';
import { devicesSelectors } from '../../../../../store/selectors';


interface DeleteParameterValuesOperationSnackbarProperties extends Pick<DeleteParameterValuesProperties, 'agentEndpointId'> {

}

export const deleteParameterValuesOperationSnackbarDataSelectors = {
	deleteParameterValuesOperationSnackbarAlertError: 'DeleteParameterValuesOperationSnackbar-snackbarAlertError',
	deleteParameterValuesOperationSnackbarAlertSuccess: 'DeleteParameterValuesOperationSnackbar-snackbarAlertSuccess',
	deleteParameterValuesOperationSnackbarError: 'DeleteParameterValuesOperationSnackbar-snackbarError',
	deleteParameterValuesOperationSnackbarForm: 'DeleteParameterValuesOperationSnackbar-form',
	deleteParameterValuesOperationSnackbarModal: 'DeleteParameterValuesOperationSnackbar-modal',
	deleteParameterValuesOperationSnackbarSuccess: 'DeleteParameterValuesOperationSnackbar-snackbarSuccess'
};

export const DeleteParameterValuesOperationSnackbar: React.FC<DeleteParameterValuesOperationSnackbarProperties> = ({ agentEndpointId }) => {

	const deleteDeviceParameterValuesOperation = useSelector(devicesSelectors.deleteDeviceParameterValuesOperation(agentEndpointId)) as any;
	const deleteDeviceParameterValuesOperationResults = useSelector(devicesSelectors.deleteDeviceParameterValuesOperationResults(agentEndpointId)) as any;
	const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean | 'success' | 'error'>(false);

	const operationError = typeof deleteDeviceParameterValuesOperation?.error === 'string' ? deleteDeviceParameterValuesOperation.error : deleteDeviceParameterValuesOperation?.error?.message;
	const operationResponse = deleteDeviceParameterValuesOperationResults;

	const didOperationSucceed = deleteDeviceParameterValuesOperation?.didSucceed === true;
	const didOperationError = deleteDeviceParameterValuesOperation?.hasError === true;

	const operationRequestParameterPaths = deleteDeviceParameterValuesOperationResults?.affectedPaths ;

	const shouldOpenSnackbar = didOperationSucceed || didOperationError;

	useEffect(() => {
		if (shouldOpenSnackbar) {
			setShouldShowSnackbar(didOperationSucceed ? 'success' : 'error');
		}
	}, [
		shouldOpenSnackbar
	]);

	return (<>
		<Snackbar open={shouldShowSnackbar === 'error'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={deleteParameterValuesOperationSnackbarDataSelectors.deleteParameterValuesOperationSnackbarError}>
			<Alert variant="filled" severity="error" onClose={() => setShouldShowSnackbar(false)} data-cy={deleteParameterValuesOperationSnackbarDataSelectors.deleteParameterValuesOperationSnackbarAlertError}>
				An error occurred while deleting {operationRequestParameterPaths?.length} parameter value{operationRequestParameterPaths?.length === 1 ? '' : 's'}: {operationError}
			</Alert>
		</Snackbar>
		<Snackbar open={shouldShowSnackbar === 'success'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={deleteParameterValuesOperationSnackbarDataSelectors.deleteParameterValuesOperationSnackbarSuccess}>
			<Alert variant="filled" severity="success" onClose={() => setShouldShowSnackbar(false)} data-cy={deleteParameterValuesOperationSnackbarDataSelectors.deleteParameterValuesOperationSnackbarAlertSuccess}>
				{operationRequestParameterPaths?.length} parameter value{operationRequestParameterPaths?.length === 1 ? '' : 's'} deleted successfully.
			</Alert>
		</Snackbar>
	</>);
};

import React, { useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import * as PropTypes from 'prop-types';
import styles from './ActivationWindow.component.module.scss';
import { Field, FieldArray, FormikHelpers, FormikState } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import { Button, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import { DeviceGroupConfigurationInputFormValues } from '../../DeviceGroupConfigurationInputForm';
import Box from '@mui/system/Box';


export interface ActivationProperties extends Pick<FormikHelpers<DeviceGroupConfigurationInputFormValues> & FormikState<DeviceGroupConfigurationInputFormValues>, 'setFieldValue' | 'errors' | 'values'> {
	isSubmitting: boolean;
}

const updateItemAtIndex = (index: any, newValue: any, updateFunction: any) => {
	const updateCallback = (previousItems: any) => {
	  const updatedItems = [...previousItems];
	  updatedItems[index] = newValue;
	  return updatedItems;
	};
	updateFunction(updateCallback);
};

const removeAndFillLastIndex = (indexToRemove: number, initialValue: string, updateFunction: any) => {
	const updateCallback = (previousState: any[]) => {
	  const updatedState = previousState.filter((_, index) => index !== indexToRemove);
	  updatedState.push(initialValue);
	  return updatedState;
	};
	updateFunction(updateCallback);
};

export const ActivationWindow: React.FC<ActivationProperties>  = (({values, isSubmitting, errors, setFieldValue}) => {
	
	const maxActivationInstant = 5;
	const [startDateDisableClass, setStartDateDisableClass] = useState(Array.from({length: maxActivationInstant}).fill(styles.disabledInput));
	const [endDateDisableClass, setEndDateDisableClass] = useState(Array.from({length: maxActivationInstant}).fill(styles.disabledInput));
	
	const startMinDateTime = new Date();
	const [endMinDateTime, setEndMinDateTime] = useState(Array.from({length: maxActivationInstant}).fill(startMinDateTime));

	const setDatePickerEnableDisable = (mode: string, index: number) => {
		if(mode === 'Immediately') {
			updateItemAtIndex(index, '', setStartDateDisableClass);
			updateItemAtIndex(index, styles.disabledInput, setEndDateDisableClass);
		} else if(mode === 'WhenIdle') {
			updateItemAtIndex(index, '', setStartDateDisableClass);
			updateItemAtIndex(index, '', setEndDateDisableClass);
		} else {
			updateItemAtIndex(index, styles.disabledInput, setStartDateDisableClass);
			updateItemAtIndex(index, styles.disabledInput, setEndDateDisableClass);
		}
	}

	return (
		<Box position="relative">
			<Typography variant="h6" color="primary" >
				Activation Window
			</Typography>

			<Box position="absolute" top={4} right={24}>
				<Button color="primary" onClick={() => {
					setFieldValue('activationWindowRows',[{}])
				}}>Reset</Button>
			</Box>
			
			<FieldArray name="activationWindowRows">
				{({ remove, push }) => (
					<div>
						{values.activationWindowRows.map((row, index: number) => (
							<div key={index} className={styles.activationRows}>
								<Field
									disabled={isSubmitting}
									name={`activationWindowRows[${index}].mode`}
									component={Select}
									label="Mode"
									className={styles.activationRowMode}
									type="select"
									value={values.activationWindowRows[index].mode ? values.activationWindowRows[index].mode : ''}
									onChange={(event_: any) => setDatePickerEnableDisable(event_.target.value, index)}
								>
									<MenuItem value='Immediately'>Immediately</MenuItem>
									<MenuItem value='WhenIdle'>WhenIdle</MenuItem>
								</Field>
								{/* // TODO: Move this to formik-mui. having problems with formik0mui where the date displays as today's date (instead of empty) when the form value is undefined */}
								<Field 
									name={`activationWindowRows[${index}].startTime`}
									type="text"
									component={TextField}>
									{({ field, form }: { field: any, form: any }) => (
										<DateTimePicker
											{...field}
											disabled={isSubmitting}
											format="dd/MM/yyyy HH:mm"
											label="Start Date and Time"
											minDateTime={startMinDateTime}
											onChange={(newValue) => {
												form.setFieldValue(field.name, newValue);
												updateItemAtIndex(index, newValue as Date, setEndMinDateTime);
											}}
											className={startDateDisableClass[index]}
											value={values.activationWindowRows[index].startTime || ''}
										/>
									)}
								</Field>
								<Field
									name={`activationWindowRows[${index}].endTime`}
									type="text"
									component={TextField}>
									{({ field, form }: { field: any, form: any }) => (
										<DateTimePicker
											{...field}
											disabled={isSubmitting}
											format="dd/MM/yyyy HH:mm"
											label="End Date and Time"
											minDateTime={endMinDateTime[index]}
											onChange={(newValue) => form.setFieldValue(field.name, newValue)}
											className={endDateDisableClass[index]}
											value={values.activationWindowRows[index].endTime || ''}
										/>
									)}
								</Field>

								<Tooltip title="Remove field">
									<IconButton onClick={() => {
										remove(index);
										removeAndFillLastIndex(index, '', setStartDateDisableClass);
										removeAndFillLastIndex(index, '', setEndDateDisableClass);
									}} size={'small'}>
										<Remove style={{ color: '#e57373'}} />
									</IconButton>
								</Tooltip>
								
								<div>
									{errors.activationWindowRows && Array.isArray(errors.activationWindowRows) && errors.activationWindowRows[index] && Object.entries(errors.activationWindowRows[index]).map(([key]) => (
										<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error" key={key}>
											{(errors.activationWindowRows?.[index] as any)[key]}
										</Typography>
                  				))}
								</div>
							</div>
						))}
						<div className={styles.commonErrorContainer}>
							{errors.activationWindowRows && !Array.isArray(errors.activationWindowRows) && (
								
								<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error">{String(errors.activationWindowRows)}</Typography>
							)}
						</div>
						{(values.activationWindowRows.length < maxActivationInstant) ? 
							<Tooltip title="Add new field">
								<IconButton onClick={() => push({})} size={'small'}>
									<Add color={'primary'} />
								</IconButton>
							</Tooltip>
							: (<p className={styles.maximumWindowMessage}>Maximum windows allowed: {maxActivationInstant} </p>)
						}
					</div>
				)}
			</FieldArray>
		  </Box>
	);
});

ActivationWindow.displayName = 'Activation Window';

ActivationWindow.propTypes = {
	errors: PropTypes.any,
	isSubmitting: PropTypes.any,
	setFieldValue: PropTypes.func.isRequired,
	values: PropTypes.any
};
import React, { useContext, useEffect, useState } from 'react';
import styles from './DeviceExecuteCommands.component.module.scss';

import { DeviceExecuteCommandsOperationSnackbar } from './components/DeviceExecuteCommandsOperationSnackbar';
import { useSelector } from 'react-redux';
import { devicesSelectors, locationSelectors } from '../../../store/selectors';
import _ from 'lodash';
import { DeviceInfoModalType } from '../../../pages/DeviceInfo/DeviceInfo.page';
import { PrivateRoute, Role } from '@indigo-cloud/common-react';
import { AppRouteNames, appRoutes, pages } from '../../../App.router';
import { AwsExportsContext } from '../../../components';
import { useHistory } from 'react-router-dom';
import { ExecuteDeviceCommandResults } from '../../../pages/DeviceExecuteCommand/components/ExecuteCommandResults';
import { DeviceCommand } from '../../types/Device';

export interface DeviceExecuteCommandsProperties {
	agentEndpointId: string;
}

export const DeviceExecuteCommands: React.FC<DeviceExecuteCommandsProperties> = ({agentEndpointId}) => {
	const deviceExecuteCommandsOperation = useSelector(devicesSelectors.executeDeviceCommandOperation(agentEndpointId));

	const history = useHistory();
	const didOperationSucceed = deviceExecuteCommandsOperation?.didSucceed === true;

	const previousRoute = useSelector(locationSelectors.prevRoute);
	const didNavigateFromDeviceInfoPage = previousRoute?.location?.pathname === appRoutes.DeviceInfo;

	const contextAwsExports = useContext(AwsExportsContext);

	// TODO: Refactor with GPV/SPV. makybe turn this page into a re-usable component
	const onModalBack = () => {
		if (didNavigateFromDeviceInfoPage) {
			history.goBack();
		} else {
			// New tab loaded the child/next route directly and has no history, or the user directly changed the address bar to the nested route from another page. Both cases require push instead of goBack
			history.push(appRoutes.DeviceInfo.replace(':agentEndpointId', agentEndpointId));
		}
	}

	useEffect(() => {
		if (didOperationSucceed === true) {
			onModalBack();
		}
	}, [
		didOperationSucceed
	]);

	const onClickUpdateRequest = (commands: DeviceCommand[]) => {
		history.push(appRoutes.DeviceExecuteCommand.replace(':agentEndpointId', agentEndpointId).replace(':commands?', JSON.stringify(commands)));
	}

	return (<div className={styles.container}>
		<DeviceExecuteCommandsOperationSnackbar agentEndpointId={agentEndpointId} />

		{/* <ExecuteDeviceCommandResults agentEndpointId={agentEndpointId} onClickUpdateRequest={onClickUpdateRequest} /> */}

		<PrivateRoute roles={[ Role.Device, Role.DeviceGroup, Role.Admin]} path={appRoutes.DeviceExecuteCommand} exact component={pages[AppRouteNames.DeviceExecuteCommand]} awsExports={contextAwsExports} />
	</div>);
};

import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Paper, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CSVDownload } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { theme } from '../../../App.theme';
import { devicesSelectors } from '../../../store/selectors';
import { Notification } from '../../types';
import styles from './NotificationsTable.component.module.scss';
import { PaginatedTable } from '@indigo-cloud/common-react';
import { Column } from 'react-table';
import { devicesActions } from '../../../store/actions'
import { debounce } from 'lodash';



interface NotificationsTableProperties {
	name: string
	isLoading?: boolean;
	inherittedFilters?: Record<string, unknown>;
	customStyle?: Record<string, unknown>;
}


export const notificationsTableDataSelectors = {
	container: 'NotificationsTable-container'
};

// Reuse this component in Device Page to list notifications

export const NotificationsTable: React.FC<NotificationsTableProperties> = ({ inherittedFilters, name, isLoading, customStyle = {} }) => {

	const reference = useRef<any>(null);
	const Tempreference = useRef<any>(null);

	const [colorReset, setColorReset] = useState(false); //[REMOVE LATER]

	const Filter = (property: any) => {

		return (
			<TextField
				placeholder={'Filter...'}
					 value={property.column.filterValue ? property.column.filterValue : ''}
					 onChange={event => property.setFilter(property.column.id, event.target.value)}
			/>
		 )
	}

	const columns = useMemo<Column<Notification>[]>(
		() => [
			{
				Filter,
				Header: 'ID',
				accessor: 'msgId' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Type',
				accessor: 'msgType' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Event name',
				accessor: 'eventName' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Event cause',
				accessor: 'eventCause' as any,
				disableFilters: true
			},
			{
				Filter,
				Header: 'Param path',
				accessor: 'paramPath' as any,
				disableFilters: true
			},
			{
				Cell: ({ value }: { value: any }) => (
					<div style={{ maxWidth: '120px', overflowWrap: 'break-word' }}>{value}</div>
				),
				Filter,
				Header: 'Param value',
				accessor: 'paramValue' as any,
				disableFilters: true
			},			{
				Filter,
				Header: 'Time',
				accessor: 'time' as any,
				disableFilters: true
			}
		], []
	);
	const [pageSize, setPageSize] = React.useState(10);
	const [pageIndex, setPageIndex] = React.useState(0);
	const [filters, setFilters] = React.useState({
		...inherittedFilters
	});
	const listNotificationCurrentPage = useSelector(devicesSelectors.getNotificationsOperationResultsPage(pageIndex, name, filters));

	const listNotificationOperation = useSelector(devicesSelectors.getNotificationsOperation(name));

	const [listNotificationOperationAll, listNotificationOperationLastPage] = useSelector(devicesSelectors.getNotificationsOperationAll(name, filters));

	const [initiateDownload, setInitiateDownload] = useState(false);
	const [collectingtAllData, setCollectingtAllData] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(devicesActions.loadDeviceNotifications({agentEndpointId: name, filters, nextToken: ''}));

		return () => {
			dispatch(devicesActions.loadDeviceNotificationsClear());
		}
	}, []);

	// Define a default UI for filtering
	function DefaultColumnFilter({
		column: { filterValue, preFilteredRows, setFilter }
	}: any) {
		const count = preFilteredRows.length

		return (
			<div style={{
				'borderBottom': '2px solid rgba(224, 224, 224, 1)',
				display: 'flex',
				justifyContent: 'center',
				padding: '10px'
			}}>
				<FilterListIcon />
				<input
					value={filterValue || ''}
					onChange={event => {
						setFilter(event.target.value || undefined) // Set undefined to remove the filter entirely
					}}
					placeholder={`Search ${count} records...`}
				/>
			</div>
		)
	}

	const defaultColumn: any = React.useMemo(
		() => ({
		  Filter: DefaultColumnFilter
		}),
		[]
	);

	const onFiltersChanged = useCallback(debounce((filtersNext: any) => {
		const filtersObject = filtersNext.reduce((result: Record<string, unknown>, current: Record<string, unknown>) => {
			return Object.assign({
				[current.id as string]: current.value
			}, result);
		}, {});
		const filtersNextParsed = {
			...inherittedFilters,
			...filtersObject

		}

		if (JSON.stringify(filtersNextParsed) === JSON.stringify(filters || '{}')) {
			return;
		}
		setFilters(filtersNextParsed);
		setPageIndex(0);

		const hasRequestedPageInState = !!listNotificationOperation?.result?.[`${pageIndex.toString()}_${JSON.stringify(filtersNextParsed)}`];

		if (!hasRequestedPageInState) {
			dispatch(devicesActions.loadDeviceNotifications({agentEndpointId: name, filters: filtersNextParsed, pageIndex: 0}));
		}
	}, 800), [pageSize, pageIndex, listNotificationCurrentPage, inherittedFilters]);

	const onPageChanged = useCallback((page) => {
		setPageIndex(page);
		const hasRequestedPageInState = !!listNotificationOperation?.result?.[`${page.toString()}_${JSON.stringify(filters)}`];
		if (!hasRequestedPageInState) {
			dispatch(devicesActions.loadDeviceNotifications({agentEndpointId: name, filters, nextToken: listNotificationCurrentPage?.pagination.next, pageIndex: page}));
		}

	}, [pageSize, listNotificationCurrentPage?.pagination?.next, listNotificationOperation?.result]);

	const hasCursor = useMemo(() => {
		return (listNotificationCurrentPage?.pagination?.cursor != '')
	}, [listNotificationCurrentPage?.pagination]);
	
	useEffect(() => {
		// Enables user to download data more than once
		if (initiateDownload) {
		  setInitiateDownload(false)
		}
	}, [initiateDownload]);

	useEffect(() => {
		if(!listNotificationOperation?.isLoading && collectingtAllData){
			setInitiateDownload(true);
			setCollectingtAllData(false);
		}
	}, [listNotificationOperation?.isLoading]);

	const notificastionsCSVDownload = () => {
		if(listNotificationOperationLastPage.pagination.cursor !== ''){
			setCollectingtAllData(true);
			dispatch(devicesActions.loadDeviceNotifications({
				agentEndpointId: name, nextToken: listNotificationOperationLastPage.pagination.next,
				filters,
				pageIndex: listNotificationOperationLastPage.pageIndex + 1, 
				collectAllData: true
			}));
		} else {
			setInitiateDownload(true);
		}
	}
	
	const generateView = () => (
		<div data-cy={notificationsTableDataSelectors.container} id={styles.container} >
			{initiateDownload && (
				<CSVDownload
					data={listNotificationOperationAll}
					filename={`Devices in ${name}`}
					target="_self"
				/>
			)}
			{(listNotificationOperationAll.length > 0) && <div className={styles.downloadIcon}>
				{!collectingtAllData ?
					<Button
						variant="contained"
						startIcon={<FileDownloadOutlined />}
						onClick={notificastionsCSVDownload}
						aria-label="Export as CSV"
						title="Export as CSV"
				  	>
						Download Event(s)
				  	</Button> : 
					<CircularProgress data-cy='' color='primary' size={'2rem'} />}
			</div>}

			<Paper elevation={2} style={{ margin: '20px', overflow: 'auto', padding: '20px'}}>
				<PaginatedTable
					colorReset={colorReset}
					setColorReset={setColorReset}
					ref={Tempreference}
					columns={columns}
					data={listNotificationCurrentPage?.data || []}
					defaultColumn={defaultColumn}
					hasCursor={hasCursor}
					onFiltersChanged={onFiltersChanged}
					onPageChanged={onPageChanged}
					pageIndex={pageIndex}
					loading={!!listNotificationOperation?.isLoading}
					theme={theme}
					customStyle={{bodyAlign: 'center', headerAlign: 'center', ...customStyle }}
				/>
			</Paper>
		</div>);
	return (
		<>
			{(isLoading && !collectingtAllData)? <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div> : generateView()}
		</>
	);
};

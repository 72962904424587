import {useEffect, useState} from 'react';
import {Auth} from '@aws-amplify/auth';
import {Hub} from 'aws-amplify';
import {Role, populateGroups, userGroups} from '@indigo-cloud/common-react';
import jwtDecode from 'jwt-decode';



export const useAuthorisation = () => {
	const [userAuthorisationGroups, setUserAuthorisationGroups] = useState({} as {[key in Role]: boolean});

	useEffect(() => {
		const fetchUserAuthorisationGroups = async () => {
			try {
				let user: any;
				try {
					user = await Auth.currentAuthenticatedUser();

				}
				catch {
					user = await new Promise((resolve) => {
						Hub.listen('auth', async (data) => {
							const {payload} = data;

							if (payload.event === 'signIn') {
								resolve(await Auth.currentAuthenticatedUser())
							}

						})
					});
				}

				const populateGroupsIfNotExists = async () => {
					if (!userGroups.groups?.length) {


						await populateGroups();
					}
				}



				await populateGroupsIfNotExists();


				const accessToken = user.token;
				const accessTokenDecoded = jwtDecode(accessToken) as any;

				const groups = accessTokenDecoded['cognito:groups'];



				setUserAuthorisationGroups(userGroups.groups.reduce((previous, current) => {
					previous[current as keyof typeof previous] = groups.includes(current);

					return previous;
				}, {} as {[key in Role]: boolean}));
			} catch (error) {
				console.log('Error fetching user authorisation groups:', error);
			}
		};

		fetchUserAuthorisationGroups();
	}, []);


	return userAuthorisationGroups;
};

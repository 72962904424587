import {Auth, Hub} from 'aws-amplify';

import { HubCapsule } from '@aws-amplify/core';

import {useEffect, useReducer, useState} from 'react';

import {authActions} from '../../../store/actions';
import {authReducers, initialAuthState} from '../../../store/reducers';
import { signoutComponent } from '@indigo-cloud/common-react';

export const useAmplifyAuth = () => {
	const [state, dispatch] = useReducer(authReducers, initialAuthState);
	const [shouldTriggerLoad, setShouldTriggerLoad] = useState(false);

	useEffect(() => {

		let isMounted = true;

		const loadUserData = async () => {
			if (isMounted) {
				dispatch(authActions.loadAuthUser());
			}
			try {
				if (isMounted) {
					const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();
					if (currentAuthenticatedUser) {
						dispatch(authActions.loadAuthUserSuccess(currentAuthenticatedUser));
					}
				}
			} catch (error) {
				if (isMounted) {
					dispatch(authActions.loadAuthUserError(error));
				}
			}
		};

		const onAuthEvent = (hubCapsule: HubCapsule) => {
			const {
				payload: {event}
			} = hubCapsule;


			switch (event) {
			case 'signIn': {
				if (isMounted) {
					setShouldTriggerLoad(true);
				}
				break;
			}
			default:
			}
		};



		const registerHubSubscriptions = () => {

			Hub.listen('auth', (hubCapsule) => {
				onAuthEvent(hubCapsule);
			});
		};

		registerHubSubscriptions();
		loadUserData();

		return () => {
			isMounted = false;
		};
	}, [shouldTriggerLoad]);

	const handleSignout = async () => {
		await signoutComponent(dispatch, authActions.loadAuthUserClear())
		setShouldTriggerLoad(false);
	};


	return {handleSignout, state};
};

export default useAmplifyAuth;

import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import React, {useMemo} from 'react';
import * as PropTypes from 'prop-types';
import {Link, LinkProps, useLocation} from 'react-router-dom';
import styles from './DrawerNavigationList.component.module.scss';
import ListItemIcon from '@mui/material/ListItemIcon';

import Tooltip from '@mui/material/Tooltip';
import {OverridableComponent} from '@mui/material/OverridableComponent';
import {SvgIconTypeMap} from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import {theme} from '../../../../suota-ui/src/App.theme';
import {useTheme} from '@mui/styles';
import {Theme} from '@mui/material/styles';

export interface DrawerNavigationListLinkProperties {

	label?: string;
	routeKey: string;
	icon: string | OverridableComponent<SvgIconTypeMap<any, 'svg'>>;
	appRoutes: {[k: string]: string};

	drawerNavigationOptions?: {
		isRootItem?: boolean;
		isOpen: boolean;
		applicationName: string;
	};
	linkProps?: Partial<LinkProps>;
	themeColour: string
}

export const drawerNavigationListLinkDataSelectors = {
	drawerNavigationListDivider: 'DrawerNavigationListLink-divider',
	drawerNavigationListLinkContainer: 'DrawerNavigationListLink-container',
	drawerNavigationListLinkItem: 'DrawerNavigationListLink-listItem',
	drawerNavigationListLinkItemIcon: 'DrawerNavigationListLink-listItemIcon',
	drawerNavigationListLinkItemText: 'DrawerNavigationListLink-listItemText',
	drawerNavigationListLinkToolTip: 'DrawerNavigationListLink-tooltip'
};

export const DrawerNavigationListLink: React.FC<DrawerNavigationListLinkProperties> = ({label, routeKey, drawerNavigationOptions, icon, appRoutes, linkProps, themeColour}) => {


	const isDrawerNavigation = drawerNavigationOptions !== undefined;
	const iconSize = isDrawerNavigation ? 26 : 46;
	const theme = useTheme<Theme>();
	const location = useLocation();
	const isRouteActive = location.pathname === appRoutes[routeKey as keyof typeof appRoutes];
	const Icon = icon as any;

	const tooltipTitle = useMemo(() => {
		let title = '';
		title = drawerNavigationOptions?.applicationName ? `${drawerNavigationOptions?.applicationName} - ${routeKey}` : routeKey;

		return title;

	}, [drawerNavigationOptions?.applicationName]);

	const innerContent = <ListItem
		button
		disableGutters
		style={{

			borderLeft: isDrawerNavigation ? `4px solid ${isRouteActive ? theme.palette.primary.main : 'transparent'}` : 0,
			color: theme.palette.primary.main,
			display: 'flex',
			flexDirection: 'row',

			height: isDrawerNavigation ? 'initial' : '100%',
			justifyContent: 'center',

			padding: drawerNavigationOptions?.isRootItem ? '16px 12px 16px 6px' : '8px 8px 8px 2px'
		}}
		data-cy={drawerNavigationListLinkDataSelectors.drawerNavigationListLinkItem}
	>
		<ListItemIcon className={styles.listItemIcon} style={{height: `${iconSize}px`, width: `${iconSize}px`}} data-cy={drawerNavigationListLinkDataSelectors.drawerNavigationListLinkItemIcon}>
			{icon && typeof icon === 'string' ? <img src={icon} width={iconSize.toString()} height={iconSize.toString()} /> : <Icon style={{color: '#5514b4', fontSize: `${iconSize}px`}} />}
		</ListItemIcon>
		{drawerNavigationOptions?.isOpen && <ListItemText
			primaryTypographyProps={{
				color: 'primary',
				style: {
					fontWeight: isDrawerNavigation && drawerNavigationOptions?.isRootItem ? 'bold' : 'normal',
					marginLeft: '12px'
				}
			}}
			primary={label ? label : (isDrawerNavigation && drawerNavigationOptions?.isRootItem ? drawerNavigationOptions?.applicationName : routeKey.replace(/([A-Z])/g, ' $1').trim())}
			className={`${styles.linkText} ${styles.classNameListItemTextActive}`}
			data-cy={drawerNavigationListLinkDataSelectors.drawerNavigationListLinkItemText}
		/>}
	</ListItem>;

	const content = <>
		<Link
			to={{
				pathname: appRoutes[routeKey as keyof typeof appRoutes]
			}}

			key={routeKey}
			{...linkProps}
			className={styles.link}
			data-cy={`Drawer-link Drawer-link-${routeKey}`}>
			{isDrawerNavigation ?  <Tooltip title={tooltipTitle} placement={isDrawerNavigation ? 'right' : 'bottom'} key={routeKey} data-cy={drawerNavigationListLinkDataSelectors.drawerNavigationListLinkToolTip}>
				{innerContent}
			</Tooltip> : innerContent}
		</Link>
		{ drawerNavigationOptions?.isRootItem && <Divider data-cy={drawerNavigationListLinkDataSelectors.drawerNavigationListDivider} />}
	</>;


	if (!isDrawerNavigation) {
		return <Box boxShadow={1}  marginTop={2} marginLeft={2}
			bgcolor="background.paper" className={styles.box}>
			{content}
			<div className={styles.label} >{routeKey}</div>
		</Box>
	}

	return content;
};

DrawerNavigationListLink.propTypes = {
	appRoutes: PropTypes.objectOf(PropTypes.string.isRequired).isRequired,



	drawerNavigationOptions: PropTypes.shape({
		applicationName: PropTypes.string.isRequired,
		isOpen: PropTypes.bool.isRequired,
		isRootItem: PropTypes.bool.isRequired
	}),
	icon: PropTypes.string.isRequired,

	label: PropTypes.string,
	linkProps: PropTypes.any,
	routeKey: PropTypes.string.isRequired,
	themeColour: PropTypes.string.isRequired
};

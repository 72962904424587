import { combineReducers } from 'redux';
import { Firmware } from '../../../shared/types';
import { StateType } from 'typesafe-actions';

import { firmwareActions } from '../../actions';
import {
	CREATE_FIRMWARE,
	CREATE_FIRMWARE_ERROR,
	CREATE_FIRMWARE_SUCCESS,
	DELETE_FIRMWARE,
	DELETE_FIRMWARE_ERROR,
	DELETE_FIRMWARE_SUCCESS,
	LIST_FIRMWARE,
	LIST_FIRMWARE_CLEAR,
	LIST_FIRMWARE_ERROR,
	LIST_FIRMWARE_SUCCESS,
	UPDATE_FIRMWARE,
	UPDATE_FIRMWARE_ERROR,
	UPDATE_FIRMWARE_SUCCESS
} from '../../constants';
import { DeepReadonlyObject, OperationState, PaginatedResults, getLoadingStatusInProgress, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';

export type FirmwareState = DeepReadonlyObject<{
	createFirmwareOperation: OperationState<any, Error>;
	deleteFirmwareOperation: OperationState<any, Error>;
	listFirmwareOperation: OperationState<{ [pageNumber: number]: PaginatedResults<Firmware>}>;
	updateFirmwareOperation: OperationState<any, Error>;
}>;
export const initialFirmwareState: FirmwareState = {
	createFirmwareOperation: getStateInitial<any, Error>(),
	deleteFirmwareOperation: getStateInitial<any, Error>(),
	listFirmwareOperation: getStateInitial(),
	updateFirmwareOperation: getStateInitial<any, Error>()
};
export const firmwareReducers = combineReducers<FirmwareState, firmwareActions>({
	/**
	 * CREATE FIRMWARE
	 */
	createFirmwareOperation: (state = initialFirmwareState.createFirmwareOperation, action) => {
		switch (action.type) {
		case CREATE_FIRMWARE: {
			return getStateInProgress(action.payload);
		}
		case CREATE_FIRMWARE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case CREATE_FIRMWARE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * DELETE FIRMWARE
	 */
	deleteFirmwareOperation: (state = initialFirmwareState.deleteFirmwareOperation, action) => {

		switch (action.type) {
		case DELETE_FIRMWARE: {
			return getStateInProgress(action.payload);
		}
		case DELETE_FIRMWARE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case DELETE_FIRMWARE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * LIST FIRMWARE
	 */
	listFirmwareOperation: (state = initialFirmwareState.listFirmwareOperation, action) => {
		switch (action.type) {
		case LIST_FIRMWARE: {
			return {
				...state,
				...getLoadingStatusInProgress()
			};
		}
		case LIST_FIRMWARE_SUCCESS: {
			const {result, filters, pageIndex} = action.payload;

			return getStateSuccess({
				...state.current,
				[`${pageIndex}_${JSON.stringify(filters)}`]: result
			}, state);
		}
		case LIST_FIRMWARE_ERROR: {
			const {error} = action.payload;

			return getStateError(error, state);
		}

		case LIST_FIRMWARE_CLEAR: {
			return {
				...state,
				...getStateInitial()
			}
		}
		default:
			return state;
		}
	},
	/**
	 * UPDATE FIRMWARE
	 */
	updateFirmwareOperation: (state = initialFirmwareState.updateFirmwareOperation, action) => {
		switch (action.type) {
		case UPDATE_FIRMWARE: {
			return getStateInProgress(action.payload);
		}
		case UPDATE_FIRMWARE_SUCCESS: {
			const { response} = action.payload;

			return getStateSuccess(response, state);
		}
		case UPDATE_FIRMWARE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type firmwareReducers = StateType<typeof firmwareReducers>;

import React, {useEffect, useState} from 'react';
import * as PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

import {useDispatch} from 'react-redux';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';

import {Operation, OperationStatusType} from '../../store/utils';
import _ from 'lodash';
import CloseIcon from '@mui/icons-material/Close';

export interface OperationSnackbarProperties<Entity = unknown, OperationResult = unknown, RequestActionPayload = unknown, OperationType extends Operation<OperationResult, {error: Error}, RequestActionPayload> = any> {
	operation: OperationType;
	isMultiple?: boolean;
	uniqueIdentifierMapper: (entity: Entity | Entity[]) => string,
	requestActionType: string;
	action: 'create' | 'update' | 'delete' | 'list' | 'search' | 'download' | 'execute';
	itemName?: 'firmware' | 'release' | 'rule' | 'rules' | 'user' | 'device' | 'deviceGroup' | 'report';
	urlCreators?: {
		view: (entity:Entity) => string;
	},
	customActionName?: string;
	messageSuffix?: string;
	errorSuffix?: string;
}

export const operationSnackbarDataSelectors = {
	alert: 'operationSnackbar-alert',

	buttonRetry: 'operationSnackbar-button-retry',
	buttonView: 'operationSnackbar-button-view',
	message: 'operationSnackbar-message',
	snackbar: 'operationSnackbar-container'
};

// TODO: Implement <OperationSnackbars helper component to combine/create <OperationSnackbar each for Create / Update / Delete operations.
export const OperationSnackbar = <
	Entity,
	OperationResult = unknown,
	OperationRequest = unknown
	>({
		action,
		uniqueIdentifierMapper,
		isMultiple,
		requestActionType,
		itemName,
		operation,
		urlCreators,
		customActionName,
		messageSuffix,
		errorSuffix
	}: OperationSnackbarProperties<Entity, OperationResult, OperationRequest>) => {

	const [isOpen, setIsOpen] = useState<'success' | 'error' | false>(false);

	const didOperationSucceed = operation?.didSucceed === true;
	const didOperationError = operation?.hasError === true;

	const shouldOpenSnackbar = didOperationSucceed || didOperationError;

	const history = useHistory();

	const [didLoad, setDidLoad] = useState(false);

	// [10/02/22]: Hack to stop operationSnackbars showing again after navigation to Dasbhboar,d then back to Releases page (after deleting/creating/updating a record)
	useEffect(() => setDidLoad(true), [])

	useEffect(() => {
		if (!didLoad) {
			return
		}
		if (shouldOpenSnackbar) {
			// Don't show 'success' snackbar for the list operation
			if (action === 'list') {
				if (didOperationSucceed === false) {
					setIsOpen(didOperationSucceed ? 'success' : 'error');
				}
			} else {
				setIsOpen(didOperationSucceed ? 'success' : 'error');
			}

		} else {
			setIsOpen(false);
		}
	}, [
		shouldOpenSnackbar
	]);

	const itemNameCapitalized = _.capitalize(itemName);
	const dispatch = useDispatch();

	// TODO: Improve error handling
	const error = typeof operation?.error === 'string' ? operation?.error : operation?.error?.message;

	const currentSeverity = (didOperationSucceed ? 'success' : 'error') as OperationStatusType;
	const currentItem = operation?.request?.model;
	const currentItemIdentifier = uniqueIdentifierMapper(currentItem);

	const createMessageError = () => {
		if (action === 'search') {
			return `We couldn\'t find any ${itemName} to show for '${currentItemIdentifier}'`;
		}
		const actionNameParsed = action.toLowerCase().charAt(action.length - 1) === 'e' ? `${action.slice(0, -1)}ing the` : `${action}ing the`;
		const itemNameCapitalizedParsed = action === 'list' ? `${itemNameCapitalized}s` : itemNameCapitalized;
		const itemIdentifierParsed = action === 'list' ? '' : ` "${currentItemIdentifier}"`;
		const suffixMessage = (errorSuffix && errorSuffix.length > 0) ? ` ${errorSuffix}` : '';
		return `An error occurred while ${customActionName ? _.capitalize(customActionName) : `${actionNameParsed} ${itemNameCapitalizedParsed}${itemIdentifierParsed}`}: ${error}. ${suffixMessage}`;
	};

	const createMessageSuccess = () => {
		const actionPastTense = action.slice(-1) === 'e' ? `${action}d` : `${action}ed`;
		const suffixMessage = (messageSuffix && messageSuffix.length > 0) ? ` ${messageSuffix}` : '';
		return `${customActionName ? `${_.capitalize(customActionName)} completed` : `The ${itemNameCapitalized} "${currentItemIdentifier}" ${isMultiple ? 'were' : 'was'} ${actionPastTense}`} successfully.${suffixMessage}`
	};

	const messages = {
		error: createMessageError(),
		success: createMessageSuccess()
	};
	const currentMessage = messages[currentSeverity];

	const onClickView = () => {
		const url = urlCreators?.view(currentItem);

		history.push({pathname: url});
	}

	const onClickRetry = () => {
		dispatch({
			payload: operation?.request,
			type: requestActionType
		});
	}

	return (
		<Snackbar anchorOrigin={{horizontal: 'center', vertical: 'bottom'}} open={!!isOpen} autoHideDuration={10_000} onClose={() => setIsOpen(false)} data-cy={operationSnackbarDataSelectors.snackbar}>
			<Alert  variant="filled" severity={currentSeverity} onClose={() => setIsOpen(false)} data-cy={operationSnackbarDataSelectors.alert} action={
				  <>
					{currentSeverity === 'error' && <Button color="secondary" size="small" onClick={onClickRetry} data-cy={operationSnackbarDataSelectors.buttonView}>
						Retry
					</Button>}
					{urlCreators?.view && currentSeverity !== 'error' && action !== 'delete'  && action !== 'download' && <Button color="secondary" size="small" onClick={onClickView} data-cy={operationSnackbarDataSelectors.buttonView}>
						View
					</Button>}
					<IconButton size="small" aria-label="close" color="inherit" onClick={() => setIsOpen(false)}>
						<CloseIcon fontSize="small" />
					</IconButton>
				</>
			}>
				<span data-cy={operationSnackbarDataSelectors.message}>{currentMessage} </span>
			</Alert>
		</Snackbar>
	)

};

OperationSnackbar.propTypes = {
	action: PropTypes.oneOf(['create', 'update', 'delete', 'search', 'download']),
	itemName: PropTypes.oneOf(['firmware', 'release', 'rule', 'device', 'report']),
	operation: PropTypes.any,
	uniqueIdentifierMapper: PropTypes.func.isRequired,
	urlCreators: PropTypes.shape({
		view: PropTypes.func.isRequired
	}).isRequired
};

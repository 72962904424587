import { processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { push } from 'connected-react-router';
import { appRoutes } from '../../App.router';
import { Report, awsExports } from '../../shared';
import { authActions, reportActions } from '../actions';
import {
	APP_REPORT,
	CREATE_REPORT,
	DELETE_REPORT,
	DOWNLOAD_REPORT,
	FIRMWARE_REPORT,
	LIST_REPORTS,
	LOAD_REPORT_INFO,
	RBSID_REPORT,
	UPDATE_REPORT
} from '../constants';

const baseReportPath = '/v1/reports';

const LOCALSTORAGE_REPORTS_KEY = 'REPORTS';

const getReportsFromLocalstorage = () => {
	const reports = (localStorage.getItem(LOCALSTORAGE_REPORTS_KEY) || '[]');

	return JSON.parse(reports);
}

const savetReportsToLocalstorage = async (reports: Report[]) => {
	localStorage.setItem(LOCALSTORAGE_REPORTS_KEY, JSON.stringify(reports));

}

function* listReportsSaga() {
	try {
		// const response = yield call(
		// 	request,
		// 	{
		// 		apiName: 'usp',
		// 		awsExports,
		// 		options: {
		// 			queryStringParameters: {
		// 				cursor: undefined,
		// 				limit: 5000
		// 			}
		// 		},
		// 		path: baseReportPath
		// 	}
		// );
		// const response = [{
		// 	createdAt: moment().subtract(4, 'days').toISOString(),
		// 	id: v4(),
		// 	name: 'USP Test Reporting Dashboard 1',

		// 	widgets: [{
		// 		dataSourceId: v4(),

		// 		order: 0,
		// 		widgetId: v4()

		// 	}]
		// }, {
		// 	createdAt: moment().subtract(4, 'days').toISOString(),
		// 	id: v4(),
		// 	name: 'Test Reporting Dashboard 2',

		// 	updatedAt: moment().subtract(1, 'days').toISOString(),
		// 	widgets: [{
		// 		dataSourceId: v4(),

		// 		order: 0,
		// 		widgetId: v4()

		// 	}]
		// }] as Report[];

		const reports = getReportsFromLocalstorage();

		yield put(reportActions.listReportsSuccess({
			data: reports,
			pagination: {
				count: reports.length,
				cursor: '[cursor]',
				next: '[cursor]',
				total: reports.length
			}
		}));
	} catch (error) {
		// if (error.response?.status === 401) {
		//			yield call(Auth.signOut);
		//		}
		console.error('An error occurred while loading the reports.', error);
		yield put(reportActions.listReportsError(error));
	}
}

function* loadReportInfoSaga(action: ReturnType<typeof reportActions.loadReportInfo>) {
	const {
		payload: {reportId}
	} = action;

	try {
		const response = yield call(
			request,

			{
				apiName: 'usp',
				awsExports,
				method: 'get',
				options: {
					id: reportId
				},
				path: baseReportPath

			}
		);

		yield put(reportActions.loadReportInfoSuccess(reportId, response));

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while loading the report info', error);
		yield put(reportActions.loadReportInfoError(reportId, error));
	}
}

function* createReportSaga(action: ReturnType<typeof reportActions['createReport']>) {
	const {
		payload: {model, formikPromise, createAnother}
	} = action;

	let response = [] as Report[];
	try {

		response = getReportsFromLocalstorage();

		response.push({
			...model,
			createdAt: new Date().toISOString()
		})

		savetReportsToLocalstorage(response);

		// const response = yield call(
		// 	request,

		// 	{
		// 		apiName: 'usp',
		// 		awsExports,
		// 		method: 'post',
		// 		options: {
		// 			body: model
		// 		},
		// 		path: baseReportPath

		// 	}
		// );

		yield put(reportActions.createReportSuccess(response));
		formikPromise.resolve(response);
		yield put(reportActions.listReports());

		if (!createAnother) {
			yield put(push(appRoutes.Reports));
		}

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while creating the report', error);
		yield put(reportActions.createReportError(error));
		formikPromise.reject(error);
	}
}

function* deleteReportSaga(action: ReturnType<typeof reportActions['deleteReport']>) {
	const {
		payload: {model}
	} = action;
	try {

		// const response = yield call(
		// 	request,
		// 	{
		// 		apiName: 'usp',
		// 		awsExports,
		// 		method: 'del',
		// 		path: `${baseReportPath}/${model.id}`
		// 	}
		// );

		let response = [] as Report[];
		response = getReportsFromLocalstorage();

		response = response.filter((report) => {
			return report.id !== model.id;
		})
		savetReportsToLocalstorage(response);

		yield put(reportActions.deleteReportSuccess(response));
		yield put(reportActions.listReports());
		yield put(push(appRoutes.Reports));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the report', error, model);
		yield put(reportActions.deleteReportError(error));
	}
}

function* updateReportSaga(action: ReturnType<typeof reportActions['updateReport']>) {
	const {
		payload: {model, formikPromise}
	} = action;
	try {
		const { id, ...restReport } = model;

		// const response = yield call(
		// 	request,
		// 	{
		// 		apiName: 'usp',
		// 		awsExports,
		// 		method: 'put',
		// 		options: {
		// 			body: restReport
		// 		},
		// 		path: `${baseReportPath}/${id}`
		// 	}
		// );

		let response = [] as Report[];
		response = getReportsFromLocalstorage();

		response = response.map((report) => {
			if (report.id === id) {
				return {
					...model,
					updatedAt: new Date().toISOString()
				}
			}

			return report;
		})
		savetReportsToLocalstorage(response);

		yield put(reportActions.updateReportSuccess(response));
		yield put(reportActions.listReports());

		formikPromise.resolve(response);

		yield put(push(appRoutes.Reports));

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while updating the Report '${model.id}'.`, error);
		yield put(reportActions.updateReportError(error));
		formikPromise.reject(error);
	}
}

function* downloadReportSaga(action: ReturnType<typeof reportActions['downloadReport']>) {
	const {
		payload: {reportDate, formikPromise}
	} = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp_reporting',
				awsExports,
				path: `${baseReportPath}/devices/download/${reportDate}`
			}
		);
		yield put(reportActions.downloadReportSuccess(response.data));
		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while downloading the Report', error);
		yield put(reportActions.downloadReportError(error));
		formikPromise.reject(error);
	}
}


function* loadFirmwareReport(action: ReturnType<typeof reportActions.loadFirmwareReport>) {
	const {
		payload: {range = 3, reportFilterType = 'BY_CFU'}
	} = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp_reporting',
				awsExports,
				options: {
					queryStringParameters: {
						range,
						reportFilterType
					}
				},
				path: `${baseReportPath}/devices/firmwareReport`
			}
		);
		yield put(reportActions.loadFirmwareReportSuccess(response));
	} catch (error) {
		console.error('An error occurred while loading the device groups.', error);
		yield put(reportActions.loadFirmwareReportError(error));
	}
}

function* loadRbsidReport(action: ReturnType<typeof reportActions.loadRbsidReport>) {
	const {
		payload: {range = 3}
	} = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp_reporting',
				awsExports,
				options: {
					queryStringParameters: {
						range
					}
				},
				path: `${baseReportPath}/devices/rbsidStatusReport`
			}
		);
		yield put(reportActions.loadRbsidReportSuccess(response));
	} catch (error) {
		console.error('An error occurred while loading the reports.', error);
		yield put(reportActions.loadRbsidReportError(error));
	}
}

function* loadAppReport(action: ReturnType<typeof reportActions.loadAppReport>) {
	const {
		payload: {range = 3}
	} = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp_reporting',
				awsExports,
				options: {
					queryStringParameters: {
						range
					}
				},
				path: `${baseReportPath}/devices/thirdPartyAppStatusReport`
			}
		);
		yield put(reportActions.loadAppReportSuccess(response));
	} catch (error) {
		console.error('An error occurred while loading the reports.', error);
		yield put(reportActions.loadAppReportError(error));
	}
}

function* watcher() {
	yield takeEvery(LIST_REPORTS, listReportsSaga);
	yield takeEvery(LOAD_REPORT_INFO, loadReportInfoSaga);
	yield takeEvery(CREATE_REPORT, createReportSaga);
	yield takeEvery(DELETE_REPORT, deleteReportSaga);
	yield takeEvery(UPDATE_REPORT, updateReportSaga);
	yield takeEvery(DOWNLOAD_REPORT, downloadReportSaga);
	yield takeEvery(FIRMWARE_REPORT, loadFirmwareReport);
	yield takeEvery(RBSID_REPORT, loadRbsidReport);
	yield takeEvery(APP_REPORT, loadAppReport);
}

export const reportSagas = () => {

	return {
		watcher
	};
};

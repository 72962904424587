import {createSelector} from 'reselect';
import {RootState} from '../reducers';

export const authSelector = (state: RootState) => {
	return state?.auth;
};

export const authUserSelector = createSelector(authSelector, (state) => {
	return state?.user;
});
export const authSelectors = {
	user: authUserSelector
};

import {routerMiddleware} from 'connected-react-router';
import {History, LocationState, createBrowserHistory} from 'history';
import {AnyAction, CombinedState, Reducer, applyMiddleware, compose, createStore as createStoreRedux} from 'redux';
import {EnhancerOptions, composeWithDevTools} from 'redux-devtools-extension';
import logger from 'redux-logger';
import {persistStore} from 'redux-persist';
import sagaMiddlewareFactory, {Saga} from 'redux-saga';

// [DF - 21/10/20]: Placing outside of createStore allows history to be shared properly between nested routes. Solves a bug where `push(AppRoutes.YOURNAME)` wasn't redirecting.
// Follows the example here: https://github.com/supasate/connected-react-router#step-2
const history = createBrowserHistory();

export const createStore = <HistoryState extends LocationState = any, RootInitialState = any, Sagas extends Saga = any>(
	createRootReducer: (history: History<HistoryState>) => Reducer<CombinedState<any>, AnyAction>,
	rootInitialState: RootInitialState,
	allSagas: Sagas,
	storeName?: string
) => {
	/**
	 * The redux-saga middleware
	 */
	const sagaMiddleware = sagaMiddlewareFactory();

	const rootReducer = createRootReducer(history as History<HistoryState>);
	const middlewares = [sagaMiddleware, routerMiddleware(history)];

	let composeEnhancers: typeof compose = compose;

	if (process.env.NODE_ENV === 'development') {
		middlewares.push(logger);

		const composeDevelopmentToolsOptions: EnhancerOptions | undefined = {
			name: storeName
		};
		composeEnhancers = composeWithDevTools(composeDevelopmentToolsOptions);
	}

	const enhancers = composeEnhancers(applyMiddleware(...middlewares));


	/**
	 * The redux store.
	 */
	const store = createStoreRedux(rootReducer, rootInitialState, enhancers);

	/**
	 * @see @{link https://www.cypress.io/blog/2018/11/14/testing-redux-store/}
	 */
	/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
	// @ts-ignore
	if (window.Cypress) {
		/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */
		// @ts-ignore
		window.store = store;
	}
	sagaMiddleware.run(allSagas as Saga<any[]>);

	const persistor = persistStore(store);

	return {
		history,
		persistor,
		rootReducer,
		store

	};
}

import * as AWS from 'aws-sdk';
import jwtDecode from 'jwt-decode';
import {Auth} from '@aws-amplify/auth';
import {Hub} from 'aws-amplify';


export const userGroups = {
	groups: [] as string[]

}


export const cognito = {
	client: {} as AWS.CognitoIdentityServiceProvider
};


let initClientPromise: Promise<any> | undefined;

export const initClient = async () => {
	if (!initClientPromise) {
		// eslint-disable-next-line  no-async-promise-executor
		initClientPromise = new Promise(async (resolve) => {
			let user: any;
			try {
				user = await Auth.currentAuthenticatedUser();

			}
			catch {
				user = await new Promise((resolve) => {
					Hub.listen('auth', async (data) => {
						const {payload} = data;

						if (payload.event === 'signIn') {
							resolve(await Auth.currentAuthenticatedUser())
						}

					})
				})


			}

			const accessToken = user.token;
			const accessTokenDecoded = jwtDecode(accessToken) as any;

			const adminGroup = accessTokenDecoded?.['cognito:roles']?.[0];
			const {
				accessKeyId,
				secretAccessKey,
				sessionToken
			} = await Auth.currentCredentials();
			/* eslint-disable-next-line import/namespace */
			const sts = new AWS.STS({
				credentials: new AWS.Credentials(accessKeyId, secretAccessKey, sessionToken)
			});


			const callerIdentity = await sts.getCallerIdentity({}).promise();

			let result: any;
			let credentials: AWS.Credentials | undefined;
			// Don't assume the role twice. Only assume if we're on cognito identity pool credential
			if (!callerIdentity.Arn!.includes('admin')) {
				try {
					const result = await sts.assumeRole({
						// RoleArn: 'arn:aws:iam::079439661102:role/crossacc-test',
						RoleArn: adminGroup,
						RoleSessionName: Date.now().toString()
					}).promise();

					credentials = new AWS.Credentials(result.Credentials!.AccessKeyId, result.Credentials!.SecretAccessKey, result.Credentials!.SessionToken)
					// eslint-disable-next-line import/namespace

				}
				catch {
					credentials = new AWS.Credentials(accessKeyId, secretAccessKey, sessionToken)
					// We still need to use the admin api for non admin users (LIstGroups to populate userAuthorisation)
				}

				cognito.client = new AWS.CognitoIdentityServiceProvider({credentials, region: 'eu-west-1'});


				resolve(result);
			}
		});
	}

	return await initClientPromise;

}

export const populateGroups = async () => {
	await initClient();

	const groups = await cognito.client!.listGroups({
		UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string
	}).promise();

	userGroups.groups = (groups?.Groups?.map(({GroupName}) => GroupName)?.filter((name) => !name?.startsWith('eu-west')) || []) as string[];

}


Hub.listen('auth', async (data) => {
	const {payload} = data;

	if (payload.event === 'signIn' && !userGroups.groups?.length) {
		await populateGroups();
	}

})

import { createTheme } from '@mui/material/styles';
const fontFamiliesWithFallbacks = '\'BT Curve\', \'Century Gothic\', Arial, sans-serif';
const fontFamiliesWithFallbacksHeadline = `'BT Curve Headline', ${fontFamiliesWithFallbacks}`;

const stylesFontFamiliesWithFallbacks = {
	fontFamily: fontFamiliesWithFallbacks
};
const stylesFontFamiliesWithFallbacksHeadline = {
	fontFamily: fontFamiliesWithFallbacksHeadline
};


export const theme = createTheme({
	components: {
		MuiSelect: {
			defaultProps: {
				variant: 'standard'
			}
		},
		MuiTextField: {
			defaultProps: {
				variant: 'standard'
			}
		}
	},
	palette: {
		primary: {
			contrastText: '#FFFFFF',
			dark: '#000000',
			light: '#FFFFFF',
			main: '#5514b4'
		},
		secondary: {
			contrastText: '#FFFFFF',
			dark: '#000000',
			light: '#FFFFFF',
			main: '#FFFFFF'
		},
		success: {
			main: '#28a745'
		}

	},
	typography: {
		body1: stylesFontFamiliesWithFallbacks,
		body2: stylesFontFamiliesWithFallbacks,
		fontFamily: fontFamiliesWithFallbacks,
		h1: stylesFontFamiliesWithFallbacksHeadline,
		h2: stylesFontFamiliesWithFallbacksHeadline,
		h3: stylesFontFamiliesWithFallbacksHeadline,
		h4: stylesFontFamiliesWithFallbacksHeadline,
		h5: stylesFontFamiliesWithFallbacksHeadline,
		h6: stylesFontFamiliesWithFallbacksHeadline
	}
});

import React from 'react';
import * as PropTypes from 'prop-types';
import { AppRouteNames, appRouteNames, appRoutes, navigationItems } from '../../App.router';
import IconHome from '@indigo-cloud/common-react/src/images/icons/BT_Icons_Home.svg';
import IconDashboard from '@indigo-cloud/common-react/src/images/icons/BT_Icons_Dashboard.svg';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import IconWifi from '@indigo-cloud/common-react/src/images/icons/BT_Icons_WiFi.svg';
import { DrawerNavigationList as DrawerNavigationListCommon, DrawerNavigationListLink as DrawerNavigationListLinkCommon } from '@indigo-cloud/common-react';

import { theme } from './../../App.theme'

import { PaletteOptions, SimplePaletteColorOptions, Theme, ThemeOptions, useTheme } from '@mui/material/styles';

interface DrawerNavigationListProperties {
	isOpen: boolean;
	onCloseDrawer: () => void;
}

export const drawerNavigationListDataSelectors = {
	drawerNavigationListContainer: 'DrawerNavigationList-drawer'
};


export const DrawerNavigationList: React.FC<DrawerNavigationListProperties> = ({ isOpen, onCloseDrawer }) => {

	// 'useLocation' required here to cause re-rneder on route change. Otherwise the highlighted route doesn't update on route change.
	// const location = useLocation();

	const applicationName = 'SUOTA';

	const theme = useTheme<Theme>();

	return (
		<DrawerNavigationListCommon >

			<DrawerNavigationListLinkCommon drawerNavigationOptions={{ applicationName, isOpen, isRootItem: true }} linkProps={{ onClick: onCloseDrawer }} routeKey={'Dashboard'} appRoutes={appRoutes} icon={IconWifi} themeColour={theme.palette.primary.main} />
			{navigationItems
				.filter(({shouldHideLink}) => !shouldHideLink)
				.map(({icon, name}) => <DrawerNavigationListLinkCommon key={name} drawerNavigationOptions={{applicationName, isOpen, isRootItem: false  }}  linkProps={{ onClick: onCloseDrawer }} appRoutes={appRoutes} routeKey={name} icon={icon!} themeColour={theme.palette.primary.main} />)
			}

		</DrawerNavigationListCommon>
	);
};

DrawerNavigationList.propTypes = {
	isOpen: PropTypes.bool.isRequired,
	onCloseDrawer: PropTypes.func.isRequired
};

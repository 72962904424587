import { combineReducers } from 'redux';
import { User } from '../../shared/types';
import { StateType } from 'typesafe-actions';

import { DisableUserRequest, ListUsersRequest, UpdateUserRequest, usersActions } from '../actions';
import {
	DISABLE_USER,
	DISABLE_USER_ERROR,
	DISABLE_USER_SUCCESS,
	LIST_USERS,
	LIST_USERS_ERROR,
	LIST_USERS_SUCCESS,
	UPDATE_USER,
	UPDATE_USER_ERROR,
	UPDATE_USER_SUCCESS
} from '../constants';

import { DeepReadonlyObject, OperationState, PaginatedResults, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';


export type UsersState = DeepReadonlyObject<{
	listUsersOperation: OperationState<User[], Error, ListUsersRequest>;
	disableUserOperation: OperationState<{ response: any;  }, Error, DisableUserRequest>;
	updateUserOperation: OperationState<{ response: any;  }, Error, UpdateUserRequest>;
}>;
export const initialUsersState: UsersState = {
	disableUserOperation: getStateInitial<{ response: any; }, Error, DisableUserRequest>(),
	listUsersOperation: getStateInitial<User[], Error, ListUsersRequest>(),
	updateUserOperation: getStateInitial<{ response: any; }, Error, UpdateUserRequest>()
};
export const usersReducers = combineReducers<UsersState, usersActions>({
	/**
	 * DISABLE USER
	 */
	disableUserOperation: (state = initialUsersState.disableUserOperation, action) => {
		switch (action.type) {
		case DISABLE_USER: {
			return getStateInProgress(action.payload);
		}
		case DISABLE_USER_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case DISABLE_USER_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * LIST USERS
	 */
	listUsersOperation: (state = initialUsersState.listUsersOperation, action) => {
		switch (action.type) {
		case LIST_USERS: {
			return getStateInProgress();
		}
		case LIST_USERS_SUCCESS: {
			const {result} = action.payload;

			return getStateSuccess(result);
		}
		// [09/11/2021]: We can't update the user directly after update success, because user has no id and no unique identifier. So we can't compare other fields for matching user as they could have been updated and won't matc the original item in state.
		// case UPDATE_USER_SUCCESS: {
		// 	const { user: userUpdated} = action.payload;

		// 	return {
		// 		...state,
		// 		current: state?.current?.map((user) => {
		// 			const { hubFw, hubModel, swvid, targetFw } = user;
		// 			const isMatch = hubFw === user.hubFw && hubModel === user.hubModel && swvid === user.swvid && targetFw === user.targetFw;

		// 			if (isMatch) {
		// 				return userUpdated;
		// 			}
		// 			return user;
		// 		})
		// 	};
		// }
		case LIST_USERS_ERROR: {
			const {error} = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	/**
	 * UPDATE USER
	 */
	updateUserOperation: (state = initialUsersState.updateUserOperation, action) => {
		switch (action.type) {
		case UPDATE_USER: {
			return getStateInProgress(action.payload);
		}
		case UPDATE_USER_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case UPDATE_USER_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type usersReducers = StateType<typeof usersReducers>;

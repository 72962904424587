import {CircularProgressProps} from '@mui/material';
import * as PropTypes from 'prop-types';
import {WeakValidationMap} from 'react';

export const muiCircularProgressPropertyTypes = {
	color: PropTypes.oneOf(['primary', 'secondary', 'inherit']),
	disableShrink: PropTypes.bool,
	size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
	thickness: PropTypes.number,
	type: PropTypes.string,
	value: PropTypes.number,
	variant: PropTypes.oneOf(['determinate', 'indeterminate', 'static'])
} as WeakValidationMap<CircularProgressProps>;

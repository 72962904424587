import { User } from '../../../shared/types';
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import {
	DISABLE_USER,
	DISABLE_USER_ERROR,
	DISABLE_USER_SUCCESS,
	LIST_USERS,
	LIST_USERS_ERROR,
	LIST_USERS_SUCCESS,
	UPDATE_USER,
	UPDATE_USER_ERROR,
	UPDATE_USER_SUCCESS

} from '../../constants';
import { FormikFormSubmitPromise, PaginatedResults, createAction } from '@indigo-cloud/common-react';


export type UpdateUserRequest = ReturnType<typeof usersActions['updateUser']>['payload'];
export type DisableUserRequest = ReturnType<typeof usersActions['disableUser']>['payload'];
export type ListUsersRequest = unknown;

export const usersActions = {


	 /**
	 * DISABLE USER
	 */
	disableUser: (model: User): PayloadAction<typeof DISABLE_USER, { model: typeof model }> => createAction(DISABLE_USER, { model }),
	disableUserError: (error: Error): PayloadAction<typeof DISABLE_USER_ERROR, { error: typeof error }> =>
		 createAction(DISABLE_USER_ERROR, { error }),
	disableUserSuccess: (response: any): PayloadAction<typeof DISABLE_USER_SUCCESS, { response: typeof response }> =>
		 createAction(DISABLE_USER_SUCCESS, { response }),

	/**
	 * LIST USERS
	 */
	listUsers: (): Action<typeof LIST_USERS> => createAction(LIST_USERS),
	listUsersError: (error: Error): PayloadAction<typeof LIST_USERS_ERROR, { error: typeof error }> =>
		createAction(LIST_USERS_ERROR, { error }),
	listUsersSuccess: (result: User[]): PayloadAction<typeof LIST_USERS_SUCCESS, { result: typeof result }> =>
		createAction(LIST_USERS_SUCCESS, { result }),

	/**
	 * UPDATE USER
	 */
	updateUser: (oldUser: User, newUser: User, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof UPDATE_USER, { formikPromise: typeof formikPromise; newUser: typeof newUser; oldUser: typeof oldUser;   }> => createAction(UPDATE_USER, { formikPromise, newUser, oldUser }),
	updateUserError: (error: Error): PayloadAction<typeof UPDATE_USER_ERROR, { error: typeof error }> =>
		 createAction(UPDATE_USER_ERROR, { error }),
	updateUserSuccess: (response: any): PayloadAction<typeof UPDATE_USER_SUCCESS, { response: typeof response }> =>
		 createAction(UPDATE_USER_SUCCESS, { response })
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
export type usersActions = ActionType<typeof usersActions>;

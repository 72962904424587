import { createStore } from '@indigo-cloud/common-react';

import { createRootReducer, rootInitialState } from './reducers/root.reducers';
import { allSagas } from './sagas/root.sagas';

export const {
	history,
	persistor,
	store,
	rootReducer
} = createStore(createRootReducer, rootInitialState, allSagas, 'Indigo Cloud - Portal UI');

import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { navigateToDeviceIdentifier } from '../../../shared/utils';
import { devicesActions } from '../../../store/actions';
import { devicesSelectors } from '../../../store/selectors';
import { DeviceSearchBox } from '../DeviceSearchBox';
import styles from './DeviceSearch.component.module.scss';
import { Device } from '../../../shared/types';

export const DeviceSearchDataSelectors = {
	container: 'DeviceSearch-container'
};

const LOCALSTORAGE_RECENTLY_VIEWED_KEY = 'DEVICE_RECENTLY_VIEWED';

export const DeviceSearch: React.FC = ({ }) => {
	const history = useHistory();
	const operationGetDevice = useSelector(devicesSelectors.findDeviceOperation);
	const isMounted = useRef(false);

	useEffect(() => { // onmount
		dispatch(devicesActions.findDeviceClear());
	}, []);

	const saveDeviceToLocalstorage = async (device: Device) => {
		const recentlyViewdDevices = (localStorage.getItem(LOCALSTORAGE_RECENTLY_VIEWED_KEY) || '');
		const myArray = recentlyViewdDevices ? recentlyViewdDevices.split('/') : [];
		if (myArray.length >=5 ) {
			myArray.pop();
		}
		if (!myArray.includes(device.serialNumber)) {
			myArray.unshift(device.serialNumber);
		}
		localStorage.setItem(LOCALSTORAGE_RECENTLY_VIEWED_KEY, myArray.join('/'));
	}

	useEffect(() => { // prevent this from running on mount
		if (isMounted.current) {
			if (operationGetDevice?.didSucceed && operationGetDevice?.result) {
				saveDeviceToLocalstorage(operationGetDevice.result.data);
				navigateToDeviceIdentifier(operationGetDevice.result.data, history);
			}
		} else {
			isMounted.current = true;
		}
	}, [operationGetDevice?.result]);

	const dispatch = useDispatch();

	const handleRecentlyVisited = useCallback((searchString: string) => {
		dispatch(devicesActions.findDevice(searchString));
	},
	[]
	);

	return (
		<div data-cy={DeviceSearchDataSelectors.container} id={styles.container} >
			<Container sx={{ margin: 'unset' }}>
				<Box>
					<Typography variant="h6" gutterBottom>
						Find a Device
					</Typography>
				</Box>
				<DeviceSearchBox />
				<Divider sx={{ mb: '1rem',  mt: '2rem' }} />
				<Box>
					<Typography variant="h6" gutterBottom sx={{ display: 'inline-block'}}>
						Recently viewed:
					</Typography>
					{localStorage.getItem(LOCALSTORAGE_RECENTLY_VIEWED_KEY)?.split('/').map(device=> (
						<Button key={device} variant="text"  onClick={() => handleRecentlyVisited(device)}>{device}</Button>
					))}
				</Box>
			</Container>
		</div>);
};

import {Selector, createSelector} from 'reselect';

import {OperationState, OperationStatus} from '../../types';
import {PaginatedResults} from '../../../types';

export const statusIsLoadingSelector = <Error_>(status: OperationStatus<Error_>) => status?.isLoading;

export const statusDidSucceedSelector = <Error_>(status: OperationStatus<Error_>) => status?.didSucceed;

export const statusHasErrorSelector = <Error_>(status: OperationStatus<Error_>) => !!status?.error;

export const statusErrorSelector = <Error_>(status: OperationStatus<Error_>) => status?.error;

export const statusRequestSelector = <Error_, Request = unknown>(status: OperationStatus<Error_, Request>) => status?.request;

export const isLoadingSelector = <Current, Error_>(state: OperationState<Current, Error_>) =>
	statusIsLoadingSelector(state?.status);

export const didSucceedSelector = <Current, Error_>(state: OperationState<Current, Error_>) =>
	statusDidSucceedSelector(state?.status);

export const errorSelector = <Current, Error_>(state: OperationState<Current, Error_>) =>
	statusErrorSelector(state?.status);

export const requestSelector = <Current, Error_>(state: OperationState<Current, Error_>) =>
	statusRequestSelector(state?.status);

export const hasErrorSelector = <Current, Error_>(state: OperationState<Current, Error_>) =>
	statusHasErrorSelector(state?.status);

export const hasInitSelector = createSelector<any, boolean, boolean, any, boolean>(
	isLoadingSelector,
	didSucceedSelector,
	hasErrorSelector,
	(isLoading, didSucceed, hasError) => isLoading || didSucceed || hasError
);

export type Operation<Current, Error_ = Error, Request = any> = {
	didSucceed: boolean;
	error?: Error_;
	hasError: boolean;
	hasInit: boolean;
	isLoading: boolean;
	result?: Current;
	request?: Request
};

export enum OperationStatusType {
	success = 'success',
	error = 'error',
}

export type OptionalOperation<Current, Error_> = Operation<Current, Error_> | null | undefined;

export type OptionalOperationState<Current, Error_> = OperationState<Current, Error_> | null | undefined;

export const operationSelector = <Current, Error_ = Error>(
	state: OptionalOperationState<Current, Error_>
): OptionalOperation<Current, Error_> => {
	if (!state) {
		return state;
	}
	return {
		didSucceed: didSucceedSelector(state),
		error: errorSelector(state),
		hasError: hasErrorSelector(state),
		hasInit: hasInitSelector(state),
		isLoading: isLoadingSelector(state),
		request: requestSelector(state),
		result: state?.current
	};
};

export const createOperationResultSelector = <RootState, Current, Error_>(
	operationSelectors: Selector<RootState, OptionalOperation<Current, Error_>>
) => createSelector(operationSelectors, (state) => state?.result);

export const createOperationErrorSelector = <RootState, Current, Error_>(
	operationSelectors: Selector<RootState, OptionalOperation<Current, Error_>>
) => createSelector(operationSelectors, (state) => state?.error);

export const createOperationErrorParsedSelector = <RootState, Error_>(
	operationErrorSelector: Selector<RootState, Error_ | undefined>
) =>
		createSelector(operationErrorSelector, (error: any) => {
			return error?.message || error?.response?.data?.message;
		});

export const createPagedResultDataSelector = <RootState, Current, PaginatedResult extends PaginatedResults<Current> | undefined>(
	operationResultSelector: Selector<RootState, PaginatedResult>
) => createSelector(operationResultSelector, (state) => state?.data);

export const createPagedResultPaginationSelector = <RootState, Current, PaginatedResult extends PaginatedResults<Current>>(
	operationResultSelector: Selector<RootState, PaginatedResult>
) => createSelector(operationResultSelector, (state) => state?.pagination);

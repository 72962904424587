import {Role} from '../../helpers/role';

export const isAuthorised = (roles: Role[] | undefined, userAuthorisationGroups: {[key in Role]: boolean}) => {
	let isAuthorisedFlag = false;
	if (!roles) return true;
	roles.forEach((role) => {
		if (userAuthorisationGroups[role as keyof typeof userAuthorisationGroups]) {
			isAuthorisedFlag = true;
		}
	});
	return isAuthorisedFlag;
}
import { PaginatedTable, PaginatedTableProperties, Role, isAuthorised, useAuthorisation } from '@indigo-cloud/common-react';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Checkbox } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { appRoutes } from '../../../App.router';
import { CSVDownload } from 'react-csv';
import { useDispatch, useSelector } from 'react-redux';
import { ModalConfirmation } from '@indigo-cloud/common-react';
import { deviceGroupsActions, devicesActions } from '../../../store/actions'
import { theme } from '../../../App.theme';
import { deviceGroupsSelectors } from '../../../store/selectors';
import { Thing } from '../../types';
import styles from './ThingsTable.component.module.scss';
import Box from '@mui/system/Box';
import { navigateToDeviceIdentifierByThingName } from '../../utils';
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';

interface ThingsTableProperties extends Pick<PaginatedTableProperties<Thing>, 'onRowSelectionChanged'> {
	name: string
	isLoading?: boolean;
	inherittedFilters?: Record<string, unknown>;
	customStyle?: Record<string, unknown>;
	groupType?: string
}
export const thingsTableDataSelectors = {
	actionDeleteIconButton: 'ThingsTable-actionDeleteIconButton',
	container: 'ThingsTable-container'
};

export const ThingsTable: React.FC<ThingsTableProperties> = ({ inherittedFilters, name, isLoading, customStyle = {}, groupType, ...tableProperties }) => {
	
	const [pageSize, setPageSize] = React.useState(10);
	const [pageIndex, setPageIndex] = React.useState(0);
	const pageIndexPrefix = 'page';

	const deviceGroupDevicesPaged = useSelector(deviceGroupsSelectors.listDeviceGroupDevicesOperationPage(name, pageIndex, pageIndexPrefix));
	const [deviceGroupDevicesAll, deviceGroupDevicesLastPage] = useSelector(deviceGroupsSelectors.listDeviceGroupDevicesOperationAll(name));
	
	
	const deviceGroupDevicesOperation = useSelector(deviceGroupsSelectors.listDeviceGroupDevicesOperation(name));

	

	const userAuthorisationGroups = useAuthorisation();


	const [deleteAction, setDeleteAction] = React.useState(false);
	const [thingToDelete, setThingToDelete] = React.useState<string[]>([]);
	const [thingsToDelete, setThingsToDelete] = React.useState<string[]>([]);

	const [allChecked, setAllChecked] = React.useState<boolean>(false);

	const [initiateDownload, setInitiateDownload] = useState(false);
	const [collectingtAllData, setCollectingtAllData] = useState(false);

	const DeviceRemoveOperation = useSelector(deviceGroupsSelectors.triggerDeviceGroupDevicesRemoveOperation(name));
	const didRemoveOperationSucceed = DeviceRemoveOperation?.didSucceed === true;
	const isLoadingDelete = DeviceRemoveOperation?.isLoading;

	const reference = useRef<any>(null);
	const dispatch = useDispatch();

	const hasCursor = useMemo(() => {
		return !!deviceGroupDevicesPaged?.pagination?.cursor;
	}, [deviceGroupDevicesPaged?.pagination]);

	const history = useHistory();

	useEffect(() => {
		dispatch(deviceGroupsActions.loadDeviceGroupDevices({
			name
			
		}));
		return () => {
			dispatch(deviceGroupsActions.loadDeviceGroupDevicesClear(name));
		}
	}, []);

	useEffect(() => {
		if (didRemoveOperationSucceed === true) {
			dispatch(deviceGroupsActions.loadDeviceGroupDevices({
				name
			}));
			dispatch(deviceGroupsActions.loadDeviceGroupDevicesClear(name));
		}
	}, [didRemoveOperationSucceed]);

	useEffect(() => { // onmount
		dispatch(devicesActions.findDeviceClear());
	}, []);

	const onPressAction = (action: 'delete', thing: string[]) => {
		switch (action) {
		case 'delete':
			setThingToDelete(thing);
			// confirmation modal gets opened once delete action is true
			setDeleteAction(true);
			break;
		}
	}

	const onPageChanged = (page: number) => {
		setPageIndex(page);
		// @ts-ignore
		const hasRequestedPageInState = !!deviceGroupDevicesOperation?.result?.[`${pageIndexPrefix}_${page.toString()}`];

		if (!hasRequestedPageInState) {
			dispatch(deviceGroupsActions.loadDeviceGroupDevices({name, cursor: deviceGroupDevicesPaged?.pagination.next, pageIndexPrefix, pageIndex: page}));
		}
	};

	const deleteThingFromGroup = async () => {
		try {
			setDeleteAction(false);
			await new Promise((resolve, reject) => {
				if (thingToDelete.length > 0) {
					dispatch(deviceGroupsActions.triggerDeviceGroupRemoveDevices(name, { 'thingNames': thingToDelete }, { reject, resolve }));
				}
			});
		}
		catch (error) {
			console.log('An error occurred while deleting the thing from the group', error);
		}
		finally {
			console.log('dispatched action to delete the thing from the group');
		}
		//resetting once delete action completed, that closes the confirmation modal as well
		setDeleteAction(false);
	}

	const generateDeleteConfirmModal = () => (
		<ModalConfirmation
			action={`delete ${thingToDelete.length > 1 ? `${thingToDelete.length} selected`: `${thingToDelete.join(',')}`}`}
			title='Confirm Deletion'
			isOpen={deleteAction}
			onClickOk={deleteThingFromGroup}
			onClickClose={() => setDeleteAction(false)}
		/>
	);

	const onRowClick = useCallback((row: Thing) => {
		navigateToDeviceIdentifierByThingName(row.thingName, history);
	}, [name, history]);

	/**
	 * handle multiple selection of the things
	 */
	const handleCheck = (event: React.ChangeEvent<HTMLInputElement>, checkType: string) => {
		// event.stopPropagation();
		switch(checkType) {
		case 'SINGLE':
			if (event.target.checked) {
				// add the thing to thingsToDelete scope
				updateThingsToDelete('ADD', event.target.value);
			} else {
				// remove the thing from thingsToDelete scope
				updateThingsToDelete('REMOVE', event.target.value);
			}
			break;
		case 'ALL':
			if (event.target.checked) {
				setThingsToDelete(getAllThingsNames());
				setAllChecked(true);
			} else {
				setThingsToDelete([]);
				setAllChecked(false);
			}
			break;
		default:
		}
	};

	const updateThingsToDelete = (updateType: string, thingName: string) => {
		switch (updateType) {
		case 'ADD':
			if (!thingsToDelete.includes(thingName) ) {
				setThingsToDelete([...thingsToDelete, thingName]);
			}
			break;
		case 'REMOVE':
			if (thingsToDelete.length > 0 && thingsToDelete.includes(thingName) ) {
				setThingsToDelete(thingsToDelete.filter(item => item !== thingName));
			}
			break;
		default:
		}
	};

	useEffect(() => {
		// Enables user to download data more than once
		if (initiateDownload) {
		  setInitiateDownload(false)
		}
	}, [initiateDownload]);

	useEffect(() => {
		if(!deviceGroupDevicesOperation?.isLoading && collectingtAllData){
			setInitiateDownload(true);
			setCollectingtAllData(false);
		}
	}, [deviceGroupDevicesOperation?.isLoading]);

	const thnigsCSVDownload = () => {
		if(deviceGroupDevicesLastPage.pagination.cursor){
			setCollectingtAllData(true);
			dispatch(deviceGroupsActions.loadDeviceGroupDevices({
				name, cursor:deviceGroupDevicesLastPage.pagination.next,
				pageIndexPrefix,
				pageIndex: deviceGroupDevicesLastPage.pageIndex + 1, 
				collectAllData: true
			}));
		} else {
			setInitiateDownload(true);
		}
	}

	// @ts-ignore
	const getAllThingsNames = () => deviceGroupDevicesAll.map((device: Thing) => device.thingName);

	const generateView = () => (
		<div data-cy={thingsTableDataSelectors.container} id={styles.container} >
			{initiateDownload && (
				<CSVDownload
					data={deviceGroupDevicesAll}
					filename={`Devices in ${name}`}
					target="_self"
				/>
			)}
			{(deviceGroupDevicesAll.length > 0 ) && <div className={styles.downloadIcon}>
				{!collectingtAllData ?
					<Button
						variant="contained"
						startIcon={<FileDownloadOutlined />}
						onClick={thnigsCSVDownload}
						aria-label="Export as CSV"
						title="Export as CSV"
					>
						Download Device(s)
					</Button> : 
					<CircularProgress data-cy='' color='primary' size={'2rem'} />}
			</div>}

			{ groupType !== 'DYNAMIC' && isAuthorised([Role.DeviceGroup, Role.Admin], userAuthorisationGroups) && 
				<IconButton className={styles.bulkRemoveIcon} color="primary" aria-label="Bulk Remove Devices By CSV" component="label" onClick={() => {
					history.push({
						pathname: `${appRoutes.DeviceGroupEditDevices.replace(':id', encodeURIComponent(name)).replace(':action', encodeURIComponent('removeDevices'))}`
					})
				}}>
					<Tooltip title="Bulk remove things by csv">
						<RestoreFromTrashIcon style={{ color: '#000000', opacity: 0.5 }} />
					</Tooltip>
				</IconButton>
			}
			<PaginatedTable
				ref={reference}
				columns={[
					...isAuthorised([Role.DeviceGroup, Role.Admin], userAuthorisationGroups) ? [{
						Header: groupType !== 'DYNAMIC' && (<Checkbox
							key='select-all'
							checked={allChecked}
							indeterminate={!allChecked}
							onClick={(event) => event.stopPropagation()}
							onChange={(event) => { handleCheck(event, 'ALL')}}/>),
						Cell: ({row}: any) => groupType !== 'DYNAMIC' && (
							<Checkbox
								key={row?.id}
								checked={thingsToDelete.includes(row?.values?.thingName)}
								onClick={(event) => event.stopPropagation()}
								onChange={(event) => handleCheck(event, 'SINGLE')}
								value={row?.values?.thingName}/>
						),
						accessor: 'select',
						disableFilters: true
					}]:[],
					{
						Header: (deviceGroupDevicesPaged?.pagination?.total !== undefined ) ? `Thing Name (${deviceGroupDevicesPaged?.pagination?.total})` : '',
						accessor: 'thingName',
						disableFilters: true
					},
					...isAuthorised([Role.DeviceGroup, Role.Admin], userAuthorisationGroups) ? [{
						Cell: ({row}: any) => groupType !== 'DYNAMIC' && (
							<Box display="flex" justifyContent="flex-end">
								<Tooltip title='Delete Thing'>
									<IconButton
										onClick={(event) => {
											onPressAction('delete', [row?.values?.thingName]);
											event.stopPropagation();
										}}
										data-cy={thingsTableDataSelectors.actionDeleteIconButton}
										size="large">
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							</Box>
						),
						Header: groupType !== 'DYNAMIC' && (<Box display="flex" justifyContent="flex-end">
							<Tooltip title='Delete Thing'>
								<IconButton
									onClick={(event) => {
										onPressAction('delete', thingsToDelete);
										event.stopPropagation();
									}}
									data-cy={thingsTableDataSelectors.actionDeleteIconButton}
									disabled={thingsToDelete.length === 0}
									size="large">
									<DeleteIcon />
								</IconButton>
							</Tooltip>
						</Box>),
						accessor: 'paramValue' as any,
						disableFilters: true
					}] : []
				]}
				data={deviceGroupDevicesPaged?.data || []}
				onPageChanged={onPageChanged}
				/* eslint-disable-next-line @typescript-eslint/no-empty-function  */
				onFiltersChanged={() => {}}
				// Seems to be bug with pageIndex in react-table where it doesn't always update on nextPage(). So use redux pagine index instead.
				pageIndex={pageIndex}
				hasCursor={hasCursor}
				loading={!!deviceGroupDevicesOperation?.isLoading}
				theme={theme}
				customStyle={{bodyAlign: 'center', headerAlign: 'center', ...customStyle }}
				navigateToRowDetails={onRowClick}
				{...tableProperties}
			/>
			{deleteAction ? generateDeleteConfirmModal() : ''}
		</div>);

	return (
		<>
			{(isLoading || isLoadingDelete || deviceGroupDevicesOperation?.isLoading) && !collectingtAllData ? <div><CircularProgress data-cy='' color='primary' size={'2rem'} /></div> : generateView()}
		</>
	);
};
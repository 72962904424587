export let awsExports: any;
export let api: any;

// TODO: Is there any value in moving this to common-react
export const setAwsExports = (awsExportsObject: any) => {
	awsExports = awsExportsObject;
}

export const setApi = (apiObject: any) => {
	api = apiObject;
}

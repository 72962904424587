import {Action, PayloadAction} from 'typesafe-actions';

/**
 * Type-safe redux action creators that accept just an action type.
 */
export function createAction<Type extends string>(type: Type): Action<Type>;

/**
 * Type-safe redux action creators that accept just an action type and a payload.
 */
export function createAction<Type extends string, Payload>(type: Type, payload: Payload): PayloadAction<Type, Payload>;
export function createAction<Type extends string, Payload>(
	type: Type,
	payload?: Payload
): Action<Type> | PayloadAction<Type, Payload> {
	return payload ? {payload, type} : {type};
}

import { User } from '../types';
import { History } from 'history';
import { appRoutes } from '../../components';

export const getUserIdentifier = ({ id }: User) => id;

export const getUserIdentifierUrl = (user: User) => {
	const identifier = getUserIdentifier(user);
	return appRoutes.UserInfo.replace(':userId', encodeURIComponent(identifier));
};

export const navigateToUserIdentifier = (user: User, history: History<unknown>) => {
	const identifierUrl = getUserIdentifierUrl(user);
	history.push(identifierUrl);
}

const awsmobile = {

	aws_appsync_authenticationType: 'AWS_IAM',

	aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT_USP,
	aws_appsync_region: process.env.REACT_APP_REGION,

	aws_cloud_logic_custom: [
		{
			endpoint: process.env.REACT_APP_API_ENDPOINT_SUOTA,
			name: 'suota',
			region: process.env.REACT_APP_REGION
		},
		{
			endpoint: process.env.REACT_APP_API_ENDPOINT_USP,
			name: 'usp',
			region: process.env.REACT_APP_REGION
		},
		{
			endpoint: process.env.REACT_APP_API_ENDPOINT_USP_REPORTING,
			name: 'usp_reporting',
			region: process.env.REACT_APP_REGION
		},
		{
			endpoint: process.env.REACT_APP_API_ENDPOINT_ROM,
			name: 'rom',
			region: process.env.REACT_APP_REGION
		}
	],

	aws_cognito_identity_pool_id: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
	aws_cognito_region: process.env.REACT_APP_REGION,
	aws_project_region: process.env.REACT_APP_REGION,

	aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,

	aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,

	oauth: {
		domain: process.env.REACT_APP_COGNITO_USER_POOL_OAUTH_DOMAIN
	}
};

export default awsmobile;

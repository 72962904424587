import { useDispatch, useSelector } from 'react-redux';
import { devicesSelectors } from '../../../store/selectors';
import React, { useEffect } from 'react';
import styles from './DeviceInfo.component.module.scss';
import { devicesActions } from '../../../store/actions';
import { Details } from '@indigo-cloud/common-react';


type DeviceInfoProperties = {
	agentEndpointId: string;
};

export const deviceInfoDataSelectors = {
	container: 'DeviceInfo-container'
};

export const DeviceInfo: React.FC<DeviceInfoProperties> = ({ agentEndpointId }) => {
	const getDeviceInfoOperation = useSelector(devicesSelectors.getDeviceInfoOperation(agentEndpointId));

	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(devicesActions.loadDeviceInfo(agentEndpointId));
	}, []);

	return (<div data-cy={deviceInfoDataSelectors.container} className={styles.container} >

		<Details title={'Device Information'} object={getDeviceInfoOperation?.result || {}} isLoading={ getDeviceInfoOperation?.isLoading} />
	</div>);
};

import {API} from '@aws-amplify/api';
import {AxiosError} from 'axios';

export const request = (
	{
		method = 'get',
		path,
		awsExports,
		options,
		apiName = 'suota'
	}: {
		method?: 'get' | 'post' | 'put' | 'del' | 'head',
		path: string,
		awsExports: {aws_cloud_logic_custom: {name: string;}[]},
		options?: any,
		apiName?: 'suota' | 'usp' | 'usp_reporting' | 'rom'
	}
) => {
	const token = JSON.parse(localStorage.getItem('federatedCredentials')!)?.token;
	const getApiName = () => {
		const index = {
			rom: 3,
			suota: 0,
			usp: 1,
			usp_reporting: 2
		}[apiName];

		if (index !== undefined) {
			return awsExports.aws_cloud_logic_custom[index].name;
		}
		// Handle invalid apiName value
		throw new Error(`Invalid apiName: ${apiName}`);
	};
	return API[method](getApiName(), path, {
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json'
		},
		...options
	});
}

export const getErrorFromApiError = (error: Error | AxiosError) => {
	const errorAxios = (error as AxiosError);
	return errorAxios.isAxiosError ? errorAxios?.response : error;
}

/**
 * LIST_RELEASES
 */
export const LIST_RELEASES = 'RELEASES.LIST_RELEASES';
export const LIST_RELEASES_ERROR = 'RELEASES.LIST_RELEASES_ERROR';
export const LIST_RELEASES_SUCCESS = 'RELEASES.LIST_RELEASES_SUCCESS';

/**
  * CREATE_RELEASE
  */
export const CREATE_RELEASE = 'RELEASES.CREATE_RELEASE';
export const CREATE_RELEASE_ERROR = 'RELEASES.CREATE_RELEASE_ERROR';
export const CREATE_RELEASE_SUCCESS = 'RELEASES.CREATE_RELEASE_SUCCESS';

/**
  * UPDATE_RELEASE
  */
export const UPDATE_RELEASE = 'RELEASES.UPDATE_RELEASE';
export const UPDATE_RELEASE_ERROR = 'RELEASES.UPDATE_RELEASE_ERROR';
export const UPDATE_RELEASE_SUCCESS = 'RELEASES.UPDATE_RELEASE_SUCCESS';

/**
  * DELETE_RELEASE
  */
export const DELETE_RELEASE = 'RELEASES.DELETE_RELEASE';
export const DELETE_RELEASE_ERROR = 'RELEASES.DELETE_RELEASE_ERROR';
export const DELETE_RELEASE_SUCCESS = 'RELEASES.DELETE_RELEASE_SUCCESS';

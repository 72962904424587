import Typography from '@mui/material/Typography';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {Modal} from '../Modal';


export const unauthorisedModal = {
	setIsOpenUnauthorised: (() => {
		return {};
	}) as Dispatch<SetStateAction<boolean>>
};

export const UnauthorisedModal = ({
}: {
}) => {
	const [isOpenUnauthorised, setIsOpenUnauthorised] = useState(false);

	useEffect(() => {
		unauthorisedModal.setIsOpenUnauthorised = setIsOpenUnauthorised;
	}, [setIsOpenUnauthorised]);

	const onClose = () => {
		setIsOpenUnauthorised(false)
	};

	return (
		<Modal
			isOpen={isOpenUnauthorised}
			title={'Unauthorised'}
			onClickOk={onClose}
			onClickBack={onClose}
			hide={{scroll: true}}
		>
			<Typography color="primary" >You are not authorised to perform the operation.</Typography>
		</Modal>
	);
}
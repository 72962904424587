/**
 * LOGIN
 */
export const LOGIN = 'AUTH.LOGIN';
export const LOGIN_SUCCESS = 'AUTH.LOGIN_SUCCESS';
export const LOGIN_ERROR = 'AUTH.LOGIN_ERROR';

/**
 * LOGOUT
 */
export const LOGOUT = 'AUTH.LOGOUT';

/**
 * LOAD_AUTH_USER
 */
export const LOAD_AUTH_USER = 'AUTH.LOAD_AUTH_USER';
export const LOAD_AUTH_USER_SUCCESS = 'AUTH.LOAD_AUTH_USER_SUCCESS';
export const LOAD_AUTH_USER_ERROR = 'AUTH.LOAD_AUTH_USER_ERROR';
export const LOAD_AUTH_USER_CLEAR = 'AUTH.LOAD_AUTH_USER_CLEAR';

import {WeakValidationMap} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';

export const reactRouterPropertyTypes = {
	history: ReactRouterPropTypes.history.isRequired,
	location: ReactRouterPropTypes.location.isRequired,
	match: ReactRouterPropTypes.match.isRequired,
	route: ReactRouterPropTypes.route
	// PropTypes return Validator objects instead of the direct prop type, so requires cast to unknown
} as WeakValidationMap<RouteComponentProps>;

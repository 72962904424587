import { Device, Report } from '../types';
import { History } from 'history';
import { appRoutes } from '../../components';

export const getReportIdentifier = ({ id }: Report) => id;

export const getReportIdentifierUrl = (report: Report) => {
	const identifier = getReportIdentifier(report);
	return appRoutes.ReportsUpdate.replace(':reportId', encodeURIComponent(identifier));
};

export const navigateToReportIdentifier = (report: Report, history: History<unknown>) => {
	const identifierUrl = getReportIdentifierUrl(report);
	history.push(identifierUrl);
}


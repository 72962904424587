import {all, call ,put, takeEvery} from '@redux-saga/core/effects';

import {authActions, rulesActions} from '../actions';
import { CREATE_RULE, DELETE_RULE, LIST_RULES, UPDATE_RULE } from '../constants';
import { push } from 'connected-react-router';
import { appRoutes } from '../../App.router';
import { processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { awsExports } from '../../shared';
import { delay } from 'bluebird';
import { Auth } from '@aws-amplify/auth';

const baseRulesPath = '/v1/suota/rule';

function* loadRules() {
	try {

		const response = yield call(
			request,
			{
				awsExports,
				path: baseRulesPath
			}
		);

		yield put(rulesActions.listRulesSuccess(response));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(rulesActions.listRulesError(error));
	}
}

function* createRule(action: ReturnType<typeof rulesActions['createRule']>) {
	const {
		payload: {model: rule, formikPromise, createAnother}
	} = action;
	try {

		const response = yield call(
			request,
			{
				awsExports,
				method: 'post',
				options: {
					body: rule
				},
				path: baseRulesPath

			}
		);

		yield put(rulesActions.createRuleSuccess(response));
		formikPromise.resolve(response);

		yield put(rulesActions.listRules());

		if (!createAnother) {
			yield put(push(appRoutes.Release));
		}

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while creating the rule', error);
		yield put(rulesActions.createRuleError(error));
		formikPromise.reject(error);
	}
}

function* deleteRule(action: ReturnType<typeof rulesActions['deleteRule']>) {
	const {
		payload: {model: rule}
	} = action;
	try {
		const response =  yield all(rule.map(x => call(request, {
			awsExports,
			method: 'del',
			path: encodeURI(`${baseRulesPath}/${x.hubFw}/${x.hubModel}/${x.swvid}${x.discModel ? `/${x.discModel}` : ''}`)
		})));

		yield put(rulesActions.deleteRuleSuccess(response));
		yield put(rulesActions.listRules());
		yield put(push(appRoutes.Release));

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the rule', error, rule);
		yield put(rulesActions.deleteRuleError(error));
	}
}

function* updateRule(action: ReturnType<typeof rulesActions['updateRule']>) {
	const {
		payload: {model: rule, formikPromise}
	} = action;
	try {

		console.log(`Started updating the Rule with target firmware '${rule.targetFw}'.`);
		const response = yield call(
			request,
			{
				awsExports,
				method: 'put',
				options: {
					body: rule
				},
				path: baseRulesPath
			}
		);

		yield put(rulesActions.updateRuleSuccess(response));
		yield put(rulesActions.listRules());
		formikPromise.resolve(response);

		yield put(push(appRoutes.Release));

		console.log(`Successfully completed updating the Rule with target firmware '${rule.targetFw}'.`);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while updating the rule with target firmware '${rule.targetFw}'.`, error);
		yield put(rulesActions.updateRuleError(error));
		formikPromise.reject(error);
	}
}

export const rulesSagas = () => {

	function* watcher() {
		yield takeEvery(LIST_RULES, loadRules);
		yield takeEvery(CREATE_RULE, createRule);
		yield takeEvery(DELETE_RULE, deleteRule);
		yield takeEvery(UPDATE_RULE, updateRule);
	}

	return {
		loadRules,
		watcher
	};
};

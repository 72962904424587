import {call, put, takeEvery} from '@redux-saga/core/effects';

import {authActions, releaseActions} from '../actions';
import { CREATE_RELEASE, DELETE_RELEASE, LIST_RELEASES, UPDATE_RELEASE } from '../constants';
import { push } from 'connected-react-router';
import { appRoutes } from '../../App.router';
import { processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { awsExports } from '../../shared';

const baseReleasePath = '/v1/suota/software';

function* loadReleases() {
	try {
		const response = yield call(
			request,
			{
				awsExports,
				path: baseReleasePath
			}
		);
		yield put(releaseActions.listReleasesSuccess(response));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(releaseActions.listReleasesError(error));
	}
}

function* createRelease(action: ReturnType<typeof releaseActions['createRelease']>) {
	const {
		payload: {model: release, formikPromise, createAnother}
	} = action;

	try {
		const response = yield call(
			request,
			{
				awsExports,
				method: 'post',
				options: {
					body: release
				},
				path: baseReleasePath

			}
		);

		yield put(releaseActions.createReleaseSuccess(response));
		formikPromise.resolve(response);
		yield put(releaseActions.listReleases());

		if (!createAnother) {
			yield put(push(appRoutes.Release));
		}

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while creating the release', error);
		yield put(releaseActions.createReleaseError(error));
		formikPromise.reject(error);
	}
}

function* deleteRelease(action: ReturnType<typeof releaseActions['deleteRelease']>) {
	const {
		payload: {model: release}
	} = action;
	try {
		const response = yield call(
			request,
			{
				awsExports,
				method: 'del',
				path: `${baseReleasePath}/${release.targetFw}`
			}
		);


		yield put(releaseActions.deleteReleaseSuccess(response));
		yield put(releaseActions.listReleases());
		yield put(push(appRoutes.Release));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the release', error, release);
		yield put(releaseActions.deleteReleaseError(error));
	}
}


function* updateRelease(action: ReturnType<typeof releaseActions['updateRelease']>) {
	const {
		payload: {model: release, formikPromise}
	} = action;
	try {
		console.log(`Started updating the Release with target firmware '${release.targetFw}'.`);
		const response = yield call(
			request,
			{
				awsExports,
				method: 'put',
				options: {
					body: release
				},
				path: baseReleasePath
			}
		);

		yield put(releaseActions.updateReleaseSuccess(response));
		yield put(releaseActions.listReleases());
		formikPromise.resolve(response);

		yield put(push(appRoutes.Release));

		console.log(`Successfully completed updating the Release with target firmware '${release.targetFw}'.`);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while updating the release with target firmware '${release.targetFw}'.`, error);
		yield put(releaseActions.updateReleaseError(error));
		formikPromise.reject(error);
	}
}

export const releasesSagas = () => {
	function* watcher() {
		yield takeEvery(LIST_RELEASES, loadReleases);
		yield takeEvery(CREATE_RELEASE, createRelease);
		yield takeEvery(DELETE_RELEASE, deleteRelease);
		yield takeEvery(UPDATE_RELEASE, updateRelease);
	}

	return {
		loadReleases,
		watcher
	};
};

import {useEffect, useRef, useState} from 'react';

export const useToken = (): [string | undefined, () => void] => {
	const [updateToken, setUpdateToken] = useState(false);
	const tokenReference = useRef<string | undefined>();

	useEffect(() => {
		const federatedCredentials = localStorage.getItem('federatedCredentials');
		if (federatedCredentials) {
			const parsedCredentials = JSON.parse(federatedCredentials);
			const extractedToken = parsedCredentials.token;
			tokenReference.current = extractedToken;
		}
		setUpdateToken(false);
	}, [updateToken]);

	const retriveToken = () => {
		setUpdateToken(true);
	};

	return [tokenReference.current, retriveToken];
};

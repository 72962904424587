import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { SetParameterValuesProperties } from '../../../SetParameterValues';
import { devicesSelectors } from '../../../../../store/selectors';


interface SetParameterValuesOperationSnackbarProperties extends Pick<SetParameterValuesProperties, 'agentEndpointId'> {

}

export const setParameterValuesOperationSnackbarDataSelectors = {
	setParameterValuesOperationSnackbarAlertError: 'SetParameterValuesOperationSnackbar-snackbarAlertError',
	setParameterValuesOperationSnackbarAlertSuccess: 'SetParameterValuesOperationSnackbar-snackbarAlertSuccess',
	setParameterValuesOperationSnackbarError: 'SetParameterValuesOperationSnackbar-snackbarError',
	setParameterValuesOperationSnackbarForm: 'SetParameterValuesOperationSnackbar-form',
	setParameterValuesOperationSnackbarModal: 'SetParameterValuesOperationSnackbar-modal',
	setParameterValuesOperationSnackbarSuccess: 'SetParameterValuesOperationSnackbar-snackbarSuccess'
};

export const SetParameterValuesOperationSnackbar: React.FC<SetParameterValuesOperationSnackbarProperties> = ({ agentEndpointId }) => {

	const setDeviceParameterValuesOperation = useSelector(devicesSelectors.setDeviceParameterValuesOperation(agentEndpointId)) as any;
	const setDeviceParameterValuesOperationResults = useSelector(devicesSelectors.setDeviceParameterValuesOperationResults(agentEndpointId)) as any;
	const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean | 'success' | 'error'>(false);

	const operationError = typeof setDeviceParameterValuesOperation?.error === 'string' ? setDeviceParameterValuesOperation.error : setDeviceParameterValuesOperation?.error?.message;
	const operationResponse = setDeviceParameterValuesOperationResults;

	const didOperationSucceed = setDeviceParameterValuesOperation?.didSucceed === true;
	const didOperationError = setDeviceParameterValuesOperation?.hasError === true;

	const operationRequestParameterPaths = setDeviceParameterValuesOperation?.request?.paramPaths;

	const shouldOpenSnackbar = didOperationSucceed || didOperationError;

	useEffect(() => {
		if (shouldOpenSnackbar) {
			setShouldShowSnackbar(didOperationSucceed ? 'success' : 'error');
		}
	}, [
		shouldOpenSnackbar
	]);

	return (<>
		<Snackbar open={shouldShowSnackbar === 'error'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={setParameterValuesOperationSnackbarDataSelectors.setParameterValuesOperationSnackbarError}>
			<Alert variant="filled" severity="error" onClose={() => setShouldShowSnackbar(false)} data-cy={setParameterValuesOperationSnackbarDataSelectors.setParameterValuesOperationSnackbarAlertError}>
				An error occurred while setting {operationRequestParameterPaths?.length} parameter value{operationRequestParameterPaths?.length === 1 ? '' : 's'}: {operationError}
			</Alert>
		</Snackbar>
		<Snackbar open={shouldShowSnackbar === 'success'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={setParameterValuesOperationSnackbarDataSelectors.setParameterValuesOperationSnackbarSuccess}>
			<Alert variant="filled" severity="success" onClose={() => setShouldShowSnackbar(false)} data-cy={setParameterValuesOperationSnackbarDataSelectors.setParameterValuesOperationSnackbarAlertSuccess}>
				{operationResponse?.length} parameter value{operationResponse?.length === 1 ? '' : 's'} set successfully.
			</Alert>
		</Snackbar>
	</>);
};

import {createSelector} from 'reselect';
import { Firmware, Report, ReportsResponse } from '../../shared/types';

import {RootState} from '../reducers';
import { PaginatedResults, createOperationResultSelector, operationSelector } from '@indigo-cloud/common-react';
import { FirmwareReport } from '../../shared/types/FirmwareReport';
import { RbsidReport } from '../../shared/types/RbsidReport';
import { AppReport } from '../../shared/types/AppReport';

/**
 * FIrmware report
 */
const loadFirmwareReportSelector = (state: RootState) => {
	return state?.report?.firmwareReportOperation;
};
const loadFirmwareReportOperationSelector = createSelector(loadFirmwareReportSelector, (state) => operationSelector<FirmwareReport, Error>(state));
const loadFirmwareReportOperationResultsSelector = createOperationResultSelector(loadFirmwareReportOperationSelector);
const loadFirmwareReportDataSelector = createSelector(loadFirmwareReportOperationResultsSelector, (state) => state?.data);

/**
 * Rbsid report
 */
const loadRbsidReportSelector = (state: RootState) => {
	return state?.report?.rbsidReportOperation;
};
const loadRbsidReportOperationSelector = createSelector(loadRbsidReportSelector, (state) => operationSelector<RbsidReport, Error>(state));
const loadRbsidReportOperationResultsSelector = createOperationResultSelector(loadRbsidReportOperationSelector);
const loadRbsidReportDataSelector = createSelector(loadRbsidReportOperationResultsSelector, (state) => state?.data);

/**
 * App report
 */
const loadAppReportSelector = (state: RootState) => {
	return state?.report?.appReportOperation;
};
const loadAppReportOperationSelector = createSelector(loadAppReportSelector, (state) => operationSelector<AppReport, Error>(state));
const loadAppReportOperationResultsSelector = createOperationResultSelector(loadAppReportOperationSelector);
const loadAppReportDataSelector = createSelector(loadAppReportOperationResultsSelector, (state) => state?.data);

/**
 * CREATE_REPORT
 */
const createReportSelector = (state: RootState) => {
	return state?.report?.createReportOperation;
};

const createReportOperationSelector = createSelector(createReportSelector, (state) => operationSelector(state));
const createReportOperationResultSelector = createOperationResultSelector(createReportOperationSelector);

/**
 * DELETE_REPORT
 */
const deleteReportSelector = (state: RootState) => {
	return state?.report.deleteReportOperation;
};

const deleteReportOperationSelector = createSelector(deleteReportSelector, (state) => operationSelector(state));
const deleteReportOperationResultSelector = createOperationResultSelector(deleteReportOperationSelector);

/**
 * LIST_REPORT
 */
const listReportsSelector = (state: RootState) => {
	return state?.report.listReportsOperation;
};
const listReportsOperationSelector = createSelector(listReportsSelector, (state) => operationSelector<ReportsResponse, Error>(state));
const listReportsOperationResultsSelector = createOperationResultSelector(listReportsOperationSelector);
const listReportsOperationDataSelector = createSelector(listReportsOperationResultsSelector, (state) => state?.data);

/**
 * GET_REPORT_INFO
 */
const getReportInfoSelector = (state: RootState) => {
	return state?.report.loadReportInfoOperation;
};

const getReportInfoOperationSelector = (id: string) => {
	return createSelector(getReportInfoSelector, (state) => {
		return operationSelector<Report, Error>(state[id]);
	})
};
const getReportInfoOperationResultsSelector = (id: string) => createOperationResultSelector(getReportInfoOperationSelector(id));

/**
 * UPDATE_REPORT
 */
const updateReportSelector = (state: RootState) => {
	return state?.report?.updateReportOperation;
};

/**
 * DOWNLOAD_REPORT
 */
const downloadReportSelector = (state: RootState) => {
	return state?.report?.downloadReportOperation;
};


const updateReportOperationSelector = createSelector(updateReportSelector, (state) => operationSelector(state));
const updateReportOperationResultSelector = createOperationResultSelector(updateReportOperationSelector);

const downloadReportOperationSelector = createSelector(downloadReportSelector, (state) => operationSelector(state));
const downloadReportOperationResultSelector = createOperationResultSelector(downloadReportOperationSelector);

const getReportByIdentifiersSelector = (reportId: string | undefined) => createSelector(listReportsOperationDataSelector, (state) => {
	return state?.filter(({id}) => id === reportId)?.[0]
});

export const reportSelectors = {
	createReportOperation: createReportOperationSelector,
	createReportOperationResult: createReportOperationResultSelector,
	deleteReportOperation: deleteReportOperationSelector,
	deleteReportOperationResult: deleteReportOperationResultSelector,
	getReportByIdentifiers: getReportByIdentifiersSelector,

	getReportInfoOperation: getReportInfoOperationSelector,
	getReportInfoOperationResults: getReportInfoOperationResultsSelector,

	listReportsOperation: listReportsOperationSelector,
	listReportsOperationData: listReportsOperationDataSelector,

	updateReportOperation: updateReportOperationSelector,
	updateReportOperationResult: updateReportOperationResultSelector,

	downloadReportOperation: downloadReportOperationSelector,
	downloadReportOperationResult: downloadReportOperationResultSelector,

	loadFirmwareReportData: loadFirmwareReportDataSelector,
	loadFirmwareReportOperation: loadFirmwareReportOperationSelector,
	loadFirmwareReportOperationResults: loadFirmwareReportOperationResultsSelector,

	loadRbsidReportData: loadRbsidReportDataSelector,
	loadRbsidReportOperation: loadRbsidReportOperationSelector,
	loadRbsidReportOperationResults: loadRbsidReportOperationResultsSelector,

	loadAppReportData: loadAppReportDataSelector,
	loadAppReportOperation: loadAppReportOperationSelector,
	loadAppReportOperationResults: loadAppReportOperationResultsSelector

};

import React, {ReactNode, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation, useRouteMatch} from 'react-router-dom';
import {RouterState} from 'connected-react-router';
import {LinearProgress} from '@mui/material';
import {Modal} from '../Modal';
import {Details} from '../Details';

export interface PageViewDetailsProperties<Entity = unknown> {
	previousRoute: RouterState | undefined;
	listPageRoute: string;
	listOperationIsLoading: boolean;
	item: Entity;
	modalTitle: string;
}

export const pageViewDetailsDataSelectors = {
	modal: 'PageViewDetails-modal'
};

export const PageViewDetails = <T,>({previousRoute, item, listPageRoute, modalTitle, listOperationIsLoading}: PageViewDetailsProperties) => {

	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();


	const didNavigateFromListPage = previousRoute?.location?.pathname === listPageRoute;

	const onModalBack = () => {
		if (didNavigateFromListPage) {
			history.goBack();
		} else {
			history.push(listPageRoute);
		}
	}

	const onModalOk = () => {
		history.push(listPageRoute);
	}


	return <Modal
		isOpen
		isLoading={listOperationIsLoading}
		title={modalTitle}
		onClickOk={onModalOk}
		onClickBack={onModalBack}
		data-cy={pageViewDetailsDataSelectors.modal}
		hide={{scroll: true}}
	>
		{!!item && <Details object={item as any} isFullWidth = {false} />}
	</Modal>
}

PageViewDetails.propTypes = {};

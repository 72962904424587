import React, { useContext } from 'react';
import Loadable from 'react-loadable';
import { Route, Switch, useLocation } from 'react-router-dom';

import { BackdropLoader, NavigationBreadcrumbs, PrivateRoute, Role } from '@indigo-cloud/common-react';
import GroupIcon from '@mui/icons-material/Group';
import styles from './App.router.module.scss';
import { AwsExportsContext } from './components';

export enum AppRouteNames {
	Dashboard = 'Dashboard',
	UserManagement = 'UserManagement',
	UserInfo = 'UserInfo',
	NotFound = 'NotFound',
}


export const appRoutes = {
	[AppRouteNames.Dashboard]: '/admin',
	[AppRouteNames.UserManagement]: '/admin/users',
	[AppRouteNames.UserInfo]: '/admin/user/:userId',

	[AppRouteNames.NotFound]: '/users/page-not-found'
}


export const navigationItems = [
	{
		name: AppRouteNames.NotFound,
		shouldHideLink: true
	},
	{
		name: AppRouteNames.Dashboard,
		shouldHideLink: true
	},
	{
		icon: GroupIcon,
		name: AppRouteNames.UserManagement,
		roles: [Role.Admin]
	}
];


export type AppRouteKey = keyof typeof appRoutes;
export const appRouteNames: AppRouteKey[] = [];

for (const appRouteKey in appRoutes) {
	appRouteNames.push(appRouteKey as AppRouteKey);
}

const Loading = () => <BackdropLoader isLoading />;
export const pages = {
	[AppRouteNames.Dashboard]: Loadable({
		loader: () => import('./pages/Dashboard/Dashboard.page'),
		loading: Loading
	}),
	[AppRouteNames.UserManagement]: Loadable({
		loader: () => import('./pages/UserManagement/UserListEditor.page'),
		loading: Loading
	}),
	[AppRouteNames.UserInfo]: Loadable({
		loader: () => import('./pages/UserManagement/pages/UserInfo/UserInfo.page'),
		loading: Loading
	}),

	[AppRouteNames.NotFound]: Loadable({
		loader: () => import('./pages/NotFound/NotFound.page'),
		loading: Loading
	})
};

interface AppRouterProperties {}

export const AppRouter: React.FC<AppRouterProperties> = () => {
	const contextAwsExports = useContext(AwsExportsContext);

	const location = useLocation();

	return (
		<div
			className={styles.container}
		>
			<NavigationBreadcrumbs routes={appRoutes} />
			<Switch location={location as any}>
				{/* Private routes */}
				<PrivateRoute roles={[Role.Admin]} path={appRoutes.Dashboard} exact component={pages[AppRouteNames.Dashboard]} awsExports={contextAwsExports}	 />
				<PrivateRoute roles={[Role.Admin]} path={appRoutes.UserManagement} component={pages[AppRouteNames.UserManagement]} awsExports={contextAwsExports} />
				<PrivateRoute roles={[Role.Admin]} path={appRoutes.UserInfo} component={pages[AppRouteNames.UserInfo]} awsExports={contextAwsExports} />
				{/* Fallback catch-all route */}
				<Route component={pages[AppRouteNames.NotFound]} />
			</Switch>
		</div>
	);
};

AppRouter.propTypes = {};

import React, {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import jwtDecode from 'jwt-decode';
import {Redirect, Route, RouteComponentProps, RouteProps, useHistory, useLocation, withRouter} from 'react-router-dom';

import {BackdropLoader} from '../BackdropLoader';
import {reactRouterPropertyTypes} from '../../utils'
import Auth from '@aws-amplify/auth';
import {signout} from '../../utils/auth';
import {useAuthorisation} from '@indigo-cloud/common-react';
import {Role, isAuthorised} from '@indigo-cloud/common-react';

export type PrivateRouteProperties = RouteProps & {roles?: Role[]; awsExports: any;};

let hasInit = false;

export const PrivateRoute: React.FC<PrivateRouteProperties> = ({
	roles,
	component: Component,
	...rest
}: PrivateRouteProperties) => {
	const {awsExports = {
		oauth: {
			domain: '',
			response_type: '',
			scope: []
		}
	}} = rest as {awsExports: any};

	const [isAuthenticated, setIsAuthenticated] = useState<boolean | undefined>();

	const [isAuthorisedForRole, setIsAuthorisedForRole] = useState<boolean | undefined>();
	const history = useHistory();
	const userAuthorisationGroups = useAuthorisation();

	const cognitoHostedUIEndpoint = useMemo(() => {
		const {
			oauth: {
				domain,
				response_type = 'token',
				scope = [
					'aws.cognito.signin.user.admin',
					'email',
					'profile',
					'openid'
				]
			},
			aws_user_pools_web_client_id
		} = awsExports as any;

		return `https://${domain}/login?response_type=${response_type}&client_id=${aws_user_pools_web_client_id}&scope=${scope.join(
			'+'
		)}&redirect_uri=${window.location.origin}/`;
	}, [awsExports]);

	const setRedirectUrlIfExists = useCallback(() => {
		const currentUrl = window.location.pathname;
		if (currentUrl !== '/' && currentUrl !== '') {
		  // Set redirect URL in local storage if the current URL is not the home page
		  localStorage.setItem('redirectUrl', currentUrl);
		}
	}, []);

	const navigateToRedirectUrlIfExists = useCallback(() => {
		const storedUrl = localStorage.getItem('redirectUrl');
		if (storedUrl) {
			localStorage.removeItem('redirectUrl');

			history.replace(storedUrl)
			// window.location.href = ;
		}
		return;
	}, []);

	const login = useCallback(async (user, token, expiry, idToken) => {
		localStorage.setItem(
			'federatedCredentials',
			JSON.stringify({

				idToken,
				token,
				user
			})
		);

		try {
			await Auth.federatedSignIn(
				`cognito-idp.eu-west-1.amazonaws.com/${awsExports.aws_user_pools_id}`,
				{
					expires_at: new Date(expiry * 1000).getTime(),
					token: idToken
				},
				user
			)
		}
		catch (error) {
			console.error('An error occurred while performing the federated sign in', error);
		}

	}, []);

	//checkTokenExpiration function will trigger the signout function from the utility as soon as the token expires
	const checkTokenExpiration = useCallback((expiry: number) => {
		const currentTimestamp = Date.now(); // Current timestamp in milliseconds
		const millisecondsUntilExpiry = (expiry * 1000) - currentTimestamp;

		setTimeout(signout, millisecondsUntilExpiry); // Schedule the signout action when the token expires
	  }, [signout]);

	const authenticate = useCallback(async () => {
		try {
			const urlParameters = new URLSearchParams(window.location.hash?.replace(/^#/, '?') || window.location.search);
			const urlParameterAccessToken = urlParameters.get('access_token');
			const urlParameterIdToken = urlParameters.get('id_token');
			const urlParameterIdExpiresIn = urlParameters.get('expires_in');
			const federatedInfoCached = localStorage.getItem('federatedCredentials');

			let isExpired = false;
			let federatedInfoCachedDecoded;
			if (federatedInfoCached?.length) {
				federatedInfoCachedDecoded = JSON.parse(federatedInfoCached);
				if (federatedInfoCachedDecoded?.user?.exp) {
					const expiry = new Date(+federatedInfoCachedDecoded?.user?.exp * 1000);
					isExpired = new Date() >= expiry;
				}
			}

			if (process.env.NODE_ENV?.toLocaleLowerCase() === 'test') {
				setIsAuthenticated(true);
				navigateToRedirectUrlIfExists();
			} else if (urlParameterAccessToken) {
				const decoded: {exp?: number; user?: {exp?: number}} = jwtDecode(urlParameterAccessToken);
				const expirationTimestamp = decoded.exp || decoded.user?.exp;

				await login(decoded, urlParameterAccessToken, +urlParameterIdExpiresIn!, urlParameterIdToken);

				setIsAuthenticated(true);
				/* eslint-disable-next-line unicorn/no-null */
				window.history.replaceState(null, document.title, window.location.pathname);
				navigateToRedirectUrlIfExists();
				checkTokenExpiration(expirationTimestamp as number); // Start monitoring the token's expiration
			} else if (!federatedInfoCachedDecoded || isExpired) {
				// Store the URL before login
				setRedirectUrlIfExists();
				setIsAuthenticated(false);
			}
			// else if (!rest.path) {
			// 	forceUpdate();
			// }
			else if (hasInit === false && federatedInfoCachedDecoded?.user) {

				hasInit = true;
				await login(
					federatedInfoCachedDecoded?.user,
					federatedInfoCachedDecoded?.token,
					federatedInfoCachedDecoded?.user?.exp,
					federatedInfoCachedDecoded?.idToken
				);
				setIsAuthenticated(true);
				// Don't call login on every route change. Logout will redirect to hosted UI so flag will reset

				navigateToRedirectUrlIfExists();
				checkTokenExpiration(federatedInfoCachedDecoded?.user?.exp as number); // Start monitoring the token's expiration
			} else if (hasInit === true && federatedInfoCachedDecoded?.user) {
				setIsAuthenticated(true);
				checkTokenExpiration(federatedInfoCachedDecoded?.user?.exp as number); // Start monitoring the token's expiration
			}
		} catch(error) {
			console.warn('No authenticated user was found. Redirecting to login.');
			console.error(error)
			localStorage.removeItem('auth');
			setIsAuthenticated(false);
		}
	}, [login, checkTokenExpiration, signout]);

	const authorise = async () => {
		try {
			const result = isAuthorised(roles, userAuthorisationGroups);
			setIsAuthorisedForRole(result);

		} catch{
			setIsAuthorisedForRole(false);
		}
	};

	useEffect(() => {
		if (Object.keys(userAuthorisationGroups)?.length !== 0) {

			authorise();
		}

	}, [userAuthorisationGroups, rest?.path]);

	useEffect(() => {
		authenticate();

	}, []);

	const isLoading = isAuthenticated === undefined;

	if (!Component) {
		throw new Error('A component to this route was not provided.');
	}

	return (
		<Route
			{...rest}
			// @ts-ignore
			isAuthenticated={isAuthenticated}
			render={(properties) => {
				if (isAuthenticated === undefined) {
					/* eslint-disable-next-line unicorn/no-null */
					return <BackdropLoader isLoading />;
				} else if (isAuthenticated === false) {

					// return <Component {...properties} />;
					// console.log('env',process.env.NODE_ENV, process.env.CI);
					// [20/04/22]: This needs to be 'test' not CI. Otherwise if we use 'CI', we get this error when running cypress tests 'Error: [BABEL] /src/indigo-cloud-portal/packages/portal/cypress/integration/usp/firmware.page.spec.ts: Using `babel-preset-react-app` requires that you specify `NODE_ENV` or `BABEL_ENV` environment variables. Valid values are "development", "test", and "production". Instead, received: "ci"'
					if (process.env.NODE_ENV?.toLowerCase() !== 'test') {
						window.location.href = cognitoHostedUIEndpoint;
						/* eslint-disable-next-line unicorn/no-null */
						return null;
					} else {
						return <Component {...properties} />;
					}

					// return <Component {...properties} />;
				} else if (isAuthorisedForRole === undefined) {
					/* eslint-disable-next-line unicorn/no-null */
					return <BackdropLoader isLoading />;
				} else {
					// if groupName exists renders the Component else redirect to 404
					if (roles?.length) {
						return isAuthorisedForRole	 ? (
							<Component {...properties} />
						) : (
							<Redirect to='/404' />
						);
					} else {
						return <Component {...properties} />;
					}
				}
			}}
		/>
	);
};
PrivateRoute.propTypes = {
	// ...reactRouterPropertyTypes
};

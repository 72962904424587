import { Job } from '../types';
import { History } from 'history';
import { appRoutes } from '../../components';

export type TJobFor = 'device' | 'deviceGroup';
export const getJobIdentifier = ({ jobId }: Job) => jobId;
export const getExecutionNoIdentifier = ({ executionNumber }: Job) => executionNumber;

export const getJobIdentifierUrlForDevice = (job: Job, name: string) => {
	const identifier = getJobIdentifier(job);
	const executionNumber = getExecutionNoIdentifier(job);
	return appRoutes.JobDetailsForDevice.replace(':jobId', encodeURIComponent(identifier + ( executionNumber ? ('+'+ executionNumber) : '' ))).replace(':agentEndpointId', encodeURIComponent(name));
};

export const getJobIdentifierUrlForDeviceGroup = (job: Job, name: string) => {
	const identifier = getJobIdentifier(job);
	const executionNumber = getExecutionNoIdentifier(job);
	return appRoutes.JobDetailsForDeviceGroup.replace(':jobId', encodeURIComponent(identifier)).replace(':id', encodeURIComponent(name));
};

export const navigateToJobIdentifier = (job: Job, name: string, history: History<unknown>, jobFor:TJobFor) => {
	const identifierUrl = (jobFor === 'device') ? getJobIdentifierUrlForDevice(job, name) : getJobIdentifierUrlForDeviceGroup(job, name);
	history.push(identifierUrl);
}

import { Firmware } from '../../../shared/types';
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import {
	CREATE_FIRMWARE,
	CREATE_FIRMWARE_ERROR,
	CREATE_FIRMWARE_SUCCESS,
	DELETE_FIRMWARE,
	DELETE_FIRMWARE_ERROR,
	DELETE_FIRMWARE_SUCCESS,
	LIST_FIRMWARE,
	LIST_FIRMWARE_CLEAR,
	LIST_FIRMWARE_ERROR,
	LIST_FIRMWARE_SUCCESS,
	UPDATE_FIRMWARE,
	UPDATE_FIRMWARE_ERROR,
	UPDATE_FIRMWARE_SUCCESS
} from '../../constants';
import { FormikFormSubmitPromise, PaginatedResults, createAction } from '@indigo-cloud/common-react';
import { Filters } from 'react-table';

export type CreateFirmwareRequest = ReturnType<typeof firmwareActions['createFirmware']>['payload'];
export type UpdateFirmwareRequest = ReturnType<typeof firmwareActions['updateFirmware']>['payload'];
export type DeleteFirmwareRequest = ReturnType<typeof firmwareActions['deleteFirmware']>['payload'];
export type ListFirmwareRequest = ReturnType<typeof firmwareActions['listFirmware']>['payload'];

export const firmwareActions = {

	/**
	 * CREATE FIRMWARE
	 */
	createFirmware: (model: Firmware, formikPromise: FormikFormSubmitPromise, createAnother?: boolean): PayloadAction<typeof CREATE_FIRMWARE, { createAnother: typeof createAnother; formikPromise: typeof formikPromise;  model: typeof model;  }> => createAction(CREATE_FIRMWARE, { createAnother, formikPromise, model }),
	createFirmwareError: (error: Error): PayloadAction<typeof CREATE_FIRMWARE_ERROR, { error: typeof error }> =>
		createAction(CREATE_FIRMWARE_ERROR, { error }),
	createFirmwareSuccess: (response: any): PayloadAction<typeof CREATE_FIRMWARE_SUCCESS, { response: typeof response }> =>
		createAction(CREATE_FIRMWARE_SUCCESS, { response }),

	 /**
	 * DELETE FIRMWARE
	 */
	deleteFirmware: (model: Firmware): PayloadAction<typeof DELETE_FIRMWARE, { model: typeof model }> => createAction(DELETE_FIRMWARE, { model }),
	deleteFirmwareError: (error: Error): PayloadAction<typeof DELETE_FIRMWARE_ERROR, { error: typeof error }> =>
		createAction(DELETE_FIRMWARE_ERROR, { error }),
	deleteFirmwareSuccess: (response: any): PayloadAction<typeof DELETE_FIRMWARE_SUCCESS, { response: typeof response }> =>
		createAction(DELETE_FIRMWARE_SUCCESS, {  response }),

	/**
	 * LIST FIRMWARES
	 */
	listFirmware: ({limit, cursor, pageIndex, filters}: {filters?: Record<string, unknown>, limit?: number, cursor?: string, pageIndex?: number}): PayloadAction<typeof LIST_FIRMWARE, {filters: typeof filters, limit?: number, cursor?: string, pageIndex?: number}> => createAction(LIST_FIRMWARE, { cursor, filters, limit, pageIndex }),
	listFirmwareClear: (): PayloadAction<typeof LIST_FIRMWARE_CLEAR, any> => createAction(LIST_FIRMWARE_CLEAR, {}),
	listFirmwareError: (error: Error): PayloadAction<typeof LIST_FIRMWARE_ERROR, { error: typeof error }> =>
		createAction(LIST_FIRMWARE_ERROR, { error }),
	listFirmwareSuccess: (result: PaginatedResults<Firmware>, pageIndex: number, filters?: Record<string, unknown>): PayloadAction<typeof LIST_FIRMWARE_SUCCESS, { pageIndex: number; result: typeof result , filters: typeof filters}> =>
		createAction(LIST_FIRMWARE_SUCCESS, { filters, pageIndex, result }),


	/**
	 * UPDATE FIRMWARE
	*/
	updateFirmware: (model: Firmware, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof UPDATE_FIRMWARE, { formikPromise: typeof formikPromise; model: typeof model;  }> => createAction(UPDATE_FIRMWARE, { formikPromise, model }),
	updateFirmwareError: (error: Error): PayloadAction<typeof UPDATE_FIRMWARE_ERROR, { error: typeof error }> =>
		createAction(UPDATE_FIRMWARE_ERROR, { error }),
	updateFirmwareSuccess: (response: any): PayloadAction<typeof UPDATE_FIRMWARE_SUCCESS, { response: typeof response }> =>
		createAction(UPDATE_FIRMWARE_SUCCESS, { response })


};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
export type firmwareActions = ActionType<typeof firmwareActions>;

import { Box, Tab, Tabs, Typography } from '@mui/material';
import React, {Children, ReactNode, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {Form, Formik, FormikProps} from 'formik';
import * as Yup from 'yup';
import * as PropTypes from 'prop-types';
import FormControl from '@mui/material/FormControl';
import { DeviceGroupActivationWindow, ScheduleConfig } from '../../';
import { TabPanel, getTabAttributes } from '../../../pages/DeviceInfo/components/DeviceInfoTabs';
import { FormikFormSubmitPromise,  useIsMounted } from '@indigo-cloud/common-react';
import { AbortConfigForm, ActivationWindow, SchedulingConfigForm } from './components';

import { ExecutionsRolloutForm } from './components/ExecutionsRolloutForm/ExecutionsRolloutForm.component';
import { ObjectShape } from 'yup/lib/object';
import { red } from '@mui/material/colors';

export interface DeviceGroupConfigurationInputFormProperties<AdditionalFormValues = any> {
    children?: ReactNode;
    referenceFormik: React.MutableRefObject<FormikProps<DeviceGroupConfigurationInputFormValues> | null>;
	isScheduleConfigStartTimeOptional?: boolean;
    onFormIsValidChanged?: (value: boolean) => void;
    onFormIsTouchedChanged?: (value: boolean) => void;
    onFormIsSubmittingChanged?: (value: boolean) => void;
    onFormValuesChanged?: (values?: DeviceGroupConfigurationInputFormValues & AdditionalFormValues) => void;
    onErrorsChanged?: (errors?: any) => void;
    onSubmit: (formValues: AdditionalFormValues & {
        activationWindow: { endTime: string | undefined; mode: string | undefined; startTime: string | undefined; }[] | undefined;
    } & Pick<DeviceGroupConfigurationInputFormValues, 'abortConfig' | 'executionsRolloutConfig' | 'schedulingConfig'>, promise: FormikFormSubmitPromise) => void;
    onReset?: () => void;
    activationWindowOnly?: boolean;
    hideActivationWindow?: boolean;
    updateMode?: string;
    shouldHideForm?: boolean;
    error?: any;
    schemaAdditions?: ObjectShape;
    initialValueAdditions?: Partial<DeviceGroupConfigurationInputFormValues & AdditionalFormValues>
    
}
export interface DeviceGroupConfigurationInputFormState {
	errorMessage?: string;
}

export const deviceGroupConfigurationInputFormDataSelectors = {
	form: 'DeviceGroupConfigurationInputForm-form',
	formControlActivationWindowRowsEndTime: 'DeviceGroupConfigurationInputForm-form-control-activation-window-rows-start-time',
	formControlActivationWindowRowsMode: 'DeviceGroupConfigurationInputForm-form-control-activation-window-rows-mode',
	formControlActivationWindowRowsStartTime: 'DeviceGroupConfigurationInputForm-form-control-activation-window-rows-start-time'
};

export type DeviceGroupConfigurationInputFormReference = {
  formik: FormikProps<DeviceGroupConfigurationInputFormValues> | null;
  formReset?: () => void;
};

export type DeviceGroupConfigurationInputFormValues = {
    
	activationWindowRows: (Record<string, unknown> | DeviceGroupActivationWindow)[];
	abortConfig?: {
		minNumberOfExecutedThings: number;
		thresholdPercentage: number;
	};
	executionsRolloutConfig?: {
		maximumPerMinute: number;
		exponentialRate: {
			baseRatePerMinute: number;
			incrementFactor: number;
			rateIncreaseCriteria: {
			  numberOfSucceededThings: number;
			}
		},
	},
	schedulingConfig?: {
		startTime?: Date;
		endTime?: Date;
		endBehavior?: string;
		maintenanceWindows?: [
			{
                startTime?: Date;
                durationInMinutes: number;
			}
		]
	}
};

export const DeviceGroupConfigurationInputForm = React.forwardRef<DeviceGroupConfigurationInputFormReference, DeviceGroupConfigurationInputFormProperties>(({
	onFormIsTouchedChanged,
	onFormIsSubmittingChanged,
	onFormIsValidChanged,
	onFormValuesChanged,
	onSubmit,
	onReset,
	children,
	onErrorsChanged,
	activationWindowOnly,
	hideActivationWindow = false,
	schemaAdditions,
	isScheduleConfigStartTimeOptional,
	initialValueAdditions,
	error,
	referenceFormik,
	shouldHideForm = true
}, reference) => {
	const schema = useMemo(
		() =>
			Yup.object().shape({
				...schemaAdditions,
				activationWindowRows: Yup.array()
					.of(
						Yup.object().shape({
							endTime: Yup.string().when('mode', {
								is: 'WhenIdle',
								then: Yup.string().required('End time is required').
									test('endTime', 'End time must be greater than start Time', function (value) {
										const startTime = new Date(this.parent.startTime);
										const endTime = new Date(value as unknown as Date);
										return endTime > startTime;
									})
							}),
							mode: Yup.string().label('Mode').required(),
							startTime: Yup.string().when('mode', {
								is: (value: string) => value === 'Immediately' || value === 'WhenIdle',
								then: Yup.string().required('Start time is required').test('startTime', 'Start time must be greater than current time', function (value) {
									const now = new Date();
									const startTime = new Date(value as unknown as Date);
									return startTime > now;
								})
							})
						})
					).test('atLeastThirtyMinutes', 'At least one End Time should be at least 30 minutes from the current time', function (value) {
						if (activationWindowOnly || !value?.length) {
							return true;
						}
						const thirtyMinutesFromNow = new Date();
						thirtyMinutesFromNow.setMinutes(thirtyMinutesFromNow.getMinutes() + 30);
						return value ? value.some((row) => {
							const mode = row.mode;
							if(mode === 'Immediately'){
								return true;
							} else {
								if (mode) {
									const endTime = new Date(row.endTime as unknown as Date);
									return endTime >= thirtyMinutesFromNow;
								}

								return true;
							}
						}) : false;
					}).max(5, 'Maximum 5 window allowed'),

				abortConfig: Yup.object().shape({
					minNumberOfExecutedThings: Yup.number().label('Min Job Executions').integer(),
					thresholdPercentage: Yup.number().label('Threshold Percentage').integer().min(1).max(100)
				}).test({
					test: function (values) {

					  // If the object is empty, or all fields are not set, allow it to be submitted. But if any field is set, all fields must be set.
					  if (!values) {
							return true;
					  }

					  const {
							minNumberOfExecutedThings,
							thresholdPercentage
					  } = values;
					  if (
							minNumberOfExecutedThings === undefined &&
						thresholdPercentage === undefined
					  ) {
							return true;
					  }

					  if (
							minNumberOfExecutedThings !== undefined &&
						thresholdPercentage !== undefined
					  ) {
							return true;
					  }
					  return new Yup.ValidationError([new Yup.ValidationError('Either none, or all fields in abort config should be populated.', undefined, 'abortConfig.minNumberOfExecutedThings'), new Yup.ValidationError('Either none, or all fields in abort config should be populated.', undefined, 'abortConfig.thresholdPercentage')])
					}
				  }),
				executionsRolloutConfig: Yup.object().shape({
					maximumPerMinute: Yup.number().label('Max Rate Per Minute').integer().min(1).max(600),
					exponentialRate: Yup.object().shape({
						baseRatePerMinute: Yup.number().label('Base Rate Per Minute'),
						incrementFactor: Yup.number().label('Increment Factor'),
						rateIncreaseCriteria: Yup.object().shape({
							numberOfSucceededThings: Yup.number().label('No of succeeded things').integer()
						})
					})
				}).test({
					test: function (values) {

						if (activationWindowOnly) {
							return true;
						}

					  // If the object is empty, or all fields are not set, allow it to be submitted. But if any field is set, all fields must be set.
					  if (!values) {
							return true;
					  }

					  const {
							maximumPerMinute,
							exponentialRate
					  } = values;

					  const {
							baseRatePerMinute,
							incrementFactor,
							rateIncreaseCriteria
					  } = exponentialRate || {};

					  const { numberOfSucceededThings } = rateIncreaseCriteria || {};
					  if (
							maximumPerMinute === undefined &&
						baseRatePerMinute === undefined &&
						incrementFactor === undefined &&
						numberOfSucceededThings === undefined
					  ) {
							return true;
					  }

					  if (!maximumPerMinute) {
							return new Yup.ValidationError('Max rate per minute is required.', undefined, 'executionsRolloutConfig.maximumPerMinute');
						}

						if (incrementFactor !== undefined && (
							baseRatePerMinute === undefined ||
							numberOfSucceededThings === undefined
						  )) {
							return new Yup.ValidationError([new Yup.ValidationError('If increment factor is populated, baseRatePerMinute and numberOfSucceededThings must also be populated.', undefined, 'executionsRolloutConfig.exponentialRate.baseRatePerMinute'), new Yup.ValidationError('If increment factor is populated, baseRatePerMinute and numberOfSucceededThings must also be populated.', undefined, 'executionsRolloutConfig.exponentialRate.incrementFactor'), new Yup.ValidationError('If increment factor is populated, baseRatePerMinute and numberOfSucceededThings must also be populated.', undefined, 'executionsRolloutConfig.exponentialRate.rateIncreaseCriteria.numberOfSucceededThings')])
						  }

					  return true;
					}
				  }),
				schedulingConfig: Yup.object().shape({
					startTime: Yup.string().nullable(true).label('Start Time').test('startTime', 'Start time must be greater than 30 minutes from the current time', function (value) {

						if (!value) {
							return true;
						}
						const now = new Date();
						const startTime = new Date(value as unknown as Date);
						const thirtyMinutesInMilliseconds = 30 * 60 * 1000;
						return startTime.getTime() - now.getTime() >= thirtyMinutesInMilliseconds;
					}),
					endTime: Yup.string().nullable(true).label('End Time').
						test('endTime', 'End time must be 30 minutes greater than start time', function (value) {
							if (value === undefined || (isScheduleConfigStartTimeOptional && !this.parent.startTime)) {
								return true;
							}
							const startTime = new Date(this.parent.startTime);
							const endTime = new Date(value as unknown as Date);
							const thirtyMinutesInMilliseconds = 30 * 60 * 1000;
							return endTime.getTime() - startTime.getTime() >= thirtyMinutesInMilliseconds;
						}),
					endBehavior: Yup.string().label('End Behavior'),
					maintenanceWindows: Yup.array()
						.of(
							Yup.object().shape({
								startTime: Yup.string().label('Start Time').test('startTime', 'Start time must be greater than current time', function (value) {
									const now = new Date();
									const startTime = new Date(value as unknown as Date);
									return startTime > now;
								}),
								durationInMinutes: Yup.number().label('Duration In Minutes').integer().required()
							})
						)
				}).test({
					test: function (values) {

					  // If the object is empty, or all fields are not set, allow it to be submitted. But if any field is set, all fields must be set.
					  if (!values || (isScheduleConfigStartTimeOptional && !values.startTime)) {
							return true;
					  }
					  const {
							startTime,
							endTime
							// endBehavior
						// maintenanceWindows
					  } = values;
					  if (
							startTime === undefined &&
						endTime === undefined
						// endBehavior === undefined
						// maintenanceWindows === undefined
					  ) {
							return true;
					  }

					  if (
							startTime !== undefined &&
						endTime !== undefined
						// endBehavior !== undefined
						// maintenanceWindows !== undefined
					  ) {
							return true;
					  }
					  return new Yup.ValidationError([new Yup.ValidationError('Either none, or all fields in schedule config should be populated.', undefined, 'schedulingConfig.startTime'), new Yup.ValidationError('Either none, or all fields in schedule config should be populated.', undefined, 'schedulingConfig.endTime')])
					}
				  })
			}),
		[activationWindowOnly, isScheduleConfigStartTimeOptional]
	);

	const isMounted = useIsMounted();

	const referenceFormIsValid = useRef(false);
	const referenceFormIsSubmitting = useRef(false);
	const referenceFormIsTouched = useRef(false);
	const referenceFormValues = useRef();
	const referenceFormErrors = useRef();
	
	const [activeTab, setActiveTab] = React.useState(0);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setActiveTab(newValue);
	};

	useImperativeHandle(reference, () => ({
		formReset: referenceFormik.current?.resetForm,
		formik: referenceFormik.current
	}));

	return (
		<Formik<DeviceGroupConfigurationInputFormValues>
			innerRef={referenceFormik}
			initialValues={{
				...initialValueAdditions,
				activationWindowRows: [],
				abortConfig: undefined,
				schedulingConfig: {
					startTime: undefined,
					endTime: undefined,
					endBehavior: 'CANCEL',
					maintenanceWindows: undefined

				},
				executionsRolloutConfig: undefined
			}}
			enableReinitialize
			validateOnMount
			validateOnChange
			validationSchema={schema}
			onSubmit={async (values, { resetForm }) => {
				const activationWindows = values.activationWindowRows.map(aw => {
					if (typeof aw === 'object' && aw !== null && 'startTime' in aw) {
						const startTime = aw.startTime as Date;
						const immediatelyEndtime = new Date(startTime.getTime() + 30*60_000);
						return {
							endTime: ((aw as DeviceGroupActivationWindow).mode === 'WhenIdle') ? (aw as DeviceGroupActivationWindow)?.endTime?.toISOString() : immediatelyEndtime.toISOString(),
							mode: (aw as DeviceGroupActivationWindow).mode,
							startTime: (aw as DeviceGroupActivationWindow)?.startTime?.toISOString()
						}
					} else {
						return {
							endTime: undefined,
							mode: '',
							startTime: undefined
						}
					}
				});

				const scheduleConfigParsed: ScheduleConfig | undefined = values.schedulingConfig?.endTime ? {
					startTime: values.schedulingConfig.startTime ? values.schedulingConfig.startTime!.toISOString().slice(0, -8) : undefined,
					endTime: values.schedulingConfig.endTime!.toISOString().slice(0, -8),
					endBehavior: values.schedulingConfig.endBehavior! as ScheduleConfig['endBehavior'],
					maintenanceWindows: values.schedulingConfig?.maintenanceWindows?.map(({
						durationInMinutes,
						startTime
					}) => ({
						durationInMinutes,
						startTime: startTime ? startTime!.toISOString().slice(0, -8) : undefined
					}))
				} : undefined;

				try {
					await new Promise((resolve, reject) => {
						const {
							activationWindowRows,
							abortConfig,
							executionsRolloutConfig,
							schedulingConfig,
							...rest
						} = values;


						onSubmit({ activationWindows: activationWindows?.length ? activationWindows : undefined, abortConfig, executionsRolloutConfig, schedulingConfig: scheduleConfigParsed, ...rest }, {resolve, reject});
					});
					resetForm();
				}
				catch (error) {
					console.log('An error occurred while submitting the form', error);
				}
			}}
			onReset={onReset}
		>
			{({ isValid, isSubmitting, touched, values, setFieldValue, errors}) => {

				const isFormTouched =  Object.keys(touched as any).some((key) => {
					return touched[key as keyof typeof  touched] ===true;
				});
				if (isFormTouched !== referenceFormIsTouched.current) {
					setTimeout(() => {
						if (isMounted()) {
							onFormIsTouchedChanged?.(isFormTouched)
						}
					}, 1);
				}
				if (isValid !== referenceFormIsValid.current) {
					setTimeout(() => {
						if (isMounted()) {
							onFormIsValidChanged?.(isValid)
						}
					}, 1);
				}
				if (values !== referenceFormValues.current) {
					setTimeout(() => {
						if (isMounted()) {
							onFormValuesChanged?.(values)
						}
					}, 1);
				}
				if (errors != referenceFormErrors.current) {
					setTimeout(() => {
						if (isMounted()) {
							onErrorsChanged?.(errors)
						}
					}, 1);
				}

				

				if (isSubmitting !== referenceFormIsSubmitting.current) {
					setTimeout(() => {
						if (isMounted()) {
							onFormIsSubmittingChanged?.(isSubmitting)
						}
					}, 1);
				}

				// [DF - 17/06/21]: Accessing this form component's exposed imperative handle from parent page component (eg refDeviceGroupConfigurationInputForm.current.formik) will NOT update on formik prop changes causing stale form state in the parent. Hence manual onChange detection
				referenceFormIsTouched.current = isFormTouched;
				referenceFormIsValid.current = isValid;
				referenceFormIsSubmitting.current = isSubmitting;

				const hasActivationWindowError = !!errors?.activationWindowRows;
				const hasAbortConfigError = !!errors?.abortConfig;
				const hasRolloutConfigError = !!errors?.executionsRolloutConfig;
				const hasSchedulingConfigError = !!errors?.schedulingConfig;

				const hasTabsError = hasActivationWindowError || hasAbortConfigError || hasRolloutConfigError || hasSchedulingConfigError;


				return (
					<Form data-cy={deviceGroupConfigurationInputFormDataSelectors.form} style={{ display: !shouldHideForm ? 'block' : 'none'}} onKeyDown={(keyEvent) => {
						if (keyEvent.code === 'Enter') {
						  keyEvent.preventDefault();
						}
					 }}>
                       

						{children}

						{error && <Box paddingY={1} mb={1}>

							<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error">
								{error}
							</Typography>
						</Box>}
						{hasTabsError && <Box paddingY={1} mb={1}>
							<Typography variant="body2" className="MuiFormHelperText-root Mui-error" color="error">
                                Please fix all errors in the tab pages below to continue
							</Typography>
						</Box>}
					

						<Box paddingBottom={2}>
                        
							<Tabs value={activeTab} onChange={handleChange} aria-label="Firmware rollout configuration tabs" TabIndicatorProps={{style: hasTabsError ? { backgroundColor: red['500'] } : undefined }} >
								{!hideActivationWindow && <Tab label="Activation Window" {...getTabAttributes(0)} {...hasActivationWindowError ? { style: { color: red['500'] } } : {}} />}
								{!activationWindowOnly && <Tab label="Abort Config" {...getTabAttributes(1)} {...hasAbortConfigError ? { style: { color: red['500'] } } : {}} />}
								{!activationWindowOnly && <Tab label="Rollout Config" {...getTabAttributes(2)} {...hasRolloutConfigError ? { style: { color: red['500'] } } : {}} />}
								{!activationWindowOnly && <Tab label="Scheduling Config" {...getTabAttributes(3)} {...hasSchedulingConfigError ? { style: { color: red['500'] } } : {} } />}
							</Tabs>
							<FormControl style={{width: '100%'}}>
								{!hideActivationWindow && <TabPanel value={activeTab} index={0} >
									<ActivationWindow
										values={values}
										isSubmitting={isSubmitting}
										errors={errors}
										setFieldValue={setFieldValue}
									/>
								</TabPanel>}
								<TabPanel value={activeTab} index={hideActivationWindow ? 0 : 1}>
									<AbortConfigForm
										values={values}
										isSubmitting={isSubmitting}
										setFieldValue={setFieldValue}
										errors={errors}
									/>
								</TabPanel>
								<TabPanel value={activeTab} index={hideActivationWindow ? 1 : 2}>
									<ExecutionsRolloutForm
										values={values}
										isSubmitting={isSubmitting}
										setFieldValue={setFieldValue}
										errors={errors}
									></ExecutionsRolloutForm>
								</TabPanel>
								<TabPanel value={activeTab} index={hideActivationWindow ? 2 : 3}>
									<SchedulingConfigForm
										values={values}
										isSubmitting={isSubmitting}
										setFieldValue={setFieldValue}
										errors={errors}
									></SchedulingConfigForm>
								</TabPanel>

							</FormControl>
						</Box>
					</Form>
				);
			}}
		</Formik>
	);
});

DeviceGroupConfigurationInputForm.displayName = 'Device Group Configuration Input Form';

DeviceGroupConfigurationInputForm.propTypes = {
	shouldHideForm: PropTypes.bool,
	activationWindowOnly: PropTypes.bool,
	onFormIsSubmittingChanged: PropTypes.func,
	onFormIsTouchedChanged: PropTypes.func,
	onFormIsValidChanged: PropTypes.func,
	onFormValuesChanged: PropTypes.func,
	onErrorsChanged: PropTypes.func,
	hideActivationWindow: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	onReset: PropTypes.func.isRequired,
	children: PropTypes.object,
	initialValueAdditions: PropTypes.object,
	isScheduleConfigStartTimeOptional: PropTypes.bool,
	referenceFormik: PropTypes.any,
	schemaAdditions: PropTypes.any,
	error: PropTypes.object,
	
	updateMode: PropTypes.string.isRequired
};

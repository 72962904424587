import {IconButton, Theme} from '@mui/material';
import Divider from '@mui/material/Divider';
import DrawerMUI from '@mui/material/Drawer';
import {makeStyles} from '@mui/styles';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import React, {useCallback} from 'react';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import styles from './Drawer.component.module.scss';

interface DrawerProperties {
	children: any;
	isOpen: boolean;
	onOpenChanged: (isOpen: boolean) => void;
	theme: Theme;
}

export const drawerDataSelectors = {
	drawerContainer: 'Drawer-drawer'
};

export const drawerWidth = 240;

export const Drawer: React.FC<DrawerProperties> = ({isOpen, onOpenChanged, children, theme}) => {

	const useStyles = makeStyles(() => ({
		content: {
			transition: theme.transitions.create('width', {
				duration: theme.transitions.duration.leavingScreen,
				easing: theme.transitions.easing.sharp
			}),
			width: drawerWidth - 166
		},

		contentShift: {
			transition: theme.transitions.create('width', {
				duration: theme.transitions.duration.enteringScreen,
				easing: theme.transitions.easing.easeOut
			}),
			width: drawerWidth
		}
	}));

	const classes = useStyles();

	const toggleDrawer = useCallback(
		(isOpen: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
			if (
				event &&
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
			) {
				return;
			}

			onOpenChanged(isOpen);
		},
		[]
	);

	// @ts-ignore
	return (

		<nav data-cy={drawerDataSelectors.drawerContainer} className={styles.root}>
			 {children.length > 0 &&
			<DrawerMUI
				open={true}
				onClose={toggleDrawer(false)}
				ModalProps={{
					keepMounted: true
				}}
				variant="persistent"
				anchor="left"
				sx={{color: theme.palette.primary.main, fontFamily: theme.typography.fontFamily}}
				classes={{
					paper: styles.drawer,
					root: clsx(classes.content, {
						[classes.contentShift]: isOpen
					})
				}}
			>
				<IconButton onClick={toggleDrawer(!isOpen)} className={styles.buttonIconCollapse} size="large">
					{isOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
				</IconButton>
				<Divider />
				{children}
			</DrawerMUI>}
		</nav>
	);
};

Drawer.propTypes = {
	children: PropTypes.any,
	isOpen: PropTypes.bool.isRequired,
	onOpenChanged: PropTypes.func.isRequired,
	theme: PropTypes.any
};

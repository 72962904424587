import { ConnectedRouter } from 'connected-react-router';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { AppRouter } from '../../App.router';
import { store } from '../../store';
import './App.component.scss';

import { ThemeProvider } from '@mui/material/styles';

import { theme } from '../../App.theme';

import { History } from 'history';
import { awsExports, setAwsExports } from '../../shared';

export interface AppProperties {
	awsExports: any;
	history: History<any>;
}

export const AwsExportsContext = React.createContext(awsExports);
export const App: React.FC<AppProperties> = ({ awsExports: awsExportsProperties, history }: AppProperties) => {

	useEffect(() => {
		setAwsExports(awsExportsProperties as any);
	}, []);

	return (
		<AwsExportsContext.Provider value={awsExportsProperties}>
			<Provider store={store}>
				<ConnectedRouter history={history as any}>
					<ThemeProvider theme={theme}>
						<AppRouter />
					</ThemeProvider>
				</ConnectedRouter>
			</Provider>
		</AwsExportsContext.Provider>
	);
};

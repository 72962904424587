import {createSelector} from 'reselect';

import {RootState} from '../reducers';

const locationSelector = (state: RootState) => {
	return state?.location;
};

const previousRouteSelector = createSelector(locationSelector, (state) => {
	const history = state?.history;
	if (history.length > 1) {
		return history[1];
	}
	return;
});

export const locationSelectors = {
	prevRoute: previousRouteSelector
};

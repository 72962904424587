import {Box, Theme, Typography} from '@mui/material';
import React from 'react';
import {Page} from '../Page';

export interface NotFoundPageProperties {
	theme: Theme;
}


export const notFoundPageDataSelectors = {};

export const NotFoundPageCommon: React.FC<NotFoundPageProperties> = ({children, theme}) => {
	return <Page >
		<Box
			sx={{
				alignItems: 'center',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'center',
				minHeight: '100vh'
			}}
		>
			<Typography variant="h1">
				404
			</Typography>
			<Typography variant="h6">
				The page you’re looking for doesn’t exist.
			</Typography>
		</Box>
	</Page>;
};

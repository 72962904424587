import { AppBar, UnauthorisedModal } from '@indigo-cloud/common-react';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { ThemeProvider } from '@mui/material/styles';
import { ConnectedRouter } from 'connected-react-router';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React from 'react';
import CustomScrollbars from 'react-custom-scrollbars';
import { Provider } from 'react-redux';
import { theme } from '../src/App.theme';
import './App.component.scss';
import { AppRouter } from './App.router';
import { useAmplifyAuth } from './shared/hooks/use-amplify-auth';
import { history, store } from './store';


export const App: React.FC = () => {
	const { handleSignout } = useAmplifyAuth();
	
	
	return (
		<Provider store={store}>
			<ConnectedRouter history={history}>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<CustomScrollbars className="scrollbars">
							<AppBar onSignout={handleSignout} />
							<AppRouter />
							<UnauthorisedModal />
						</CustomScrollbars>
					</LocalizationProvider>
				</ThemeProvider>
			</ConnectedRouter>
		</Provider>
	);
};



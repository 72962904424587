import React from 'react';
import * as PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';

import {Modal, ModalComponentProperties} from '../Modal';

export interface ModalConfirmationComponentProperties
	extends Omit<
		Pick<ModalComponentProperties, 'classes' | 'isOpen' | 'onClickOk' | 'title' | 'onClickClose' | 'hide'>,
		'title'
	> {
	action?: string;
	title?: string;
}

export const ModalConfirmation: React.FC<ModalConfirmationComponentProperties> = (properties) => {
	const {action, children, title} = properties;
	return (
		<Modal {...properties} title={title || 'Are you sure?'} disableBackdropClick>
			{action && <Typography variant="body1">{`Are you sure you want to ${action}?`}</Typography>}
			{children}
		</Modal>
	);
};

ModalConfirmation.propTypes = {
	action: PropTypes.string,
	classes: PropTypes.object,
	
	hide: PropTypes.object,
	isOpen: PropTypes.bool.isRequired,
	onClickClose: PropTypes.func,
	onClickOk: PropTypes.func.isRequired,
	title: PropTypes.string
};



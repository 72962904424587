import React, { useContext, useEffect, useState } from 'react';
import styles from './GetParameterValues.component.module.scss';

import { GetParameterValuesOperationSnackbar } from './components/GetParameterValuesOperationSnackbar';
import { useSelector } from 'react-redux';
import { devicesSelectors, locationSelectors } from '../../../store/selectors';
import _ from 'lodash';
import { GetParameterValuesResults } from './components/GetParameterValuesResults';
import { DeviceInfoModalType } from '../../../pages/DeviceInfo/DeviceInfo.page';
import { PrivateRoute, Role } from '@indigo-cloud/common-react';
import { AppRouteNames, appRoutes, pages } from '../../../App.router';
import { AwsExportsContext } from '../../../components/App/App.component';
import { useHistory } from 'react-router-dom';


export interface GetParameterValuesProperties {
	agentEndpointId: string;
}

export const GetParameterValues: React.FC<GetParameterValuesProperties> = ({agentEndpointId}) => {
	const getDeviceParameterValuesOperation = useSelector(devicesSelectors.getDeviceParameterValuesOperation(agentEndpointId));

	const contextAwsExports = useContext(AwsExportsContext);

	const history = useHistory();
	const didOperationSucceed = getDeviceParameterValuesOperation?.didSucceed === true;
	const previousRoute = useSelector(locationSelectors.prevRoute);
	const didNavigateFromDeviceInfoPage = previousRoute?.location?.pathname === appRoutes.DeviceInfo;

	const onModalBack = () => {
		if (didNavigateFromDeviceInfoPage) {
			history.goBack();
		} else {
			// New tab loaded the child/next route directly and has no history, or the user directly changed the address bar to the nested route from another page. Both cases require push instead of goBack
			history.push(appRoutes.DeviceInfo.replace(':agentEndpointId', agentEndpointId));
		}
	}

	useEffect(() => {
		if (didOperationSucceed === true) {
			onModalBack();
		}
	}, [didOperationSucceed]);

	const onClickUpdateRequest = (parameterPaths: string[]) => {
		history.push(appRoutes.DeviceGetParameterValues.replace(':agentEndpointId', agentEndpointId).replace(':parameterPaths?', JSON.stringify(parameterPaths)));
	}


	return <div className={styles.container}>
		<GetParameterValuesOperationSnackbar agentEndpointId={agentEndpointId} />

		<GetParameterValuesResults agentEndpointId={agentEndpointId} onClickUpdateRequest={onClickUpdateRequest} />

		<PrivateRoute roles={[ Role.Device, Role.Admin]} path={appRoutes.DeviceGetParameterValues} exact component={pages[AppRouteNames.DeviceGetParameterValues]} awsExports={contextAwsExports} />
	</div>
};

import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';

import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { DeviceExecuteCommandsProperties } from '../../../DeviceExecuteCommands';
import { devicesSelectors } from '../../../../../store/selectors';
import CircularProgress from '@mui/material/CircularProgress';
import { OptionalOperation } from '@indigo-cloud/common-react';


// TODO: Remove extraneous disable rules
/* eslint-disable react/no-unescaped-entities */

interface DeviceExecuteCommandsOperationSnackbarProperties extends Pick<DeviceExecuteCommandsProperties, 'agentEndpointId'> {

}

export const deviceExecuteCommandsOperationSnackbarDataSelectors = {
	executeCommandsOperationSnackbarAlertError: 'DeviceExecuteCommandsOperationSnackbar-snackbarAlertError',
	executeCommandsOperationSnackbarAlertLoading: 'DeviceExecuteCommandsOperationSnackbar-snackbarAlertLoading',
	executeCommandsOperationSnackbarAlertSuccess: 'DeviceExecuteCommandsOperationSnackbar-snackbarAlertSuccess',
	executeCommandsOperationSnackbarError: 'DeviceExecuteCommandsOperationSnackbar-snackbarError',

	executeCommandsOperationSnackbarSuccess: 'DeviceExecuteCommandsOperationSnackbar-snackbarSuccess'


};

export const DeviceExecuteCommandsOperationSnackbar: React.FC<DeviceExecuteCommandsOperationSnackbarProperties> = ({ agentEndpointId }) => {

	const executeDeviceCommandOperation = useSelector(devicesSelectors.executeDeviceCommandOperation(agentEndpointId)) as any;
	const executeDeviceCommandOperationResults = useSelector(devicesSelectors.executeDeviceCommandOperationResults(agentEndpointId)) as any;
	const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean | 'success' | 'error'>(false);

	const operationError = typeof executeDeviceCommandOperation?.error === 'string' ? executeDeviceCommandOperation.error : executeDeviceCommandOperation?.error?.message;
	const operationResponse = executeDeviceCommandOperationResults;

	const didOperationSucceed = executeDeviceCommandOperation?.didSucceed === true;
	const didOperationError = executeDeviceCommandOperation?.hasError === true;

	const operationRequestCommands = executeDeviceCommandOperation?.request?.command;

	const shouldOpenSnackbar = didOperationSucceed || didOperationError;

	useEffect(() => {
		if (shouldOpenSnackbar) {
			setShouldShowSnackbar(didOperationSucceed ? 'success' : 'error');
		}
	}, [
		shouldOpenSnackbar
	]);

	return (<>
		<Snackbar open={shouldShowSnackbar === 'error'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={deviceExecuteCommandsOperationSnackbarDataSelectors.executeCommandsOperationSnackbarError}>
			<Alert variant="filled" severity="error" onClose={() => setShouldShowSnackbar(false)} data-cy={deviceExecuteCommandsOperationSnackbarDataSelectors.executeCommandsOperationSnackbarAlertError}>
				An error occurred while executing command: {operationError}
			</Alert>
		</Snackbar>
		<Snackbar open={shouldShowSnackbar === 'success'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={deviceExecuteCommandsOperationSnackbarDataSelectors.executeCommandsOperationSnackbarSuccess}>
			<Alert variant="filled" severity="success" onClose={() => setShouldShowSnackbar(false)} data-cy={deviceExecuteCommandsOperationSnackbarDataSelectors.executeCommandsOperationSnackbarAlertSuccess}>
				1 command executed successfully.
			</Alert>
		</Snackbar>
	</>);
};
/* eslint-enable react/no-unescaped-entities */

import { call, delay, put, select, takeEvery } from '@redux-saga/core/effects';
import * as AWS from 'aws-sdk';
import { AxiosError } from 'axios';
import { DeleteParameterValuesResponse, Device, Job, awsExports } from '../../shared';
import { authActions, devicesActions } from '../actions';
import { ADD_DEVICE_PARAMETER_VALUES, DELETE_DEVICE_PARAMETER_VALUES, EXECUTE_DEVICE_COMMAND, FIND_DEVICE, GET_DEVICE_PARAMETER_VALUES, INIT_SPEED_TEST, LOAD_DEVICES, LOAD_DEVICE_GROUPS_FOR_DEVICE, LOAD_DEVICE_INFO, LOAD_DEVICE_JOBS, LOAD_DEVICE_NOTIFICATIONS, SET_DEVICE_PARAMETER_VALUES, TRIGGER_DEVICE_FIRMWARE_UPDATE, TRIGGER_SPEED_TEST } from '../constants';
// import { AWSAppSyncClient} from 'aws-appsync';
import { PaginatedResults, processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import ZenObservable from 'zen-observable-ts';

import { API, graphqlOperation } from '@aws-amplify/api';
import Auth from '@aws-amplify/auth';
import { triggerSpeedTest as triggerSpeedTestMutation } from '../../graphql/mutations';
import { exchangeTokens } from '../../graphql/queries';
import { onSpeedTestChanges } from '../../graphql/subscriptions';
import { devicesSelectors } from '../selectors';
import { store } from '../store';
import moment from 'moment';

const baseDevicesPath = '/v1/devices';
const baseAgentsPath = '/v1/agents'

function* findDevice(action: ReturnType<typeof devicesActions.findDevice> | ReturnType<typeof devicesActions.loadDeviceInfo>) {
	const { payload: { searchString } } = action;
	try {
		let requestUrl;
		if (searchString.startsWith('os::012345-:')) {
			requestUrl = `${baseAgentsPath}/${encodeURIComponent(searchString)}`;
		} else if (searchString.startsWith('+')) {
			const newstr = `os::012345-${searchString.replaceAll('+', ':')}`; 
			requestUrl = `${baseAgentsPath}/${encodeURIComponent(newstr)}`;
		} else {
			requestUrl = `${baseAgentsPath}/findByExternalId/${encodeURIComponent(searchString)}`;
		}
		
		let response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: requestUrl
			}
		);

		if (response.data) {
			response = response.data;
			const deviceDetail = {
				...response,
				lastStatusChange: moment(response.lastStatusChange, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA') + ' ' + moment(response.lastStatusChange).fromNow()
			};
			yield put(devicesActions.findDeviceSuccess({ data: deviceDetail }));
		}
		// Convert dates using moment before putting them in the store
		
	} catch (error) {
		console.error('Device not found', error);
		yield put(devicesActions.findDeviceError(error, searchString));
	}
}

function* loadDevicesCall(cursor: any): any {
	const response = yield call(
		request,
		{
			apiName: 'usp',
			awsExports,
			options: {
				queryStringParameters: {
					cursor: cursor? cursor : undefined,
					limit: 1000
				}
			},
			path: baseDevicesPath
		}
	);
	if (response?.pagination?.cursor) {
		return [...response.data, ...yield call(loadDevicesCall, response.pagination.cursor)];
	}
	return response.data;
}

function* loadDevices() {
	try {
		const response = yield call(loadDevicesCall, '');
		type DeviceType = {
			lastStatusChange: string;
		};

		const convertedDevices = response.map((device: DeviceType) => ({
			...device,
			lastStatusChange: moment(device.lastStatusChange, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA') + ' ' + moment(device.lastStatusChange).fromNow()
		}));
		yield put(devicesActions.loadDevicesSuccess({ data: convertedDevices }));
	} catch (error) {
		// if (error.response?.status === 401) {
		//			yield call(Auth.signOut);
		//		}
		console.error('An error occurred while loading the devices.', error);
		yield put(devicesActions.loadDevicesError(error));
	}
}

function* loadDeviceInfo(action: ReturnType<typeof devicesActions.loadDeviceInfo>) {
	const { payload: { searchString } } = action;
	try {
		let devicesState: Device = yield select(devicesSelectors.findDeviceData);
		if (!devicesState) {
			yield call(findDevice, action);
			yield delay(10);
			devicesState = yield select(devicesSelectors.findDeviceData);
		}
		yield put(devicesActions.loadDeviceInfoSuccess(searchString, devicesState));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while loading the device info with id '${searchString}'`, error);
		yield put(devicesActions.loadDeviceInfoError(searchString, error));
	}
}

function* loadDeviceGroupsForDevice(action: ReturnType<typeof devicesActions.loadDeviceGroupsForDevice>) {
	const { payload: { name } } = action;
	try {
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				options: {
					queryStringParameters: {}
				},
				path: `${baseAgentsPath}/${encodeURIComponent(name)}/deviceGroups`
			}
		);
		yield put(devicesActions.loadDeviceGroupsForDeviceSuccess(name, response));
	} catch (error) {
		console.error('An error occurred while loading the device groups for the device.', error);
		yield put(devicesActions.loadDeviceGroupsForDeviceError(error));
	}
}

function* loadJobs(action: ReturnType<typeof devicesActions['loadDeviceJobs']>) {
	const {
		payload: {filters, agentEndpointId, limit = 10, cursor, pageIndex = 0, collectAllData = false}
	} = action;
	try {
		console.log('filters', filters)

		const keysFilters = Object.keys(filters || {});
		let search = '';
		if (keysFilters?.length) {
			search = keysFilters.reduce((previous, keyFilter) => {
				const filter = filters![keyFilter] as string;
				previous += `&${encodeURIComponent(`search[${keyFilter}]`)}=${encodeURIComponent(filter)}`;

				return previous;
			}, '');

			console.log('search', search)
		}

		let requestUrl = `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/jobs?limit=${limit}${cursor?.length ? `&cursor=${cursor}` : ''}${Object.keys(filters || '').length > 0 ? search : ''}`
		let responses: Record<string, PaginatedResults<Job>> = {};
		let counterIndex = 0;
		while(requestUrl !== ''){
			const response = yield call(
				request,
				{
					apiName: 'usp',
					awsExports,
					path: requestUrl
				}
			);
			const isoFormat = 'YYYY-MM-DDTHH:mm:ss';
			if (Array.isArray(response.data)) {
				response.data.forEach((job: any) => {
					const startedAtDate = moment(job.startedAt, isoFormat).toDate();
					const updatedAtDate = moment(job.updatedAt, isoFormat).toDate();
					job.startedAt = moment(startedAtDate).format('DD/MM/YYYY HH:mm:ssA');
					job.updatedAt = moment(updatedAtDate).format('DD/MM/YYYY HH:mm:ssA');
				});
			}
			responses = {...responses, [`${pageIndex + counterIndex++}_${JSON.stringify(filters)}`]: response};
			if(!collectAllData) break;
			requestUrl = (response.pagination.cursor) ? `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/jobs?limit=${limit}&cursor=${response.pagination.next}` : '';
		}
		yield put(devicesActions.loadDeviceJobsSuccess(agentEndpointId, responses));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(devicesActions.loadDeviceJobsError(error));
	}
}

function* loadNotifications(action: ReturnType<typeof devicesActions['loadDeviceNotifications']>) {
	const {
		payload: {agentEndpointId, filters, nextToken, pageIndex = 0, collectAllData = false}
	} = action;
	try {
		console.log('filters', filters)

		const keysFilters = Object.keys(filters || {});
		let search = '';
		if (keysFilters?.length) {
			search = keysFilters.reduce((previous, keyFilter) => {
				const filter = filters![keyFilter] as string;
				previous += `&${encodeURIComponent(`search[${keyFilter}]`)}=${encodeURIComponent(filter)}`;

				return previous;
			}, '');

			console.log('search', search)
		}

		let requestUrl = `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/notifications?${nextToken?.length ? `&nextToken=${nextToken}` : ''}${Object.keys(filters || '').length > 0 ? search : ''}`
		let responses: Record<string, PaginatedResults<Notification>> = {};
		let counterIndex = 0;
		while(requestUrl !== ''){
			const response = yield call(
				request,
				{
					apiName: 'usp_reporting',
					awsExports,
					path: requestUrl
				}
			);
			type NotificationType = {
				time: string;
			};
			const updatedData = response.data.map((notification: NotificationType) => ({
				...notification,
				time: moment(notification.time, 'YYYY-MM-DDTHH:mm:ss').format('DD/MM/YYYY HH:mm:ssA') // Change the format as needed
			}));
			const updatedResponse = {
				...response,
				data: updatedData
			};
			responses = {...responses, [`${pageIndex + counterIndex++}_${JSON.stringify(filters)}`]: response};
			if(!collectAllData) break;
			requestUrl = (response.pagination.cursor !== '') ? `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/notifications?nextToken=${response.pagination.next}` : '';
		}
		yield put(devicesActions.loadDeviceNotificationsSuccess(agentEndpointId, responses));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(devicesActions.loadDeviceNotificationsError(error, agentEndpointId));
	}
}

function* getDeviceParameterValues(action: ReturnType<typeof devicesActions.getDeviceParameterValues>) {

	const { payload: { agentEndpointId, formikPromise, paramPaths } } = action;

	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				path: `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/serviceElements?${paramPaths.map((parameterPath, index) => `paramPath=${parameterPath}${(index !== paramPaths.length - 1) ? '&' : ''}`).join('')}`
			}
		);

		const { reqPathResults } = response;

		// reqPathResults can be undefined
		const requestPathResultsFailures = reqPathResults?.filter?.(({ errCode }: any) => !!errCode);

		if (requestPathResultsFailures?.length) {
			throw new Error(requestPathResultsFailures[0].errMsg);
		}
		yield put(devicesActions.getDeviceParameterValuesSuccess(reqPathResults, agentEndpointId));
		formikPromise.resolve(reqPathResults);

	}
	catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while setting the device parameter values with agent endpoint id '${agentEndpointId}'`, error);
		yield put(devicesActions.getDeviceParameterValuesError({
			message: error.message
		} as any, agentEndpointId));
		formikPromise.reject(error);
	}
}

function* setDeviceParameterValues(action: ReturnType<typeof devicesActions.setDeviceParameterValues>) {

	const { payload: { agentEndpointId, formikPromise, setParameterValues: { allowPartial, parameters  } } } = action;

	try {
		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'put',
				options: {
					body: {
						allowPartial,
						'updateObjs': parameters?.map(({ key, value, required }) => {
							const parameterPath = key?.split('.');
							const parameter = parameterPath.pop();
							return {
								'objPath': `${parameterPath.join('.')}.`,
								'paramSettings': [{
									param: parameter,
									required,
									value
								}]
							}
						})
					}
				},
				path: `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/serviceElements`
			}
		);

		yield put(devicesActions.setDeviceParameterValuesSuccess(parameters.map((parameterPath: { key: string, value: string }) => {
			return {
				requestedPath: parameterPath.key,
				resolvedPathResults: [
					{
						resolvedPath: parameterPath.key,
						resultParams: parameterPath.value
					}
				]
			}
		}), agentEndpointId));

		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while setting the device parameter values with agent endpoint id '${agentEndpointId}'`, error);
		yield put(devicesActions.setDeviceParameterValuesError({
			message: error.message
		} as any, agentEndpointId));
		formikPromise.reject(error);
	}
}

function* addDeviceParameterValues(action: ReturnType<typeof devicesActions.addDeviceParameterValues>) {

	const { payload: { agentEndpointId, formikPromise, addParameterValues: { allowPartial, objPath, parameters  } } } = action;
	
	const modifiedParameter: any = {};
	parameters.forEach((parameter: { param: string; required: any; value: any; })=>{
		(modifiedParameter[objPath]) ? modifiedParameter[objPath].push(parameter) : modifiedParameter[objPath] = [parameter];
	});
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'post',
				options: {
					body: {

						allowPartial,
						createObjs: Object.keys(modifiedParameter)?.map((index) => {
							return {
								'objPath': index,
								'paramSettings': modifiedParameter[index]
							}
						})
					}
				},
				path: `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/serviceElements`
			}
		);

		yield put(devicesActions.addDeviceParameterValuesSuccess(parameters.map((parameterPath: { param: string, value: string }) => {
			return {
				requestedPath: parameterPath.param,
				resolvedPathResults: [
					{
						resolvedPath: parameterPath.param,
						resultParams: parameterPath.value
					}
				]
			}
		}), agentEndpointId));

		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while adding the device parameter values with agent endpoint id '${agentEndpointId}'`, error);
		yield put(devicesActions.addDeviceParameterValuesError({
			message: error.message
		} as any, agentEndpointId));
		formikPromise.reject(error);
	}
}

function* deleteDeviceParameterValues(action: ReturnType<typeof devicesActions.deleteDeviceParameterValues>) {

	const { payload: { agentEndpointId, formikPromise, deleteParameterValues: { allowPartial, objPaths  } } } = action;

	try {

		const response: DeleteParameterValuesResponse = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'del',

				options: {
					body: {
						allowPartial,
						objPaths
					}
				},
				path: `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/serviceElements`
			}
		);

		yield put(devicesActions.deleteDeviceParameterValuesSuccess(response, agentEndpointId));

		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while deleting the device parameter values with agent endpoint id '${agentEndpointId}'`, error);
		yield put(devicesActions.deleteDeviceParameterValuesError({
			message: error.message
		} as any, agentEndpointId));
		formikPromise.reject(error);
	}
}

function* executeDeviceCommand(action: ReturnType<typeof devicesActions.executeDeviceCommand>) {

	const { payload: { agentEndpointId, formikPromise, command } } = action;

	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'post',
				options: {
					body: command
				},
				path: `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/commands`
			}
		);

		yield put(devicesActions.executeDeviceCommandSuccess(response, agentEndpointId));

		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while executing the device command with agent endpoint id '${agentEndpointId}'`, error);
		yield put(devicesActions.executeDeviceCommandError({
			message: error.message
		} as any, agentEndpointId));
		formikPromise.reject(error);
	}
}

const whitelistedUsers = {
	dev: [
		'gemma.pinney',
		'luigino.soave',
		'alex.hopkins',
		'shaun.porter',
		'robert.glassford',
		'mohammed.chhatriwala',
		'yasin.ozer',
		'malan.kotyalkar',
		'david.farinha',
		'akash.mishra',
		'ruslan.i.kovtun'
	],
	prd: [
		'gemma.pinney',
		'luigino.soave',
		'alex.hopkins',
		'shaun.porter',
		'robert.glassford',
		'mohammed.chhatriwala',
		'yasin.ozer',
		'david.farinha'
	]
};

export const checkTriggerFirmwareUpdateAuthorisation = () => {
	if (process.env.REACT_APP_API_NAME === 'dev' || process.env.REACT_APP_API_NAME === 'prd') {
		const currentSession = JSON.parse(localStorage.getItem('aws-amplify-federatedInfo') || '{}');

		const username = currentSession?.user?.username;

		if (username) {
			const whitelistMatchIndex = whitelistedUsers[process.env.REACT_APP_API_NAME].indexOf(username.replace('Crowd_', ''));

			if (whitelistMatchIndex === -1) {
				throw new Error('User is unauthorised to perform a trigger update')
			}
		}
	}
}

function* triggerDeviceFirmwareUpdate(action: ReturnType<typeof devicesActions.triggerDeviceFirmwareUpdate>) {

	const { payload: { agentEndpointId, firmwareName, formikPromise, activationWindows, shouldForceDowngrade } } = action;

	try {

		const response = yield call(
			request,
			{
				apiName: 'rom',
				awsExports,
				method: 'post',
				options: {
					body: {
						firmwareName,
						forceDowngrade: shouldForceDowngrade,
						activationWindow: activationWindows
					}
				},
				path: `${baseAgentsPath}/${encodeURIComponent(agentEndpointId)}/triggerFirmwareUpdate`
			}
		);

		yield put(devicesActions.triggerDeviceFirmwareUpdateSuccess(response, agentEndpointId));

		formikPromise.resolve(response);
	} catch (error) {
		const errorAxios = (error as AxiosError);
		let errorMessage = '';
		
		errorMessage = errorAxios.isAxiosError ? (typeof errorAxios?.response?.data === 'string' ? errorAxios?.response?.data :  errorAxios?.response?.data.message) : error?.message;
		
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while triggering the firmware update for the device with agent endpoint id '${agentEndpointId}'`, errorMessage);
		
		yield put(devicesActions.triggerDeviceFirmwareUpdateError({
			message: errorMessage
		} as any, agentEndpointId));
		formikPromise.reject(error);
	}
}

let subscription: ZenObservable.Subscription | undefined = undefined;

const onTriggerSpeedTestError = (agentEndpointId: string, error: Error) => {
	if (subscription) {
		subscription.unsubscribe();
	}

	store.dispatch(devicesActions.triggerSpeedTestError(error, agentEndpointId))
}

const assumedRole = false;

function* triggerSpeedTest(action: ReturnType<typeof devicesActions.triggerSpeedTest>) {

	const {agentEndpointId, type, line, formikPromise } = action.payload;
	try {

		const result = yield select(devicesSelectors.initSpeedTestOperationResults(agentEndpointId));

		const {
			accessKeyId,
			secretAccessKey,
			sessionToken
		} = yield call(Auth.currentCredentials.bind(Auth));

		/* eslint-disable-next-line import/namespace */
		const sts = new AWS.STS({
			credentials: new AWS.Credentials(accessKeyId, secretAccessKey, sessionToken)
		});

		const assumeXRole = async () => {
			try {
				const callerIdentity = await sts.getCallerIdentity({}).promise();

				console.log('[callerIdentity]', {callerIdentity}, process.env.REACT_APP_APPSYNC_SPEEDTEST_IAM_ROLE_CROSS_ACCOUNT)
				// Don't assume the role twice. Only assume if we're on cognito identity pool credential
				if (callerIdentity.UserId!.includes('CognitoIdentityCredentials')) {
					const result = await sts.assumeRole({
						// RoleArn: 'arn:aws:iam::079439661102:role/crossacc-test',
						RoleArn: process.env.REACT_APP_APPSYNC_SPEEDTEST_IAM_ROLE_CROSS_ACCOUNT!,
						RoleSessionName: Date.now().toString()
					}).promise();

					return result;
				}
			}
			catch (error) {
				console.error('An error occurred while assuming the role', error);
			}

			return undefined;
		}

		const role = yield call(assumeXRole);

		if (role) {
			API.configure({
				...awsExports,
				'aws_appsync_authenticationType': 'AWS_IAM'
			});

			API['_graphqlApi']['Credentials'].get = async () => {

				const credentials = new AWS.Credentials(role.Credentials.AccessKeyId, role.Credentials.SecretAccessKey, role.Credentials.SessionToken)

				return credentials;
			}
		}

		// AWS.config.update(
		// 	{
		// 		credentials: new AWS.Credentials(role.Credentials.AccessKeyId, role.Credentials.SecretAccessKey, role.Credentials.SessionToken),
		// 		 accessKeyId: role.Credentials.AccessKeyId,
		// 		 secretAccessKey: role.Credentials.SecretAccessKey,
		// 		 sessionToken: role.Credentials.SessionToken,
		// 		 region: 'eu-west-1'
		// 	}
	  // );

	  yield delay(1000);

		const observable = yield call(API.graphql.bind(API), graphqlOperation(onSpeedTestChanges, {
			line
			// authMode: 'AWS_IAM'
		}));

		subscription = observable!.subscribe({

			complete: () => {
				console.error('Speed test subscription completed');
			},

			error: (error: any) => {
				console.error('Speed test subscription error', error);
				onTriggerSpeedTestError(agentEndpointId, error);
			},
			next: async (data: any) => {
				try {
					const response = data?.value?.data?.onSpeedTestChanges;
					const currentStatus = response?.status;

					if (currentStatus === 'ERROR') {
						onTriggerSpeedTestError(agentEndpointId, (response.error || response.hubError));
					} else if (currentStatus !== undefined) {
						store.dispatch(devicesActions.triggerSpeedTestStatusUpdatedSuccess(agentEndpointId, response))

					}
				}
				catch (error) {
					console.error('Speed test subscription next threw an error', error);
					onTriggerSpeedTestError(agentEndpointId, error);
				}

			},
			start: () => {
				console.error('Speed test subscription started');
			}
		});

		// Store the reference to the eubscription immediately
		yield put(devicesActions.triggerSpeedTestSuccess(agentEndpointId, subscription!));

		// We don't need anything from the response, all status updates are handled in the subscirption
		const {
			data: {
				triggerSpeedTest: response
			}
		} = yield call(API.graphql.bind(API), graphqlOperation(
			triggerSpeedTestMutation,
			{
				authMode: 'AWS_IAM',

				input: {
					line,
					platform: 'JSClient',
					trigger: 'GRAPH_QL_API',
					type: type === 'TCP' ? 'TCPSpeedTestMT' : 'UDPSpeedTest'
				}
			}));

		formikPromise.resolve(response);
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while starting the speed test with agent endpoint id '${agentEndpointId}'`, error);
		onTriggerSpeedTestError(agentEndpointId, error);
		formikPromise.reject(error);
	}
}

function* initSpeedTest(action: ReturnType<typeof devicesActions.initSpeedTest>) {

	const { payload: { agentEndpointId, line } } = action;

	try {

		API.configure(awsExports);

		const {
			data
		} = yield call(API.graphql.bind(API), graphqlOperation(
			exchangeTokens,
			{
				input: {
					authToken: awsExports.aws_appsync_speedTest_authToken,
					client: 'Resolve',
					line,
					mobileServiceHeader: {
						ADN: 'ccp64',
						appName: 'ResolveConsumer',

						requestType: 'Sync',
						timestamp: Date.now().toString()
					}
				}
			}));
		yield put(devicesActions.initSpeedTestSuccess(agentEndpointId, data?.exchangeTokens));

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while starting the speed test with agent endpoint id '${agentEndpointId}'`, error);
		yield put(devicesActions.initSpeedTestError({
			message: error.message
		} as any, agentEndpointId));
	}
}

export const devicesSagas = () => {

	function* watcher() {
		yield takeEvery(LOAD_DEVICES, loadDevices);
		yield takeEvery(LOAD_DEVICE_INFO, loadDeviceInfo);
		yield takeEvery(LOAD_DEVICE_GROUPS_FOR_DEVICE, loadDeviceGroupsForDevice);
		yield takeEvery(GET_DEVICE_PARAMETER_VALUES, getDeviceParameterValues);
		yield takeEvery(SET_DEVICE_PARAMETER_VALUES, setDeviceParameterValues);
		yield takeEvery(ADD_DEVICE_PARAMETER_VALUES, addDeviceParameterValues);
		yield takeEvery(DELETE_DEVICE_PARAMETER_VALUES, deleteDeviceParameterValues);
		yield takeEvery(EXECUTE_DEVICE_COMMAND, executeDeviceCommand);
		yield takeEvery(INIT_SPEED_TEST, initSpeedTest);
		yield takeEvery(TRIGGER_SPEED_TEST, triggerSpeedTest);
		yield takeEvery(TRIGGER_DEVICE_FIRMWARE_UPDATE, triggerDeviceFirmwareUpdate);
		yield takeEvery(LOAD_DEVICE_JOBS, loadJobs);
		yield takeEvery(LOAD_DEVICE_NOTIFICATIONS, loadNotifications);
		yield takeEvery(FIND_DEVICE, findDevice);
	}

	return {
		loadDevices,
		watcher
	};
};

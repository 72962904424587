/**
 * TRIGGER_DEVICE_FIRMWARE_UPDATE
 */
export const TRIGGER_DEVICE_FIRMWARE_UPDATE = 'DEVICES.TRIGGER_DEVICE_FIRMWARE_UPDATE';
export const TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS = 'DEVICES.TRIGGER_DEVICE_FIRMWARE_UPDATE_SUCCESS';
export const TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR = 'DEVICES.TRIGGER_DEVICE_FIRMWARE_UPDATE_ERROR';

/**
 * LOAD_DEVICES
 */
export const LOAD_DEVICES = 'DEVICES.LOAD_DEVICES';
export const LOAD_DEVICES_SUCCESS = 'DEVICES.LOAD_DEVICES_SUCCESS';
export const LOAD_DEVICES_ERROR = 'DEVICES.LOAD_DEVICES_ERROR';

/**
 * FIND_DEVICE
 */
export const FIND_DEVICE = 'DEVICES.FIND_DEVICE';
export const FIND_DEVICE_SUCCESS = 'DEVICES.FIND_DEVICE_SUCCESS';
export const FIND_DEVICE_ERROR = 'DEVICES.FIND_DEVICE_ERROR';
export const FIND_DEVICE_CLEAR = 'DEVICES.FIND_DEVICE_CLEAR';


/**
 * LOAD_DEVICE_INFO
 */
export const LOAD_DEVICE_INFO = 'DEVICES.LOAD_DEVICE_INFO';
export const LOAD_DEVICE_INFO_SUCCESS = 'DEVICES.LOAD_DEVICE_INFO_SUCCESS';
export const LOAD_DEVICE_INFO_ERROR = 'DEVICES.LOAD_DEVICE_INFO_ERROR';

/**
 * GET_DEVICE_PARAMETER_VALUES
 */
export const GET_DEVICE_PARAMETER_VALUES = 'DEVICES.GET_DEVICE_PARAMETER_VALUES';
export const GET_DEVICE_PARAMETER_VALUES_SUCCESS = 'DEVICES.GET_DEVICE_PARAMETER_VALUES_SUCCESS';
export const GET_DEVICE_PARAMETER_VALUES_ERROR = 'DEVICES.GET_DEVICE_PARAMETER_VALUES_ERROR';

/**
 * ADD_DEVICE_PARAMETER_VALUES
 */
export const ADD_DEVICE_PARAMETER_VALUES = 'DEVICES.ADD_DEVICE_PARAMETER_VALUES';
export const ADD_DEVICE_PARAMETER_VALUES_SUCCESS = 'DEVICES.ADD_DEVICE_PARAMETER_VALUES_SUCCESS';
export const ADD_DEVICE_PARAMETER_VALUES_ERROR = 'DEVICES.ADD_DEVICE_PARAMETER_VALUES_ERROR';
/**
 * DELETE_DEVICE_PARAMETER_VALUES
 */
export const DELETE_DEVICE_PARAMETER_VALUES = 'DEVICES.DELETE_DEVICE_PARAMETER_VALUES';
export const DELETE_DEVICE_PARAMETER_VALUES_SUCCESS = 'DEVICES.DELETE_DEVICE_PARAMETER_VALUES_SUCCESS';
export const DELETE_DEVICE_PARAMETER_VALUES_ERROR = 'DEVICES.DELETE_DEVICE_PARAMETER_VALUES_ERROR';

/**
 * SET_DEVICE_PARAMETER_VALUES
 */
export const SET_DEVICE_PARAMETER_VALUES = 'DEVICES.SET_DEVICE_PARAMETER_VALUES';
export const SET_DEVICE_PARAMETER_VALUES_SUCCESS = 'DEVICES.SET_DEVICE_PARAMETER_VALUES_SUCCESS';
export const SET_DEVICE_PARAMETER_VALUES_ERROR = 'DEVICES.SET_DEVICE_PARAMETER_VALUES_ERROR';

/**
 * EXECUTE_DEVICE_COMMAND
 */
export const EXECUTE_DEVICE_COMMAND = 'DEVICES.EXECUTE_DEVICE_COMMAND';
export const EXECUTE_DEVICE_COMMAND_SUCCESS = 'DEVICES.EXECUTE_DEVICE_COMMAND_SUCCESS';
export const EXECUTE_DEVICE_COMMAND_ERROR = 'DEVICES.EXECUTE_DEVICE_COMMAND_ERROR';

/**
 * INIT_SPEED_TEST
 */
export const INIT_SPEED_TEST = 'DEVICES.INIT_SPEED_TEST';
export const INIT_SPEED_TEST_SUCCESS = 'DEVICES.INIT_SPEED_TEST_SUCCESS';
export const INIT_SPEED_TEST_ERROR = 'DEVICES.INIT_SPEED_TEST_ERROR';
export const INIT_SPEED_TEST_CLEAR = 'DEVICES.INIT_SPEED_TEST_CLEAR';

/**
 * TRIGGER_SPEED_TEST
 */
export const TRIGGER_SPEED_TEST = 'DEVICES.TRIGGER_SPEED_TEST';
export const TRIGGER_SPEED_TEST_SUCCESS = 'DEVICES.TRIGGER_SPEED_TEST_SUCCESS';
export const TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS = 'DEVICES.TRIGGER_SPEED_TEST_STATUS_UPDATED_SUCCESS';
export const TRIGGER_SPEED_TEST_ERROR = 'DEVICES.TRIGGER_SPEED_TEST_ERROR';


/**
 * LOAD_DEVICE_JOBS
 */
export const LOAD_DEVICE_JOBS = 'DEVICES.LOAD_DEVICE_JOBS';
export const LOAD_DEVICE_JOBS_SUCCESS = 'DEVICES.LOAD_DEVICE_JOBS_SUCCESS';
export const LOAD_DEVICE_JOBS_ERROR = 'DEVICES.LOAD_DEVICE_JOBS_ERROR';
export const LOAD_DEVICE_JOBS_CLEAR = 'DEVICES.LOAD_DEVICE_JOBS_CLEAR';


/**
 * LOAD_DEVICE_NOTIFICATIONS
 */
export const LOAD_DEVICE_NOTIFICATIONS = 'DEVICES.LOAD_DEVICE_NOTIFICATIONS';
export const LOAD_DEVICE_NOTIFICATIONS_SUCCESS = 'DEVICES.LOAD_DEVICE_NOTIFICATIONS_SUCCESS';
export const LOAD_DEVICE_NOTIFICATIONS_ERROR = 'DEVICES.LOAD_DEVICE_NOTIFICATIONS_ERROR';
export const LOAD_DEVICE_NOTIFICATIONS_CLEAR = 'DEVICES.LOAD_DEVICE_NOTIFICATIONS_CLEAR';


/**
 * LOAD_DEVICE_GROUPS
 */
export const LOAD_DEVICE_GROUPS_FOR_DEVICE = 'DEVICES.LOAD_DEVICE_GROUPS_FOR_DEVICE';
export const LOAD_DEVICE_GROUPS_FOR_DEVICE_SUCCESS = 'DEVICES.LOAD_DEVICE_GROUPS_FOR_DEVICE_SUCCESS';
export const LOAD_DEVICE_GROUPS_FOR_DEVICE_ERROR = 'DEVICES.LOAD_DEVICE_GROUPS_FOR_DEVICE_ERROR';
import {ActionType, PayloadAction} from 'typesafe-actions';

import { TOGGLE_MENU } from '../../constants';
import { createAction } from '@indigo-cloud/common-react';


export const menuActions = {
	/**
	 * TOGGLE_MENU
	 */
	toggleMenu: (isOpen: boolean): PayloadAction<typeof TOGGLE_MENU, {isOpen: typeof isOpen}> =>
		createAction(TOGGLE_MENU, {isOpen})
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type menuActions = ActionType<typeof menuActions>;

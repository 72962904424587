/* eslint sort-keys: 0 */
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import { FormikFormSubmitPromise, PaginatedResults, createAction } from '@indigo-cloud/common-react';
import { DeviceCommand, DeviceGroup, DeviceGroupActivationWindow, DeviceGroupConfigurationInputFormValues, DeviceGroupTriggerFirmwareUpdateResponse, DeviceParameterValues, DevicesResponse, Job, ScheduleConfig, Thing } from '../../../shared';
import {
	ADD_DYNAMIC_DEVICE_GROUP,
	ADD_DYNAMIC_DEVICE_GROUP_ERROR,
	ADD_DYNAMIC_DEVICE_GROUP_SUCCESS,
	DELETE_DEVICE_GROUP,
	DELETE_DEVICE_GROUP_ERROR,
	DELETE_DEVICE_GROUP_SUCCESS,
	EXECUTE_DEVICE_GROUP_COMMAND,
	EXECUTE_DEVICE_GROUP_COMMAND_ERROR,
	EXECUTE_DEVICE_GROUP_COMMAND_SUCCESS,
	LOAD_DEVICE_GROUPS,
	LOAD_DEVICE_GROUPS_ERROR,
	LOAD_DEVICE_GROUPS_INFO,
	LOAD_DEVICE_GROUPS_SUCCESS,
	LOAD_DEVICE_GROUP_DEVICES,
	LOAD_DEVICE_GROUP_DEVICES_CLEAR,
	LOAD_DEVICE_GROUP_DEVICES_ERROR,
	LOAD_DEVICE_GROUP_DEVICES_SUCCESS,
	LOAD_DEVICE_GROUP_INFO_ERROR,
	LOAD_DEVICE_GROUP_INFO_SUCCESS,
	LOAD_DEVICE_GROUP_JOBS,
	LOAD_DEVICE_GROUP_JOBS_CLEAR,
	LOAD_DEVICE_GROUP_JOBS_ERROR,
	LOAD_DEVICE_GROUP_JOBS_SUCCESS,
	SET_DEVICE_GROUP_PARAMETERS,
	SET_DEVICE_GROUP_PARAMETERS_ERROR,
	SET_DEVICE_GROUP_PARAMETERS_SUCCESS,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR,
	TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR,
	TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR,
	TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS,
	UPDATE_DYNAMIC_DEVICE_GROUP,
	UPDATE_DYNAMIC_DEVICE_GROUP_ERROR,
	UPDATE_DYNAMIC_DEVICE_GROUP_SUCCESS
} from '../../constants';
import { DeviceGroupSetParameterValuesInputModalFormValues } from '../../../pages/DeviceGroupSetParameterValues/components';

export const deviceGroupsActions = {
	addDynamicGroup: (model: DeviceGroup, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof ADD_DYNAMIC_DEVICE_GROUP, { formikPromise: typeof formikPromise; model: typeof model;  }> => createAction(ADD_DYNAMIC_DEVICE_GROUP, { formikPromise, model  }),
	addDynamicGroupError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof ADD_DYNAMIC_DEVICE_GROUP_ERROR, {  error: ErrorType }> =>
	   createAction(ADD_DYNAMIC_DEVICE_GROUP_ERROR, {error}),
	 addDynamicGroupSuccess:  (
	   response: DeviceGroup
	): PayloadAction<typeof ADD_DYNAMIC_DEVICE_GROUP_SUCCESS, { response: typeof response }> =>
	   createAction(ADD_DYNAMIC_DEVICE_GROUP_SUCCESS, { response }),

	updateDynamicGroup: (model: DeviceGroup, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof UPDATE_DYNAMIC_DEVICE_GROUP, { formikPromise: typeof formikPromise; model: typeof model;  }> => createAction(UPDATE_DYNAMIC_DEVICE_GROUP, { formikPromise, model  }),
	updateDynamicGroupError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof UPDATE_DYNAMIC_DEVICE_GROUP_ERROR, {  error: ErrorType }> =>
		createAction(UPDATE_DYNAMIC_DEVICE_GROUP_ERROR, {error}),
	updateDynamicGroupSuccess:  (
		response: DeviceGroup
	): PayloadAction<typeof UPDATE_DYNAMIC_DEVICE_GROUP_SUCCESS, { response: typeof response }> =>
		createAction(UPDATE_DYNAMIC_DEVICE_GROUP_SUCCESS, { response }),
	/**
	/** Groups  */
	loadDeviceGroups: (): Action<typeof LOAD_DEVICE_GROUPS> => createAction(LOAD_DEVICE_GROUPS),
	loadDeviceGroupsError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof LOAD_DEVICE_GROUPS_ERROR, {error: ErrorType}> =>
		createAction(LOAD_DEVICE_GROUPS_ERROR, {error}),
	loadDeviceGroupsSuccess:  (
		response: DevicesResponse
	): PayloadAction<typeof LOAD_DEVICE_GROUPS_SUCCESS, DevicesResponse> =>
		createAction(LOAD_DEVICE_GROUPS_SUCCESS, response),

	/** Devices  */
	loadDeviceGroupDevices: ({name, limit, cursor, pageIndex, pageIndexPrefix, collectAllData}: {name: string; pageIndexPrefix?: string, limit?: number, cursor?: string, pageIndex?: number, collectAllData?: boolean}): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES, { name: typeof name; cursor: typeof cursor, pageIndexPrefix: typeof pageIndexPrefix, limit: typeof limit, pageIndex: typeof pageIndex, collectAllData: typeof collectAllData }> => createAction(LOAD_DEVICE_GROUP_DEVICES, {
		name,
		limit,
		cursor,
		pageIndex,
		pageIndexPrefix,
		collectAllData
	}),
	loadDeviceGroupDevicesClear: (name: string): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES_CLEAR, {name: typeof name}> => createAction(LOAD_DEVICE_GROUP_DEVICES_CLEAR, {name}),
	loadDeviceGroupDevicesError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES_ERROR, {name: typeof deviceGroup, error: ErrorType}> =>
		createAction(LOAD_DEVICE_GROUP_DEVICES_ERROR, { name: deviceGroup, error }),
	loadDeviceGroupDevicesSuccess: (
		response: Record<string, PaginatedResults<Thing>>,
		name: string
	): PayloadAction<typeof LOAD_DEVICE_GROUP_DEVICES_SUCCESS, { name: typeof name, response: typeof response; }> =>
		createAction(LOAD_DEVICE_GROUP_DEVICES_SUCCESS, { name, response }),

	/** Info  */
	loadDeviceGroupInfo: (name: string): PayloadAction<typeof LOAD_DEVICE_GROUPS_INFO, { name: typeof name }> => createAction(LOAD_DEVICE_GROUPS_INFO, {
		name
	}),
	loadDeviceGroupInfoError: <ErrorType extends Error>(id: string, error: ErrorType): PayloadAction<typeof LOAD_DEVICE_GROUP_INFO_ERROR, {id: typeof id, error: ErrorType}> =>
		createAction(LOAD_DEVICE_GROUP_INFO_ERROR, {error, id: typeof id}),
	loadDeviceGroupInfoSuccess: (
		id: string,
		response: DeviceGroup
	): PayloadAction<typeof LOAD_DEVICE_GROUP_INFO_SUCCESS, { id: string, response: DeviceGroup }> =>
		createAction(LOAD_DEVICE_GROUP_INFO_SUCCESS, { id, response }),

	/**
	 * DELETE DEVICE GROUP
	 */
	 deleteDeviceGroup: (deviceGroupId: string): PayloadAction<typeof DELETE_DEVICE_GROUP, { deviceGroupId: typeof deviceGroupId }> => createAction(DELETE_DEVICE_GROUP, { deviceGroupId }),
	 deleteDeviceGroupError: (deviceGroupId: string, error: Error): PayloadAction<typeof DELETE_DEVICE_GROUP_ERROR, { deviceGroupId: typeof deviceGroupId; error: typeof error;  }> =>
		 createAction(DELETE_DEVICE_GROUP_ERROR, { error, deviceGroupId }),
	 deleteDeviceGroupSuccess: (deviceGroupId: string, response: any): PayloadAction<typeof DELETE_DEVICE_GROUP_SUCCESS, { deviceGroupId: typeof deviceGroupId;  response: typeof response }> =>
		 createAction(DELETE_DEVICE_GROUP_SUCCESS, {  response, deviceGroupId }),
		
	/**
	 * EXECUTE_DEVICE_COMMAND
	 */
	executeDeviceGroupCommand: (deviceGroupId: string, command: DeviceCommand, activationWindows: undefined | (DeviceGroupActivationWindow | Record<string, unknown>)[], abortConfig: DeviceGroupConfigurationInputFormValues['abortConfig'], executionsRolloutConfig: DeviceGroupConfigurationInputFormValues['executionsRolloutConfig'], schedulingConfig: undefined | ScheduleConfig, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof EXECUTE_DEVICE_GROUP_COMMAND, { deviceGroupId: typeof deviceGroupId, command: typeof command; activationWindows: typeof activationWindows; abortConfig: typeof abortConfig; executionsRolloutConfig: typeof executionsRolloutConfig; schedulingConfig: typeof schedulingConfig; formikPromise: typeof formikPromise,   }> => createAction(EXECUTE_DEVICE_GROUP_COMMAND, { deviceGroupId, command, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig, formikPromise }),
	executeDeviceGroupCommandError: <ErrorType extends Error>(error: ErrorType, deviceGroupId: string): PayloadAction<typeof EXECUTE_DEVICE_GROUP_COMMAND_ERROR, { deviceGroupId: typeof deviceGroupId; error: ErrorType, }> =>
		createAction(EXECUTE_DEVICE_GROUP_COMMAND_ERROR, {deviceGroupId: deviceGroupId, error}),
	executeDeviceGroupCommandSuccess:  (
		response: DeviceParameterValues,
		deviceGroupId: string
	): PayloadAction<typeof EXECUTE_DEVICE_GROUP_COMMAND_SUCCESS, { deviceGroupId: typeof deviceGroupId, response: typeof response }> =>
	   createAction(EXECUTE_DEVICE_GROUP_COMMAND_SUCCESS, { deviceGroupId: deviceGroupId, response }),

	/**
	 * SET DEVICE GROUP PARAMETER VALUES
	 */
	setDeviceGroupParameters: (deviceGroupId: string, setParameterValues: DeviceGroupSetParameterValuesInputModalFormValues, activationWindows: undefined | (DeviceGroupActivationWindow | Record<string, unknown>)[], abortConfig: DeviceGroupConfigurationInputFormValues['abortConfig'], executionsRolloutConfig: DeviceGroupConfigurationInputFormValues['executionsRolloutConfig'], schedulingConfig: undefined | ScheduleConfig, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof SET_DEVICE_GROUP_PARAMETERS, { deviceGroupId: typeof deviceGroupId, setParameterValues: typeof setParameterValues; activationWindows: typeof activationWindows; abortConfig: typeof abortConfig; executionsRolloutConfig: typeof executionsRolloutConfig; schedulingConfig: typeof schedulingConfig; formikPromise: typeof formikPromise,   }> => createAction(SET_DEVICE_GROUP_PARAMETERS, { deviceGroupId, setParameterValues, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig, formikPromise }),
	setDeviceGroupParametersError: <ErrorType extends Error>(error: ErrorType, deviceGroupId: string): PayloadAction<typeof SET_DEVICE_GROUP_PARAMETERS_ERROR, { deviceGroupId: typeof deviceGroupId; error: ErrorType, }> =>
		createAction(SET_DEVICE_GROUP_PARAMETERS_ERROR, {deviceGroupId: deviceGroupId, error}),
	setDeviceGroupParametersSuccess:  (
		response: DeviceParameterValues,
		deviceGroupId: string
	): PayloadAction<typeof SET_DEVICE_GROUP_PARAMETERS_SUCCESS, { deviceGroupId: typeof deviceGroupId, response: typeof response }> =>
	   createAction(SET_DEVICE_GROUP_PARAMETERS_SUCCESS, { deviceGroupId: deviceGroupId, response }),

	/**
	 * LIST JOBS
	 */
	loadDeviceGroupJobs: ({limit, cursor, pageIndex, name, filters, collectAllData}: {filters?: Record<string, unknown>, name: string, limit?: number, cursor?: string, pageIndex?: number, collectAllData?: boolean}): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS, {filters: typeof filters, limit?: number, name: typeof name, cursor?: string, pageIndex?: number, collectAllData: typeof collectAllData}> => createAction(LOAD_DEVICE_GROUP_JOBS, { cursor, filters, limit, name, pageIndex, collectAllData}),
	loadDeviceGroupJobsClear: (): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS_CLEAR, any> => createAction(LOAD_DEVICE_GROUP_JOBS_CLEAR, {}),
	loadDeviceGroupJobsError: (error: Error): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS_ERROR, { error: typeof error }> =>
		createAction(LOAD_DEVICE_GROUP_JOBS_ERROR, { error }),
	loadDeviceGroupJobsSuccess: (name: string, result: Record<string, PaginatedResults<Job>>): PayloadAction<typeof LOAD_DEVICE_GROUP_JOBS_SUCCESS, { name:string, result: typeof result}> =>
		createAction(LOAD_DEVICE_GROUP_JOBS_SUCCESS, { name, result }),
	
	/**
	 * Firmware update
	 */
	triggerDeviceGroupFirmwareUpdate: (deviceGroup: string, firmwareName: string, shouldForceDowngrade: boolean, activationWindows: undefined | (DeviceGroupActivationWindow | Record<string, unknown>)[], abortConfig: DeviceGroupConfigurationInputFormValues['abortConfig'], executionsRolloutConfig: DeviceGroupConfigurationInputFormValues['executionsRolloutConfig'], schedulingConfig: undefined | ScheduleConfig, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE, { deviceGroup: typeof deviceGroup, firmwareName: typeof firmwareName, formikPromise: typeof formikPromise,  shouldForceDowngrade: typeof shouldForceDowngrade, activationWindows: typeof activationWindows, abortConfig: typeof abortConfig, executionsRolloutConfig: typeof executionsRolloutConfig, schedulingConfig: typeof schedulingConfig }> => createAction(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE, { deviceGroup, firmwareName, formikPromise, shouldForceDowngrade, activationWindows, abortConfig, executionsRolloutConfig, schedulingConfig }),
	triggerDeviceGroupFirmwareUpdateError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR, { deviceGroup: typeof deviceGroup; error: ErrorType, }> =>
		createAction(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_ERROR, {deviceGroup, error}),
	triggerDeviceGroupFirmwareUpdateSuccess:  (
		response: DeviceGroupTriggerFirmwareUpdateResponse,
		deviceGroup: string
	): PayloadAction<typeof TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS, { deviceGroup: typeof deviceGroup, response: typeof response }> =>
	   createAction(TRIGGER_DEVICE_GROUP_FIRMWARE_UPDATE_SUCCESS, { deviceGroup, response }),

	/**
	 * add devices to the group
	 */
	triggerDeviceGroupAddDevices: (deviceGroup: string, devices: {thingNames: string[]}, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_GROUP_ADD_DEVICES, {deviceGroup: typeof deviceGroup, devices: typeof devices, formikPromise: typeof formikPromise }> => createAction(TRIGGER_DEVICE_GROUP_ADD_DEVICES, { deviceGroup, devices, formikPromise }),
	triggerDeviceGroupAddDevicesError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR, { deviceGroup: typeof deviceGroup; error: ErrorType, }> =>
		createAction(TRIGGER_DEVICE_GROUP_ADD_DEVICES_ERROR, {deviceGroup, error}),
	triggerDeviceGroupAddDevicesSuccess:  (
		response: Record<string, unknown>,
		deviceGroup: string
	): PayloadAction<typeof TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS, { response: typeof response, deviceGroup: typeof deviceGroup }> =>
	   createAction(TRIGGER_DEVICE_GROUP_ADD_DEVICES_SUCCESS, { response, deviceGroup }),

	/**
	 * remove devices from the group
	 */
	triggerDeviceGroupRemoveDevices: (deviceGroup: string, devices: {thingNames: string[]}, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof TRIGGER_DEVICE_GROUP_REMOVE_DEVICES, {deviceGroup: typeof deviceGroup, devices: typeof devices, formikPromise: typeof formikPromise }> => createAction(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES, { deviceGroup, devices, formikPromise }),
	triggerDeviceGroupRemoveDevicesError: <ErrorType extends Error>(error: ErrorType, deviceGroup: string): PayloadAction<typeof TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR, { deviceGroup: typeof deviceGroup; error: ErrorType, }> =>
		createAction(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_ERROR, {deviceGroup, error}),
	triggerDeviceGroupRemoveDevicesSuccess:  (
		response: string,
		deviceGroup: string
	): PayloadAction<typeof TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS, { response: typeof response, deviceGroup: typeof deviceGroup }> =>
	   createAction(TRIGGER_DEVICE_GROUP_REMOVE_DEVICES_SUCCESS, { response, deviceGroup })
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type deviceGroupsActions = ActionType<typeof deviceGroupsActions>;

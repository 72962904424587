import React, { useEffect, useRef, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as PropTypes from 'prop-types';

import { ErrorMessage, Field, FieldArray, Form, Formik, FormikErrors, FormikHelpers, FormikState, useFormikContext } from 'formik';
import { CheckboxWithLabel, Select, TextField, TextFieldProps } from 'formik-material-ui';
import { Button, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCardIcon from '@mui/icons-material/AddCard';
import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import { DeviceGroupConfigurationInputFormValues } from '../../DeviceGroupConfigurationInputForm';
import Box from '@mui/system/Box/Box';


export interface ExecutionsRolloutFormProperties extends Pick<FormikHelpers<DeviceGroupConfigurationInputFormValues> & FormikState<DeviceGroupConfigurationInputFormValues>, 'setFieldValue' | 'errors' | 'values'> {
	isSubmitting: boolean;
}

export const ExecutionsRolloutForm: React.FC<ExecutionsRolloutFormProperties> = (({ values, isSubmitting, errors, setFieldValue }) => {

	return (
		<Box width={'100%'} position="relative">
			<Typography variant="h6" color="primary" pb={2}>
				Executions Rollout
			</Typography>

			<Box position="absolute" top={4} right={24}>
				<Button color="primary" onClick={() => {
					setFieldValue('executionsRolloutConfig.maximumPerMinute', '')
					setFieldValue('executionsRolloutConfig.exponentialRate.baseRatePerMinute', '')
					setFieldValue('executionsRolloutConfig.exponentialRate.incrementFactor', '')
					setFieldValue('executionsRolloutConfig.exponentialRate.rateIncreaseCriteria.numberOfSucceededThings', '')
					
					
					setTimeout(() => {
						setFieldValue('executionsRolloutConfig', undefined)
					}, 0)
				}}>Reset</Button>
			</Box>

			<Box width={300} pb={2}>
				<Field disabled={isSubmitting} label="Max Rate Per Minute" placeholder="Max Rate Per Minute" helperText={'The upper limit of the rate at which job executions can occur.'}  component={TextField} name={'executionsRolloutConfig.maximumPerMinute'} type="number" style={{ width: '100%' }} />
			</Box>

			

			<Box width={300} pb={2}>
				<Field disabled={isSubmitting} placeholder="Base Rate Per Minute" label="Base Rate Per Minute" helperText={'The rate at which the jobs are executed until the \'No of succeeded things\' threshold has been met.'} component={TextField} name={'executionsRolloutConfig.exponentialRate.baseRatePerMinute'} type="number" style={{ width: '100%' }} />
			</Box>

			<Box width={300} pb={2}>
				<Field disabled={isSubmitting} placeholder="Increment Factor" label="Increment Factor" helperText={'The exponential factor by which the rollout rate increases after the \'No of succeeded things\' threshold has been met.'} min="0.00" step="0.1" max="10000.00" presicion={1} component={TextField} name={'executionsRolloutConfig.exponentialRate.incrementFactor'} type="number" style={{ width: '100%' }} />
			</Box>

			<Box width={300} pb={2}>
				<Field disabled={isSubmitting} placeholder="No of succeeded things" label="No of succeeded things" helperText={'The threshold of succeeded things to use.'} component={TextField} name={'executionsRolloutConfig.exponentialRate.rateIncreaseCriteria.numberOfSucceededThings'} type="number" style={{ width: '100%' }} />
			</Box>
		</Box>
	);
});

ExecutionsRolloutForm.displayName = 'Executions Rollout Form';

ExecutionsRolloutForm.propTypes = {
	errors: PropTypes.any,
	isSubmitting: PropTypes.any,
	values: PropTypes.any
};
import { useSelector } from 'react-redux';

import React, { useEffect, useState } from 'react';




import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { GetParameterValuesProperties } from '../../../../../shared/components/GetParameterValues';
import { devicesSelectors } from '../../../../../store/selectors';


interface GetParameterValuesOperationSnackbarProperties extends Pick<GetParameterValuesProperties, 'agentEndpointId'> {

}

export const getParameterValuesOperationSnackbarDataSelectors = {
	getParameterValuesOperationSnackbarAlertError: 'GetParameterValuesOperationSnackbar-snackbarAlertError',
	getParameterValuesOperationSnackbarAlertSuccess: 'GetParameterValuesOperationSnackbar-snackbarAlertSuccess',
	getParameterValuesOperationSnackbarError: 'GetParameterValuesOperationSnackbar-snackbarError',
	getParameterValuesOperationSnackbarForm: 'GetParameterValuesOperationSnackbar-form',
	getParameterValuesOperationSnackbarModal: 'GetParameterValuesOperationSnackbar-modal',



	getParameterValuesOperationSnackbarSuccess: 'GetParameterValuesOperationSnackbar-snackbarSuccess'
};

export const GetParameterValuesOperationSnackbar: React.FC<GetParameterValuesOperationSnackbarProperties> = ({ agentEndpointId }) => {

	const getDeviceParameterValuesOperation = useSelector(devicesSelectors.getDeviceParameterValuesOperation(agentEndpointId)) as any;
	const getDeviceParameterValuesOperationResults = useSelector(devicesSelectors.getDeviceParameterValuesOperationResults(agentEndpointId)) as any;
	const [shouldShowSnackbar, setShouldShowSnackbar] = useState<boolean | 'success' | 'error'>(false);

	const operationError = typeof getDeviceParameterValuesOperation?.error === 'string' ? getDeviceParameterValuesOperation.error : getDeviceParameterValuesOperation?.error?.message;
	const operationResponse = getDeviceParameterValuesOperationResults;

	const didOperationSucceed = getDeviceParameterValuesOperation?.didSucceed === true;
	const didOperationError = getDeviceParameterValuesOperation?.hasError === true;

	const shouldOpenSnackbar = didOperationSucceed || didOperationError;

	useEffect(() => {
		if (shouldOpenSnackbar) {
			setShouldShowSnackbar(didOperationSucceed ? 'success' : 'error');
		}
	}, [
		shouldOpenSnackbar
	]);

	return (<>
		<Snackbar open={shouldShowSnackbar === 'error'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={getParameterValuesOperationSnackbarDataSelectors.getParameterValuesOperationSnackbarError}>
			<Alert variant="filled" severity="error" onClose={() => setShouldShowSnackbar(false)} data-cy={getParameterValuesOperationSnackbarDataSelectors.getParameterValuesOperationSnackbarAlertError}>
				An error occurred while retrieving parameter values: {operationError}
			</Alert>
		</Snackbar>
		<Snackbar open={shouldShowSnackbar === 'success'} autoHideDuration={10_000} onClose={() => setShouldShowSnackbar(false)} data-cy={getParameterValuesOperationSnackbarDataSelectors.getParameterValuesOperationSnackbarSuccess}>
			<Alert variant="filled" severity="success" onClose={() => setShouldShowSnackbar(false)} data-cy={getParameterValuesOperationSnackbarDataSelectors.getParameterValuesOperationSnackbarAlertSuccess}>
				{operationResponse?.length} parameter value{operationResponse?.length === 1 ? '' : 's'} successfully retrieved.
			</Alert>
		</Snackbar>
	</>);
};

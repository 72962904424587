import { combineReducers } from 'redux';
import { Rule } from '../../shared/types';
import { StateType } from 'typesafe-actions';

import { CreateReleaseRequest, CreateRuleRequest, DeleteRuleRequest, ListRulesRequest, UpdateRuleRequest, rulesActions } from '../actions';
import {
	CREATE_RULE,
	CREATE_RULE_ERROR,
	CREATE_RULE_SUCCESS,
	DELETE_RULE,
	DELETE_RULE_ERROR,
	DELETE_RULE_SUCCESS,
	LIST_RULES,
	LIST_RULES_ERROR,
	LIST_RULES_SUCCESS,
	UPDATE_RULE,
	UPDATE_RULE_ERROR,
	UPDATE_RULE_SUCCESS
} from '../constants';

import { DeepReadonlyObject, OperationState, PaginatedResults, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';


export type RulesState = DeepReadonlyObject<{
	createRuleOperation: OperationState<{ response: any; }, Error, CreateRuleRequest>;
	listRulesOperation: OperationState<Rule[], Error, ListRulesRequest>;
	deleteRuleOperation: OperationState<{ response: any;  }, Error, DeleteRuleRequest>;
	updateRuleOperation: OperationState<{ response: any;  }, Error, UpdateRuleRequest>;
}>;
export const initialRulesState: RulesState = {
	createRuleOperation: getStateInitial<{ response: any; }, Error, CreateRuleRequest>(),
	deleteRuleOperation: getStateInitial<{ response: any; }, Error, DeleteRuleRequest>(),
	listRulesOperation: getStateInitial<Rule[], Error, ListRulesRequest>(),
	updateRuleOperation: getStateInitial<{ response: any; }, Error, UpdateRuleRequest>()
};
export const rulesReducers = combineReducers<RulesState, rulesActions>({
	/**
	 * CREATE RULE
	 */
	createRuleOperation: (state = initialRulesState.createRuleOperation, action) => {
		switch (action.type) {
		case CREATE_RULE: {
			return getStateInProgress(action.payload);
		}
		case CREATE_RULE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case CREATE_RULE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * DELETE RULE
	 */
	deleteRuleOperation: (state = initialRulesState.deleteRuleOperation, action) => {
		switch (action.type) {
		case DELETE_RULE: {
			return getStateInProgress(action.payload);
		}
		case DELETE_RULE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case DELETE_RULE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * LIST RULES
	 */
	listRulesOperation: (state = initialRulesState.listRulesOperation, action) => {
		switch (action.type) {
		case LIST_RULES: {
			return getStateInProgress();
		}
		case LIST_RULES_SUCCESS: {
			const {result} = action.payload;

			return getStateSuccess(result);
		}
		// [09/11/2021]: We can't update the rule directly after update success, because rule has no id and no unique identifier. So we can't compare other fields for matching rule as they could have been updated and won't matc the original item in state.
		// case UPDATE_RULE_SUCCESS: {
		// 	const { rule: ruleUpdated} = action.payload;

		// 	return {
		// 		...state,
		// 		current: state?.current?.map((rule) => {
		// 			const { hubFw, hubModel, swvid, targetFw } = rule;
		// 			const isMatch = hubFw === rule.hubFw && hubModel === rule.hubModel && swvid === rule.swvid && targetFw === rule.targetFw;

		// 			if (isMatch) {
		// 				return ruleUpdated;
		// 			}
		// 			return rule;
		// 		})
		// 	};
		// }
		case LIST_RULES_ERROR: {
			const {error} = action.payload;

			return getStateError(error);
		}
		default:
			return state;
		}
	},
	/**
	 * UPDATE RULE
	 */
	updateRuleOperation: (state = initialRulesState.updateRuleOperation, action) => {
		switch (action.type) {
		case UPDATE_RULE: {
			return getStateInProgress(action.payload);
		}
		case UPDATE_RULE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case UPDATE_RULE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type rulesReducers = StateType<typeof rulesReducers>;

import {call, put, takeEvery} from '@redux-saga/core/effects';

import {authActions, firmwareActions} from '../actions';
import {
	CREATE_FIRMWARE,
	DELETE_FIRMWARE,
	LIST_FIRMWARE,
	UPDATE_FIRMWARE
} from '../constants';
import { push } from 'connected-react-router';
import { appRoutes } from '../../App.router';
import { processUnauthenticatedResponse, request } from '@indigo-cloud/common-react';
import { awsExports } from '../../shared';

const baseFirmwarePath = '/v1/firmwareImages';
// TODO: Abstract sagas, eg 'createCrudApiSagas()'
function* listFirmwareSaga(action: ReturnType<typeof firmwareActions['listFirmware']>) {
	const {
		payload: {filters, limit = 10, cursor, pageIndex = 0}
	} = action;
	try {
		console.log('filters', filters)

		const keysFilters = Object.keys(filters || {});
		let search = '';
		if (keysFilters?.length) {
			search = keysFilters.reduce((previous, keyFilter) => {
				const filter = filters![keyFilter] as string;
				previous += `&${encodeURIComponent(`search[${keyFilter}]`)}=${encodeURIComponent(filter)}`;

				return previous;
			}, '');

			console.log('search', search)
		}

		const requestUrl = `${baseFirmwarePath}?limit=${limit}${cursor?.length ? `&cursor=${cursor}` : ''}${Object.keys(filters || '').length > 0 ? search : ''}`

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				path: requestUrl
			}
		);
		yield put(firmwareActions.listFirmwareSuccess(response, pageIndex, filters));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		yield put(firmwareActions.listFirmwareError(error));
	}
}

function* createFirmwareSaga(action: ReturnType<typeof firmwareActions['createFirmware']>) {
	const {
		payload: {model, formikPromise, createAnother}
	} = action;

	try {
		const response = yield call(
			request,

			{
				apiName: 'usp',
				awsExports,
				method: 'post',
				options: {
					body: model
				},
				path: baseFirmwarePath

			}
		);

		yield put(firmwareActions.createFirmwareSuccess(response));
		formikPromise.resolve(response);
		yield put(firmwareActions.listFirmware({}));

		if (!createAnother) {
			yield put(push(appRoutes.Firmware));
		}

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while creating the firmware', error);
		yield put(firmwareActions.createFirmwareError(error));
		formikPromise.reject(error);
	}
}

function* deleteFirmwareSaga(action: ReturnType<typeof firmwareActions['deleteFirmware']>) {
	const {
		payload: {model}
	} = action;
	try {

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'del',
				path: `${baseFirmwarePath}/${model.firmwareName}/${model.modelName}`
			}
		);

		yield put(firmwareActions.deleteFirmwareSuccess(response));
		yield put(firmwareActions.listFirmware({}));
		yield put(push(appRoutes.Firmware));
	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error('An error occurred while deleting the firmware', error, model);
		yield put(firmwareActions.deleteFirmwareError(error));
	}
}

function* updateFirmwareSaga(action: ReturnType<typeof firmwareActions['updateFirmware']>) {
	const {
		payload: {model, formikPromise}
	} = action;
	try {
		const { firmwareName, ...restFirmware } = model;

		const response = yield call(
			request,
			{
				apiName: 'usp',
				awsExports,
				method: 'put',
				options: {
					body: restFirmware
				},
				path: `${baseFirmwarePath}/${firmwareName}/${model.modelName}`
			}
		);

		yield put(firmwareActions.updateFirmwareSuccess(response));
		yield put(firmwareActions.listFirmware({}));

		formikPromise.resolve(response);

		yield put(push(appRoutes.Firmware));

	} catch (error) {
		yield processUnauthenticatedResponse(authActions.loadAuthUserClear(), error);
		console.error(`An error occurred while updating the Firmware  '${model.firmwareName}'.`, error);
		yield put(firmwareActions.updateFirmwareError(error));
		formikPromise.reject(error);
	}
}

function* watcher() {
	yield takeEvery(LIST_FIRMWARE, listFirmwareSaga);
	yield takeEvery(CREATE_FIRMWARE, createFirmwareSaga);
	yield takeEvery(DELETE_FIRMWARE, deleteFirmwareSaga);
	yield takeEvery(UPDATE_FIRMWARE, updateFirmwareSaga);
}

export const firmwareSagas = () => {

	return {
		watcher
	};
};

import React from 'react';
import {Backdrop, CircularProgress, CircularProgressProps} from '@mui/material';
import * as PropTypes from 'prop-types';
import {muiCircularProgressPropertyTypes} from '../../utils';
import styles from './BackdropLoader.component.module.scss'

export interface BackdropLoaderComponentProperties extends CircularProgressProps {
	isLoading?: boolean;
}

export const BackdropLoader: React.FC<BackdropLoaderComponentProperties> = ({
	isLoading = true,
	...rest
}: BackdropLoaderComponentProperties) => {
	if (isLoading) {
		return (
			<Backdrop open={isLoading} id={styles.container}>
				<CircularProgress color='inherit' {...rest} />
			</Backdrop>
		);
	}
	/* eslint-disable unicorn/no-null */
	return null;
};

BackdropLoader.propTypes = {
	isLoading: PropTypes.bool,

	...muiCircularProgressPropertyTypes
};

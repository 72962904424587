import { Rule } from '../../../shared/types';
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import {
	CREATE_RULE,
	CREATE_RULE_ERROR,
	CREATE_RULE_SUCCESS,
	DELETE_RULE,
	DELETE_RULE_ERROR,
	DELETE_RULE_SUCCESS,
	LIST_RULES as LIST_RULES,
	LIST_RULES_ERROR as LIST_RULES_ERROR,
	LIST_RULES_SUCCESS as LIST_RULES_SUCCESS,
	UPDATE_RULE,
	UPDATE_RULE_ERROR,UPDATE_RULE_SUCCESS
} from '../../constants';
import { FormikFormSubmitPromise, PaginatedResults, createAction } from '@indigo-cloud/common-react';


export type CreateRuleRequest = ReturnType<typeof rulesActions['createRule']>['payload'];
export type UpdateRuleRequest = ReturnType<typeof rulesActions['updateRule']>['payload'];
export type DeleteRuleRequest = ReturnType<typeof rulesActions['deleteRule']>['payload'];
export type ListRulesRequest = unknown;

export const rulesActions = {

	/**
	 * CREATE
	 */
	createRule: (model: Rule, formikPromise: FormikFormSubmitPromise, createAnother?: boolean): PayloadAction<typeof CREATE_RULE, { model: typeof model; formikPromise: typeof formikPromise; createAnother: typeof createAnother }> => createAction(CREATE_RULE, { createAnother, formikPromise, model }),
	createRuleError: (error: Error): PayloadAction<typeof CREATE_RULE_ERROR, { error: typeof error }> =>
		createAction(CREATE_RULE_ERROR, { error }),
	createRuleSuccess: (response: any): PayloadAction<typeof CREATE_RULE_SUCCESS, { response: typeof response }> =>
		createAction(CREATE_RULE_SUCCESS, { response }),

	 /**
	 * DELETE RULE
	 */
	deleteRule: (model: Rule[]): PayloadAction<typeof DELETE_RULE, { model: typeof model }> => createAction(DELETE_RULE, { model }),
	deleteRuleError: (error: Error): PayloadAction<typeof DELETE_RULE_ERROR, { error: typeof error }> =>
		 createAction(DELETE_RULE_ERROR, { error }),
	deleteRuleSuccess: (response: any): PayloadAction<typeof DELETE_RULE_SUCCESS, { response: typeof response }> =>
		 createAction(DELETE_RULE_SUCCESS, { response }),

	/**
	 * LIST RULES
	 */
	listRules: (): Action<typeof LIST_RULES> => createAction(LIST_RULES),
	listRulesError: (error: Error): PayloadAction<typeof LIST_RULES_ERROR, { error: typeof error }> =>
		createAction(LIST_RULES_ERROR, { error }),
	listRulesSuccess: (result: Rule[]): PayloadAction<typeof LIST_RULES_SUCCESS, { result: typeof result }> =>
		createAction(LIST_RULES_SUCCESS, { result }),

	/**
	 * UPDATE RULE
	 */
	updateRule: (model: Rule, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof UPDATE_RULE, { model: typeof model; formikPromise: typeof formikPromise; }> => createAction(UPDATE_RULE, { formikPromise, model }),
	updateRuleError: (error: Error): PayloadAction<typeof UPDATE_RULE_ERROR, { error: typeof error }> =>
		 createAction(UPDATE_RULE_ERROR, { error }),
	updateRuleSuccess: (response: any): PayloadAction<typeof UPDATE_RULE_SUCCESS, { response: typeof response }> =>
		 createAction(UPDATE_RULE_SUCCESS, { response })
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
export type rulesActions = ActionType<typeof rulesActions>;

import { all, fork } from '@redux-saga/core/effects';

import { usersSagas } from './Users.sagas';

export const allSagas = function* () {
	yield all([
		fork(
			usersSagas().watcher
		)
	]);
};

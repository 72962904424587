/**
 * LOAD_USERS_TABLE
 */
export const LIST_USERS = 'USERS.LOAD_USERS_TABLE';
export const LIST_USERS_ERROR = 'USERS.LOAD_USERS_TABLE_ERROR';
export const LIST_USERS_SUCCESS = 'USERS.LOAD_USERS_TABLE_SUCCESS';

/**
 * UPDATE_USER
 */
export const UPDATE_USER = 'USERS.UPDATE_USER';
export const UPDATE_USER_ERROR = 'USERS.UPDATE_USER_ERROR';
export const UPDATE_USER_SUCCESS = 'USERS.UPDATE_USER_SUCCESS';

/**
 * DISABLE_USER
 */
export const DISABLE_USER = 'USERS.DISABLEUSER';
export const DISABLE_USER_ERROR = 'USERS.DISABLE_USER_ERROR';
export const DISABLE_USER_SUCCESS = 'USERS.DISABLE_USER_SUCCESS';

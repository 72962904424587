export const onSpeedTestChanges = /* GraphQL */ `
  subscription OnSpeedTestChanges($line: String!) {
    onSpeedTestChanges(line: $line) {
      id
	  line
	  type
	  deviceId
	  status
	  createdAt
	  updatedAt
	  trigger
	  platform
	  TCPTestResult {
	    resultVersion
        up {
          outcome
          serverHostname
          serverAddress
          wanType
          timeOfResults
          testDuration
          bytesTransferred
          speed
          speedGbps
          speedMbps
          speedAtMeasuredInterval
          speedWithUnit
          warmupTime
          warmupBytes
          intervalNumber
          numberOfThreads
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
        }
        down {
          outcome
          serverHostname
          serverAddress
          wanType
          timeOfResults
          testDuration
          bytesTransferred
          speed
          speedGbps
          speedMbps
          speedAtMeasuredInterval
          speedWithUnit
          warmupTime
          warmupBytes
          intervalNumber
          numberOfThreads
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
        }
	  }
      UDPTestResult {
	    resultVersion
        up {
          outcome
          errorCode
          errorDetails
          serverHostname
          serverAddress
          wanType
          timeOfResults
          packetSize
          transmissionRate
          transmissionBytes
          transmissionDuration
          speed
          speedGbps
          speedMbps
          speedWithUnit
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
         }
         down {
          outcome
          errorCode
          errorDetails
          serverHostname
          serverAddress
          wanType
          timeOfResults
          packetSize
          transmissionRate
          transmissionBytes
          transmissionDuration
          speed
          speedGbps
          speedMbps
          speedWithUnit
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
        }
	  }
	  result
      error
      hubError {
        code
        type
        message
      }
	  completedAt
      executionDuration
      _lastChangedAt
      _version
    }
  }
`;

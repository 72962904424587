import { all, fork } from '@redux-saga/core/effects';
import { releasesSagas } from './Releases.sagas';
import { rulesSagas } from './Rules.sagas';

export const allSagas = function* () {
	yield all([
		fork(
		  releasesSagas().watcher
		),
		fork(
			rulesSagas().watcher
		)
	]);
};

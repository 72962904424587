import React, {ReactNode, useEffect} from 'react';
import * as PropTypes from 'prop-types';
import {LinearProgress} from '@mui/material';
import styles from './Page.component.module.scss';
export interface PageProperties {
	children: ReactNode,
	isLoading?: boolean;
	styles?: {
		root?: any;
	}
}

export const pageDataSelectors = {
	loading: 'Page-loading',
	pageContainer: 'Page-container'
};

export const Page: React.FC<PageProperties> = ({children, isLoading, styles: stylesProperty}) => {

	return (
		<div data-cy={pageDataSelectors.pageContainer} className={styles.root} style={stylesProperty?.root}>
			{ isLoading && <LinearProgress data-cy={pageDataSelectors.loading} /> }
			{ children }
		</div>
	)
};

Page.propTypes = {
	children: PropTypes.any
};

import {OperationState, OperationStatus} from '../../types';

export const getLoadingStatusInitial = <Error_, Request = unknown>(): OperationStatus<Error_, Request> => ({
	didSucceed: false,
	isLoading: false
});

export const getLoadingStatusInProgress = <Error_, Request = unknown>(request?: Request): OperationStatus<Error_, Request> => ({
	didSucceed: false,
	isLoading: true,
	request
});

export const getLoadingStatusSuccess = <Error_, Request = unknown>(request?: Request): OperationStatus<Error_, Request> => ({
	didSucceed: true,
	isLoading: false,
	request
});

export const getLoadingStatusError = <Error_, Request = unknown>(error: Error_, request?: Request): OperationStatus<Error_, Request> => ({
	didSucceed: false,
	error,
	isLoading: false,
	request
});

export const getStateInitial = <Current, Error_ = Error, Request = unknown>(): OperationState<Current, Error_, Request> => ({
	current: undefined,
	status: getLoadingStatusInitial<Error_, Request>()
});

export const getStateInProgress = <Current, Error_ = Error, Request = unknown>(request?: Request): OperationState<Current, Error_, Request> => ({
	current: undefined,
	status: getLoadingStatusInProgress<Error_, Request>(request)
});
export const getStateSuccess = <Current, Error_ = Error, Request = unknown>(
	current: Current,
	state?: OperationState<Current, Error_, Request>
): OperationState<Current, Error_, Request> => ({
		current,
		status: getLoadingStatusSuccess<Error_, Request>(state?.status?.request)
	});

export const getStateError = <Current, Error_ = Error, Request = unknown>(error: Error_, state?: OperationState<Current, Error_, Request>): OperationState<Current, Error_, Request> => ({
	current: undefined,
	status: getLoadingStatusError<Error_, Request>(error, state?.status?.request)
});

import {BackdropLoader} from '@indigo-cloud/common-react';
import {IconButton, Theme, Typography} from '@mui/material';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, {useEffect, useImperativeHandle, useRef} from 'react';
import {Filters, TableOptions, UsePaginationOptions, useFilters, usePagination, useRowSelect, useTable} from 'react-table';

import styles from './PaginatedTable.component.module.scss';

export type PaginatedTableProperties<T extends Record<string, any>> = Pick<TableOptions<T>, 'columns' | 'data' | 'defaultColumn' > & Pick<UsePaginationOptions<T>, 'pageCount'> & {
	onPageChanged: (page: number) => void,
	loading: boolean;
	pageIndex: number;
	totalCount?: number;
	onRowSelectionChanged?: (row: T, index: number) => void;
	onFiltersChanged?: (filters: Filters<T>) => void;
	theme: Theme;
	customStyle: {headerAlign: 'left' | 'right' | 'center', bodyAlign: 'left' | 'right' | 'center'};
	colorReset?: boolean | undefined;
	setColorReset?: ((value : boolean) => void) | undefined;
	hasCursor?: boolean;
	navigateToRowDetails?: (row: any) => void,
};

export interface PaginatedTableReference {
	clearSelection: () => void;
	gotoPage: (page: number) => void;
}


const PaginatedTableBase = React.forwardRef(<T extends Record<string, any>>({
	columns,
	data,
	defaultColumn,
	pageIndex,
	pageCount: controlledPageCount,
	totalCount,
	onFiltersChanged,
	onPageChanged,
	onRowSelectionChanged,
	loading,
	theme,
	customStyle,
	colorReset,
	setColorReset,
	hasCursor,
	navigateToRowDetails
}: PaginatedTableProperties<T>, reference: React.Ref<PaginatedTableReference>) => {

	const shouldEnableRowSelection = !!onRowSelectionChanged || !!navigateToRowDetails;
	const columnStyle: React.CSSProperties = {fontFamily: theme.typography.fontFamily};


	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,

		gotoPage,
		nextPage,
		previousPage,
		setPageSize,


		state: {pageSize, selectedRowIds, filters,pageIndex: pageIndex2},
		pageCount,
		toggleAllRowsSelected,
		toggleRowSelected
	} = useTable<T>(
		{
			autoResetFilters: false,
			autoResetPage: false,

			columns,
			data,
			defaultColumn,
			enableGlobalFilter: false,


			initialState: {pageIndex: 0},

			manualPagination: true,
			pageCount: controlledPageCount

		},
		useFilters,
		usePagination,
		...(shouldEnableRowSelection ? [useRowSelect] : [])
	);
	const canNextPage = pageCount ? (pageIndex < (pageCount - 1)) : hasCursor;
	const canPreviousPage = pageIndex > 0;

	const referenceSelectedIds = useRef<any>();

	useEffect(() => {
		if (pageIndex !== 0) {
			// reset page when filters change
			gotoPage(0);
		}
	}, [filters])

	useImperativeHandle<PaginatedTableReference, PaginatedTableReference>(reference, () => ({
		clearSelection: () => {
			toggleAllRowsSelected(false);
		},
		gotoPage
	}));

	useEffect(() => {
		onFiltersChanged?.(filters);
	}, [JSON.stringify(filters || '')])


	// Listen for changes in pagination and use the state to fetch our new data


	const renderPagination = () => {
		return (
			<div style={{alignItems: 'center', display: 'flex', gap: '10px', justifyContent: 'center', padding: '30px'}}>
				{
					totalCount ?
						(<Typography style={{left: 48, position: 'absolute'}}>
						Total Records: {totalCount}
							{/* eslint-disable-next-line unicorn/no-null */}
						</Typography>) : null
				}
				<IconButton onClick={(event) => {
					previousPage();
					onPageChanged(pageIndex - 1);
					event.preventDefault();
				}
				} disabled={!canPreviousPage}>
					<NavigateBeforeIcon fontSize="large" color={canPreviousPage ? 'primary' : undefined} />
				</IconButton>
				
				{ (pageCount ) ? (
					<span>
							Page{' '}
						<strong>
							{pageIndex + 1} of {pageCount}
						</strong>{' '}
					</span>
				) : (
					<span>
							Page{' '}
						<strong>
							{pageIndex + 1}
						</strong>{' '}
					</span>
				)
				}
				<IconButton onClick={(event) => {
					nextPage();
					onPageChanged(pageIndex + 1);
					event.preventDefault();
				}
				} disabled={!canNextPage}>
					<NavigateNextIcon fontSize="large" color={canNextPage ? 'primary' : undefined} />
				</IconButton>
			</div>
		)
	};

	return (
		<>
			{ loading && <BackdropLoader isLoading />}
			<table {...getTableProps()} className={`${styles.container} ${shouldEnableRowSelection ? styles.containerSelectable : ''}`}>
				<thead>
					{headerGroups.map(headerGroup => (
						<tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
							{headerGroup.headers.map(column => (
								<th
									{...column.getHeaderProps()}
									style={{
										padding: '5px 0px 0px 18px',
										textAlign: customStyle.headerAlign,
										...columnStyle
									}}
									key={column.id}
								>
									{column.render('Header')}
									<div>{column.canFilter? column.render('Filter'): undefined}</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				<tbody {...getTableBodyProps()}>
					{page.map((row, index) => {
						prepareRow(row)
						return (
							<tr
								{...row.getRowProps({})}
								// {...row.getToggleRowSelectedProps?.() as any}
								onClick={() => {
									if (onRowSelectionChanged) {
										if(setColorReset){
											setColorReset(false);
										}
										toggleAllRowsSelected(false);
										row.toggleRowSelected(true)
										referenceSelectedIds.current = row.values;
										onRowSelectionChanged?.(row.values as T, index);
									}

									if(navigateToRowDetails){
										navigateToRowDetails(row.original);
									}

								}}
								key={row.id}
								style={{
									textAlign: customStyle.bodyAlign,
									...columnStyle,
									'borderBottom': '1px solid rgba(224, 224, 224, 1)',
									...(JSON.stringify(colorReset ? {} : referenceSelectedIds.current || {}) === JSON.stringify(row.values) ? {
										backgroundColor: '#28a745'
									} : {})
								}}
							>
								{row.cells.map((cell, index) => {
									return (
										<td
											{...cell.getCellProps()}
											key={`${cell.value}-${index}`}
											style={{
												'borderBottom': '1px solid rgba(224, 224, 224, 1)',
												padding: '15px'
											}}
										>
											{cell.render('Cell')}
										</td>
									)
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			{renderPagination()}
		</>
	);
});

// Allow forwardRef to work with type generics
export const PaginatedTable = PaginatedTableBase as <T extends Record<string, any>>(properties: PaginatedTableProperties<T> & {ref: React.Ref<PaginatedTableReference>}) => JSX.Element;

import { useSelector } from 'react-redux';

import React, {  } from 'react';

import { DataGrid } from '@mui/x-data-grid';
import { theme } from '../../../../../App.theme';
import EditIcon from '@mui/icons-material/Edit';
import styles from './GetParameterValuesResults.component.module.scss'
import { GetParameterValuesProperties } from '../../../../../shared/components/GetParameterValues';
import { devicesSelectors } from '../../../../../store/selectors';
import List from '@mui/material/List';
import ListSubheader from '@mui/material/ListSubheader';
import { DeviceParameterValues } from '../../../../types/DeviceParameterValues';
import FilterListIcon from '@mui/icons-material/FilterList';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import { Details } from '@indigo-cloud/common-react';
import MaterialTable from '@material-table/core';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

/* eslint-disable unicorn/no-null */

const columnStyle: React.CSSProperties = { fontFamily: theme.typography.fontFamily };

interface GetParameterValuesResultsProperties extends Pick<GetParameterValuesProperties, 'agentEndpointId'> {
	onClickUpdateRequest: (requestedParameterValues: string[]) => void;

}

export const getParameterValuesResultsDataSelectors = {
	progress: 'GetParameterValuesResultsDataSelectors-progress'
};

export const GetParameterValuesResults: React.FC<GetParameterValuesResultsProperties> = ({ agentEndpointId, onClickUpdateRequest }) => {

	const getDeviceParameterValuesResults: DeviceParameterValues = useSelector(devicesSelectors.getDeviceParameterValuesOperationResults(agentEndpointId)) || [];
	const getDeviceParameterValuesOperation = useSelector(devicesSelectors.getDeviceParameterValuesOperation(agentEndpointId));

	if (!getDeviceParameterValuesOperation?.isLoading && !getDeviceParameterValuesResults?.length) {
		return null;
	}

	return (
		<Paper className={styles.container}>
			<List className={styles.list}>
				<ListSubheader className={styles.listHeader}>
					Device Parameter Values
					<IconButton
						onClick={() => {
							onClickUpdateRequest(getDeviceParameterValuesOperation?.request?.paramPaths)
						}}
						size="large">
						<EditIcon style={{ fontSize: 18 }} />
					</IconButton>
				</ListSubheader>

				{getDeviceParameterValuesOperation?.isLoading ? <div className={styles.containerLoader}>
					<CircularProgress data-cy={getParameterValuesResultsDataSelectors.progress} color='primary' size={'2rem'}/>
				</div> : <div style={{ height: '600px', width: '100%' }}>
					<MaterialTable<any>
						title="Get Parameter Values"
						columns={[
							{
								cellStyle: columnStyle,
								field: 'requestedPath',
								headerStyle: columnStyle,
								title: 'Requested Path'

							},
							{
								cellStyle: columnStyle,
								field: 'resolvedPath',
								headerStyle: columnStyle,
								title: 'Resolved Path'
							},
							{
								cellStyle: columnStyle,
								field: 'resolvedValueTitle',
								headerStyle: columnStyle,
								title: 'Resolved Value'
							}
						]}
						options={{
							draggable: false,
							filterCellStyle: { textAlign: 'center' },
							pageSize: 20,
							pageSizeOptions: [20, 50, 100, 500, 1000],
							searchFieldVariant: 'standard'
						}}
						data={getDeviceParameterValuesResults.reduce((previousValue, {  requestedPath, resolvedPathResults }) => {
							return [
								...previousValue,
								...resolvedPathResults?.map?.(({ resolvedPath, resultParams }) => ({
									id: resolvedPath,
									requestedPath,
									resolvedPath,
									resolvedValue: JSON.stringify(resultParams, null, 2),
									resolvedValueTitle: `${Object.keys(resultParams as any).length} fields`
								})) || []
							]
						}, [] as any[])}
						detailPanel={({rowData}) => {
							const { tableData, resolvedValueTitle, ...rest } = rowData as any;
							return (
								<Details object={rest} />
							)
			 }}
					/>
				</div>}
			</List>
		</Paper>
	);
};

/* eslint-disable unicorn/no-null */

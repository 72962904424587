import React, { useEffect, useRef, useState } from 'react';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import * as PropTypes from 'prop-types';

import { ErrorMessage, Field, FieldArray, Form, Formik, FormikErrors, FormikHelpers, FormikState, useFormikContext } from 'formik';
import { CheckboxWithLabel, Select, TextField, TextFieldProps } from 'formik-material-ui';
import { Button, IconButton, MenuItem, Tooltip, Typography } from '@mui/material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddCardIcon from '@mui/icons-material/AddCard';
import Remove from '@mui/icons-material/Remove';
import Add from '@mui/icons-material/Add';
import { DeviceGroupConfigurationInputFormValues } from '../../DeviceGroupConfigurationInputForm';
import Box from '@mui/system/Box/Box';


export interface AbortConfigFormProperties extends Pick<FormikHelpers<DeviceGroupConfigurationInputFormValues> & FormikState<DeviceGroupConfigurationInputFormValues>, 'setFieldValue' | 'errors' | 'values'> {
	isSubmitting: boolean;
}

export const AbortConfigForm: React.FC<AbortConfigFormProperties> = (({ values, isSubmitting, errors, setFieldValue }) => {


	return (
		<Box position="relative">
			
			<Typography variant="h6" color="primary" pb={2}>
				Abort Config
			</Typography>

			<Box position="absolute" top={4} right={24}>
				<Button color="primary" onClick={() => {
					setFieldValue('abortConfig.minNumberOfExecutedThings', '')
					setFieldValue('abortConfig.thresholdPercentage', '')
					setTimeout(() => {
						setFieldValue('abortConfig', undefined)
					}, 0)
				}}>Reset</Button>
			</Box>

			<Box width={300} pb={2}>
				<Field disabled={isSubmitting} placeholder="Min Job Executions" label="Min Job Executions" helperText={'The number of completed job executions that must occur before the job abort criteria has been met.'} component={TextField} name={'abortConfig.minNumberOfExecutedThings'} type="number"  />
			</Box>

			<Box width={300} pb={2}>
				<Field disabled={isSubmitting} placeholder="Threshold Percentage" label="Threshold Percentage" helperText={'The total number of things for which jobs are executed that can initiate a job abort.'} component={TextField} name={'abortConfig.thresholdPercentage'} type="number"  />
			</Box>
		</Box>
	);
});

AbortConfigForm.displayName = 'Abort Config Form';

AbortConfigForm.propTypes = {
	errors: PropTypes.any,
	isSubmitting: PropTypes.any,
	values: PropTypes.any
};
import { combineReducers } from 'redux';
import { Release } from '../../shared/types';
import { StateType } from 'typesafe-actions';

import { CreateReleaseRequest, DeleteReleaseRequest, UpdateReleaseRequest, releaseActions } from '../actions';
import {
	CREATE_RELEASE,
	CREATE_RELEASE_ERROR,
	CREATE_RELEASE_SUCCESS,
	DELETE_RELEASE,
	DELETE_RELEASE_ERROR,
	DELETE_RELEASE_SUCCESS,
	LIST_RELEASES,
	LIST_RELEASES_ERROR,
	LIST_RELEASES_SUCCESS,
	UPDATE_RELEASE,
	UPDATE_RELEASE_ERROR,
	UPDATE_RELEASE_SUCCESS
} from '../constants';
import { DeepReadonlyObject, OperationState, getStateError, getStateInProgress, getStateInitial, getStateSuccess } from '@indigo-cloud/common-react';

export type ReleasesState = DeepReadonlyObject<{
	createReleaseOperation: OperationState<{ response: any; }, Error, CreateReleaseRequest>;
	deleteReleaseOperation: OperationState<{ response: any; }, Error, DeleteReleaseRequest>;
	listReleasesOperation: OperationState<Release[]>;
	updateReleaseOperation: OperationState<{ response: any; }, Error, UpdateReleaseRequest>;
}>;
export const initialReleasesState: ReleasesState = {
	createReleaseOperation: getStateInitial<{ response: any;}, Error, CreateReleaseRequest>(),
	deleteReleaseOperation: getStateInitial<{ response: any;}, Error, DeleteReleaseRequest>(),
	listReleasesOperation: getStateInitial(),
	updateReleaseOperation: getStateInitial<{ response: any; }, Error, UpdateReleaseRequest>()
};
export const releasesReducers = combineReducers<ReleasesState, releaseActions>({
	/**
	 * CREATE RELEASE
	 */
	createReleaseOperation: (state = initialReleasesState.createReleaseOperation, action) => {
		switch (action.type) {
		case CREATE_RELEASE: {
			return getStateInProgress(action.payload);
		}
		case CREATE_RELEASE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess({ response }, state);
		}
		case CREATE_RELEASE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * DELETE RELEASE
	 */
	deleteReleaseOperation: (state = initialReleasesState.deleteReleaseOperation, action) => {

		switch (action.type) {
		case DELETE_RELEASE: {
			return getStateInProgress(action.payload);
		}
		case DELETE_RELEASE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case DELETE_RELEASE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * LIST RELEASE
	 */
	listReleasesOperation: (state = initialReleasesState.listReleasesOperation, action) => {
		switch (action.type) {
		case LIST_RELEASES: {
			return getStateInProgress();
		}
		case LIST_RELEASES_SUCCESS: {
			const {result} = action.payload;

			return getStateSuccess(result, state);
		}
		case LIST_RELEASES_ERROR: {
			const {error} = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	},
	/**
	 * UPDATE RELEASE
	 */
	updateReleaseOperation: (state = initialReleasesState.updateReleaseOperation, action) => {
		switch (action.type) {
		case UPDATE_RELEASE: {
			return getStateInProgress(action.payload);
		}
		case UPDATE_RELEASE_SUCCESS: {
			const {response} = action.payload;

			return getStateSuccess(response, state);
		}
		case UPDATE_RELEASE_ERROR: {
			const { error } = action.payload;

			return getStateError(error, state);
		}
		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type releasesReducers = StateType<typeof releasesReducers>;

import { RouterRootState, connectRouter } from 'connected-react-router';
import { History, LocationState } from 'history';
import { combineReducers } from 'redux';
// import { PersistConfig, persistReducer } from 'redux-persist';
// import reduxPersistLocalStorage from 'redux-persist/lib/storage';
import { StateType } from 'typesafe-actions';
import { initialLocationState, locationReducers } from './Location';
import { initialReleasesState, releasesReducers } from './Releases.reducers';
import { initialRulesState, rulesReducers } from './Rules.reducers';

// const createPersistConfig = <State>() =>
// 	({
// 		key: 'root',
// 		storage: reduxPersistLocalStorage
// 	} as PersistConfig<State>);

export const createRootReducer = <HistoryState extends LocationState>(history: History<HistoryState>) =>
	combineReducers({
		location: locationReducers,
		releases: releasesReducers,
		router: connectRouter(history),
		rules: rulesReducers
	});

export type RootReducers = ReturnType<ReturnType<typeof createRootReducer>>;
export type AppState = StateType<ReturnType<typeof createRootReducer>>;
export type RootState = AppState & RouterRootState;

export const rootInitialState = {
	location: initialLocationState,
	releases: initialReleasesState,
	rules: initialRulesState
} as RootState;

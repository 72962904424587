import * as PropTypes from 'prop-types';
import {WeakValidationMap} from 'react';
import * as Loadable from 'react-loadable';

export const reactLoadablePropertyTypes = {
	error: PropTypes.any,
	isLoading: PropTypes.bool,
	pastDelay: PropTypes.bool,
	retry: PropTypes.func,
	timedOut: PropTypes.bool
} as WeakValidationMap<Loadable.LoadingComponentProps>;

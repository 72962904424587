import { DeepReadonlyObject } from '@indigo-cloud/common-react';
import { combineReducers } from 'redux';
import { StateType } from 'typesafe-actions';
import { menuActions } from '../../actions';
import { TOGGLE_MENU } from '../../constants';

export type MenuState = DeepReadonlyObject<{
  isOpen: boolean;
}>;
export const initialMenuState: MenuState = {
	isOpen: true
};
export const menuReducers = combineReducers<MenuState, menuActions>({
	isOpen: (state = initialMenuState.isOpen, action) => {
		switch (action.type) {
		case TOGGLE_MENU: {
			const {isOpen} = action.payload;
			if (isOpen !== undefined) {
				return isOpen;
			}

			return !state;
		}
		default:
			return state;
		}
	}
});

// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type menuReducers = StateType<typeof menuReducers>;

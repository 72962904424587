import { OperationSnackbar } from '@indigo-cloud/common-react';

import React from 'react';
import { useSelector } from 'react-redux';
import { FIND_DEVICE } from '../../../../../store/constants';
import { devicesSelectors } from '../../../../../store/selectors';
import { devicesActions } from '../../../../../store/actions';
import { Device } from '../../../../types';

export interface SearchSnackbarsComponentProperties {
	search: string;
}

type DeviceSearchResult = ReturnType<typeof devicesActions.findDevice>['payload'];


export const userSnackbarsDataSelectors = {};

export const SearchSnackbars: React.FC<SearchSnackbarsComponentProperties> = ({ search }: SearchSnackbarsComponentProperties) => {


	const findDeviceOperation = useSelector(devicesSelectors.findDeviceOperation);


	return (
		<>
			<OperationSnackbar<Device, DeviceSearchResult, unknown>
				action={'search'}
				itemName={'device'}
				uniqueIdentifierMapper={(entity) => search}
				operation={findDeviceOperation}
				requestActionType={FIND_DEVICE}
			/>

		</>
	);
};

SearchSnackbars.propTypes = {};

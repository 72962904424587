import { Release } from '../../../shared/types';
import { Action, ActionType, PayloadAction } from 'typesafe-actions';

import {
	CREATE_RELEASE,
	CREATE_RELEASE_ERROR,
	CREATE_RELEASE_SUCCESS,
	DELETE_RELEASE,
	DELETE_RELEASE_ERROR,
	DELETE_RELEASE_SUCCESS,
	LIST_RELEASES as GET_RELEASES_TABLE,
	LIST_RELEASES_ERROR as GET_RELEASES_TABLE_ERROR,
	LIST_RELEASES_SUCCESS as GET_RELEASES_TABLE_SUCCESS,
	UPDATE_RELEASE,
	UPDATE_RELEASE_ERROR,
	UPDATE_RELEASE_SUCCESS
} from '../../constants';
import { FormikFormSubmitPromise, createAction } from '@indigo-cloud/common-react';

export type CreateReleaseRequest = ReturnType<typeof releaseActions['createRelease']>['payload'];
export type UpdateReleaseRequest = ReturnType<typeof releaseActions['updateRelease']>['payload'];
export type DeleteReleaseRequest = ReturnType<typeof releaseActions['deleteRelease']>['payload'];
export type ListReleasesRequest = unknown;

export const releaseActions = {

	/**
	 * CREATE RELEASE
	 */
	createRelease: (model: Release, formikPromise: FormikFormSubmitPromise, createAnother?: boolean): PayloadAction<typeof CREATE_RELEASE, { model: typeof model; formikPromise: typeof formikPromise; createAnother: typeof createAnother; }> => createAction(CREATE_RELEASE, { createAnother, formikPromise, model }),
	createReleaseError: (error: Error): PayloadAction<typeof CREATE_RELEASE_ERROR, { error: typeof error }> =>
		createAction(CREATE_RELEASE_ERROR, { error }),
	createReleaseSuccess: (response: any): PayloadAction<typeof CREATE_RELEASE_SUCCESS, { response: typeof response }> =>
		createAction(CREATE_RELEASE_SUCCESS, { response }),

	 /**
	 * DELETE RELEASE
	 */
	  deleteRelease: (model: Release): PayloadAction<typeof DELETE_RELEASE, { model: typeof model }> => createAction(DELETE_RELEASE, { model }),
	  deleteReleaseError: (error: Error): PayloadAction<typeof DELETE_RELEASE_ERROR, { error: typeof error }> =>
		createAction(DELETE_RELEASE_ERROR, { error }),
	  deleteReleaseSuccess: (response: any): PayloadAction<typeof DELETE_RELEASE_SUCCESS, { response: typeof response }> =>
		createAction(DELETE_RELEASE_SUCCESS, { response }),

	/**
	 * GET RELEASES
	 */
	listReleases: (): Action<typeof GET_RELEASES_TABLE> => createAction(GET_RELEASES_TABLE),
	listReleasesError: (error: Error): PayloadAction<typeof GET_RELEASES_TABLE_ERROR, { error: typeof error }> =>
		createAction(GET_RELEASES_TABLE_ERROR, { error }),
	listReleasesSuccess: (result: Release[]): PayloadAction<typeof GET_RELEASES_TABLE_SUCCESS, { result: typeof result }> =>
		createAction(GET_RELEASES_TABLE_SUCCESS, { result }),


	/**
	 * UPDATE RELEASE
	*/
	updateRelease: (model: Release, formikPromise: FormikFormSubmitPromise): PayloadAction<typeof UPDATE_RELEASE, { model: typeof model; formikPromise: typeof formikPromise; }> => createAction(UPDATE_RELEASE, { formikPromise, model }),
	updateReleaseError: (error: Error): PayloadAction<typeof UPDATE_RELEASE_ERROR, { error: typeof error }> =>
		createAction(UPDATE_RELEASE_ERROR, { error }),
	updateReleaseSuccess: (response: any): PayloadAction<typeof UPDATE_RELEASE_SUCCESS, { response: typeof response }> =>
		createAction(UPDATE_RELEASE_SUCCESS, { response })

};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
export type releaseActions = ActionType<typeof releaseActions>;

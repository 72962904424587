
// this is an auto generated file. This will be overwritten

export const triggerSpeedTestForHub = /* GraphQL */ `
  mutation TriggerSpeedTestForHub($input: CreateHubSpeedTestInput!) {
    triggerSpeedTestForHub(input: $input) {
      id
      line
      deviceId
      type
      serialNumber
      status
      TCPTestResult {
        resultVersion
      }
      UDPTestResult {
        resultVersion
      }
      hubError {
        code
        type
        message
      }
      result
      error
      createdAt
      updatedAt
      completedAt
      executionDuration
      trigger
      platform
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const triggerSpeedTest = /* GraphQL */ `
  mutation TriggerSpeedTest($input: CreateSpeedTestInput!) {
    triggerSpeedTest(input: $input) {
      id
	  line
	  type
	  deviceId
	  status
	  createdAt
	  updatedAt
	  trigger
	  platform
	  TCPTestResult {
	    resultVersion
        up {
          outcome
          serverHostname
          serverAddress
          wanType
          timeOfResults
          testDuration
          bytesTransferred
          speed
          speedGbps
          speedMbps
          speedWithUnit
          speedAtMeasuredInterval
          warmupTime
          warmupBytes
          intervalNumber
          numberOfThreads
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
        }
        down {
          outcome
          serverHostname
          serverAddress
          wanType
          timeOfResults
          testDuration
          bytesTransferred
          speed
          speedGbps
          speedMbps
          speedAtMeasuredInterval
          speedWithUnit
          warmupTime
          warmupBytes
          intervalNumber
          numberOfThreads
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
        }
	  }
      UDPTestResult {
	    resultVersion
        up {
          outcome
           errorCode
           errorDetails
           serverHostname
           serverAddress
           wanType
           timeOfResults
           packetSize
           transmissionRate
           transmissionBytes
           transmissionDuration
           speed
           speedGbps
           speedMbps
           speedWithUnit
           usageBytes
           usageGbps
           usageMbps
           usageWithUnit
         }
         down {
          outcome
          errorCode
          errorDetails
          serverHostname
          serverAddress
          wanType
          timeOfResults
          packetSize
          transmissionRate
          transmissionBytes
          transmissionDuration
          speed
          speedGbps
          speedMbps
          speedWithUnit
          usageBytes
          usageGbps
          usageMbps
          usageWithUnit
        }
	  }
	  result
      error
      hubError {
        code
        type
        message
      }
	  completedAt
      executionDuration
      _lastChangedAt
      _version
    }
  }
`;
export const updateSpeedTest = /* GraphQL */ `
  mutation UpdateSpeedTest($input: UpdateSpeedTestInput!) {
    updateSpeedTest(input: $input) {
      id
      line
      deviceId
      type
      serialNumber
      status
      TCPTestResult {
        resultVersion
      }
      UDPTestResult {
        resultVersion
      }
      hubError {
        code
        type
        message
      }
      result
      error
      createdAt
      updatedAt
      completedAt
      executionDuration
      trigger
      platform
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const sendLastSpeedTestByLine = /* GraphQL */ `
  mutation SendLastSpeedTestByLine(
    $line: String!
    $date: String!
    $type: HubTestType!
  ) {
    sendLastSpeedTestByLine(line: $line, date: $date, type: $type) {
      id
      line
      deviceId
      type
      serialNumber
      status
      TCPTestResult {
        resultVersion
      }
      UDPTestResult {
        resultVersion
      }
      hubError {
        code
        type
        message
      }
      result
      error
      createdAt
      updatedAt
      completedAt
      executionDuration
      trigger
      platform
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const sendLastSpeedTestBySerial = /* GraphQL */ `
  mutation SendLastSpeedTestBySerial(
    $serialNumber: String!
    $date: String!
    $type: HubTestType!
  ) {
    sendLastSpeedTestBySerial(
      serialNumber: $serialNumber
      date: $date
      type: $type
    ) {
      id
      line
      deviceId
      type
      serialNumber
      status
      TCPTestResult {
        resultVersion
      }
      UDPTestResult {
        resultVersion
      }
      hubError {
        code
        type
        message
      }
      result
      error
      createdAt
      updatedAt
      completedAt
      executionDuration
      trigger
      platform
      _version
      _deleted
      _lastChangedAt
    }
  }
`;

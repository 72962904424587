import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import React, {ReactNode} from 'react';
import * as PropTypes from 'prop-types';
import styles from './DrawerNavigationList.component.module.scss';
import './DrawerNavigationList.component.scss';

export interface DrawerNavigationListProperties {
	children?: ReactNode;
}

export const drawerNavigationListDataSelectors = {
	drawerNavigationListContainer: 'DrawerNavigationList-list',
	drawerNavigationListDivider: 'DrawerNavigationList-divider'
};

export const DrawerNavigationList: React.FC<DrawerNavigationListProperties> = ({children}) => {

	return (


		<List disablePadding data-cy={drawerNavigationListDataSelectors.drawerNavigationListContainer}>
			{children}
			<Divider id={styles.divider} data-cy={drawerNavigationListDataSelectors.drawerNavigationListDivider} />
		</List>

	);
};

DrawerNavigationList.propTypes = {
	children: PropTypes.any
};

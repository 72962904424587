import { RouterRootState, connectRouter } from 'connected-react-router';
import { History, LocationState } from 'history';
import { combineReducers } from 'redux';
// import { PersistConfig, persistReducer } from 'redux-persist';
// import reduxPersistLocalStorage from 'redux-persist/lib/storage';
import { StateType } from 'typesafe-actions';
import { DevicesState, devicesReducers } from './Device';
import { FirmwareState, firmwareReducers } from './Firmware';
import { initialLocationState, locationReducers } from './Location';
import { reportReducers } from './Report';
import { deviceGroupsReducers } from './DeviceGroups';
import { jobsReducers } from './Jobs';

// const createPersistConfig = <State>() =>
// 	({
// 		key: 'root',
// 		storage: reduxPersistLocalStorage
// 	} as PersistConfig<State>);

export const createRootReducer = <HistoryState extends LocationState>(history: History<HistoryState>) =>
	combineReducers({
		// devices: persistReducer(createPersistConfig<DevicesState>(), devicesReducers),
		deviceGroup: deviceGroupsReducers,
		devices: devicesReducers,
		firmware: firmwareReducers,
		jobs: jobsReducers,
		location: locationReducers,
		report: reportReducers,
		router: connectRouter(history)
	});

export type RootReducers = ReturnType<ReturnType<typeof createRootReducer>>;

export type AppState = StateType<ReturnType<typeof createRootReducer>>;
export type RootState = AppState & RouterRootState;

export const rootInitialState = {
	location: initialLocationState
} as RootState;

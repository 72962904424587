import { all, fork } from '@redux-saga/core/effects'
import { devicesSagas } from './Device.sagas';
import { deviceGroupsSagas } from './DeviceGroup.sagas';
import { firmwareSagas } from './Firmware.sagas';
import { reportSagas } from './Report.sagas';
import { jobSagas } from './Job.sagas';

export const allSagas = function* () {
	yield all([
		fork(
			devicesSagas().watcher
		),
		fork(
			deviceGroupsSagas().watcher
		),
		fork(
			firmwareSagas().watcher
		),
		fork(
			reportSagas().watcher
		),
		fork(
			jobSagas().watcher
		)
	]);
};

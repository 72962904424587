import {createSelector} from 'reselect';

import {RootState} from '../reducers';

const menuSelector = (state: RootState) => {
	return state?.menu;
};

const isOpenSelector = createSelector(menuSelector, (state) => state?.isOpen);

export const menuSelectors = {
	isOpen: isOpenSelector
};

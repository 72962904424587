/* eslint sort-keys: 0 */
import { ActionType, PayloadAction } from 'typesafe-actions';

import { createAction } from '@indigo-cloud/common-react';
import { Job } from '../../../shared';
import {
	LOAD_JOB_DETAILS,
	LOAD_JOB_DETAILS_ERROR,
	LOAD_JOB_DETAILS_SUCCESS,
	LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE,
	LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR,
	LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS
} from '../../constants';

export const JobDetailsActions = {
	/** Job Details  */
	loadJobDetails: (jobId: string): PayloadAction<typeof LOAD_JOB_DETAILS, {jobId: typeof jobId }> => createAction(LOAD_JOB_DETAILS, { jobId }),
	loadJobDetailsSuccess: (
		jobId: string,
		response: Job
	): PayloadAction<typeof LOAD_JOB_DETAILS_SUCCESS, {jobId: string, response: Job}> =>
		createAction(LOAD_JOB_DETAILS_SUCCESS, {jobId, response}),
	loadJobDetailsError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof LOAD_JOB_DETAILS_ERROR, {error: ErrorType}> =>
		createAction(LOAD_JOB_DETAILS_ERROR, {error}),

	loadDeviceJobExecutionDetails: (jobId: string, agentEndPoint: string, executionNumber: number): PayloadAction<typeof LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE, {jobId: typeof jobId, agentEndPoint: string, executionNumber: typeof executionNumber }> => createAction(LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE, { jobId, agentEndPoint, executionNumber }),
	loadDeviceJobExecutionDetailsSuccess: (
		jobId: string,
		response: Job
	): PayloadAction<typeof LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS, {jobId: string, response: Job}> =>
		createAction(LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_SUCCESS, {jobId, response}),
	loadDeviceJobExecutionDetailsError: <ErrorType extends Error>(error: ErrorType): PayloadAction<typeof LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR, {error: ErrorType}> =>
		createAction(LOAD_JOB_EXECUTION_DETAIL_FOR_DEVICE_ERROR, {error})
};

/**
 * @see {@link https://github.com/piotrwitek/typesafe-actions#user-content-redux-actions-1}
 */
// eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-redeclare
export type JobDetailsActions = ActionType<typeof JobDetailsActions>;
